import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, Subject } from "rxjs";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Response, Notification, User } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';
import * as _ from 'lodash';

@Injectable()
export class NotificationsService {
  private _notifications: Notification[] = [];
  private _onNotificationsChanged: BehaviorSubject<Notification[]>;

  constructor(private http: HttpClient) {}

  list(user: User = null): Observable<Response<Notification[]>> {
    return this.http.get(new ServerApiPipe().transform(`notifications`));
  }

  update(notification: Notification): Observable<Response<Notification>> {
    return this.http.put(new ServerApiPipe().transform(`notifications/${notification.id}`), notification);
  }

  delete(notification: Notification): Observable<Response<any>> {
    let _event = new Subject();

    this.http.delete(new ServerApiPipe().transform(`notifications/${notification.id}`)).subscribe(() => {
      notification.deleted_at = new Date();      
      _event.next();
    });

    return _event.asObservable();
  }

  readAll(): Observable<Response<Notification[]>> {
    return this.http.put(new ServerApiPipe().transform(`notifications/all`), {});
  }

  setNotifications(notifications): Observable<Notification[]> {
    this._notifications = notifications;
    if (this._onNotificationsChanged) {
      this._onNotificationsChanged.next(this._notifications);
    } else {
      this._onNotificationsChanged = new BehaviorSubject(this._notifications);
    }
    return of(this._notifications);
  }

  getNotifications(readed: boolean = null) {
    return this._notifications.filter(f => (readed != null && f.readed == readed) || readed == null);
  }

  getUnreadNotifications(): Notification[] {
    return this.getNotifications(false) || [];
  }

  getEveryoneNotifications(): Notification[] {
    return _.filter(this._notifications, { user_id: null });
  }

  push(notification: Notification): Observable<Notification> {
    let _lastnotifiaction = this._notifications.length > 0 ? this._notifications[this._notifications.length - 1] : false;
    if (_lastnotifiaction || !notification) {
      let _foundnotification = _.find(this._notifications, {
        created_at: notification && notification.created_at,
        body: notification && notification.body,
        title: notification && notification.title
      });
      if (_foundnotification|| !notification) {
        return of(null);
      }
    }
    this._notifications.push(notification);
    if (this._onNotificationsChanged) {
      this._onNotificationsChanged.next(this._notifications);
    } else {
      this._onNotificationsChanged = new BehaviorSubject(this._notifications);
    }
    return of(notification);
  }

  onNotificationsChanged(): Observable<Notification[]> {
    if (!this._onNotificationsChanged) {
      this._onNotificationsChanged = new BehaviorSubject(null);
    } 
    return this._onNotificationsChanged?.asObservable();
  }
}
