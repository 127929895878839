import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { ChatBotResponse, User, Response, Active } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';

@Injectable()
export class ChatbotService {

  constructor(private http: HttpClient) {}

  get(id: Number): Observable<Response<ChatBotResponse>> {
    return  id > 0 ? this.http.get<Response<ChatBotResponse>>(new ServerApiPipe().transform(`chatbot/${id}`)) : of({
      status: 'success',
      success: true,
      message: '',
      data: {
        match_message: '',
        response_message: '',
        active: false
      } as ChatBotResponse
    } as Response<ChatBotResponse>);
  }

  all(body: any = {}): Observable<Response<ChatBotResponse[]>> {
    let params = new HttpParams().set("s", body.s || '');

    return this.http.get<Response<ChatBotResponse[]>>(new ServerApiPipe().transform(`chatbot`), { params }).pipe(map((r: Response<ChatBotResponse[]>) => {
      r.data = r.data?.map(c => Object({ ...c, match_message: c.match_message.replace(/^, /, '')}));
      return r;
    }));
  }

  delete(chatbot: ChatBotResponse): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`chatbot/${chatbot.id}`));
  }

  update(chatbot: ChatBotResponse): Observable<Response<ChatBotResponse>> {
    return this.http.put<Response<ChatBotResponse>>(new ServerApiPipe().transform(`chatbot/${chatbot.id}`), chatbot);
  }

  save(chatbot: ChatBotResponse): Observable<Response<ChatBotResponse>> {
    return this.http.post<Response<ChatBotResponse>>(new ServerApiPipe().transform(`chatbot`), chatbot);
  }

  list(body: any = {}): Observable<Response<ChatBotResponse[]>> {
    let params = new HttpParams();

    if (body.active) {
      params = params.set('active', body.active);
    }

    return this.http.get<Response<ChatBotResponse[]>>(new ServerApiPipe().transform(`chatbot/list`), { params });
  }
}
