/**
 * Created by xavi on 5/16/17.
 */
import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { takeUntil, map } from "rxjs/operators";
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders, HttpEvent, HttpEventType } from "@angular/common/http";
import { Login, User, Response, Active, Address, UserFile, PaymentSource, Role } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';
import { ServerFilePipe } from 'app/pipes/server_file.pipe';


@Injectable()
export class UsersService {
  userReady: Subject<User> = new Subject<User>();

  constructor(private http: HttpClient) {}

  get(id: Number): Observable<Response<User>> {
    return id > 0 ? this.http.get<Response<User>>(new ServerApiPipe().transform(`users/${id}`)).pipe(takeUntil(this.userReady)) : of({
      status: 'success',
      success: true,
      message: '',
      data: {
        title: '',
        user_group_id: 0,
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
        tel_oficina: '',
        profesion: '',
        bday: null,
        gender: '',
        rfc: '',
        no_licencia: '',
        cedula_profesional: '',
        matricula_profesional: '',
        especialidad: '',
        biography: '',
        addresses: [],
        avatar: '',
        last_login: null,
        skills_tags: [],
        conekta_payment_sources: [],
        quote: '',
        services: [],
        date_slot: '',
        next_day: 0,
        max_days: 7,
        extend_last_appointment: false,
        multiple_services: false,
        open_time: '',
        close_time: '',
        files: [],
        role: {} as Role
      } as User
    } as Response<User>);
  }
  
  all(body: any): Observable<Response<User[]>> {
    let params = new HttpParams().set("s", body.s);

    return this.http.get<Response<User[]>>(new ServerApiPipe().transform(`users`), { params });
  }

  activate(user: User, active: boolean = true): Observable<Response<Active>> {
    return this.http.put<Response<Active>>(new ServerApiPipe().transform(`users/${user.id}/activate`), { active: active });
  }

  delete(user: User): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`users/${user.id}`));
  }

  update(user: User): Observable<Response<User>> {
    const formData: FormData = new FormData();

    Object.keys(user).forEach((k) => {
      if (!Array.isArray(user[k]) && user[k] !== '' && user[k] !== null) {
        formData.append(k, user[k]);
      } else {

      }
    });

    try {
      if (user.avatar && user.avatar instanceof File) {
        formData.append('avatar', user.avatar, 'avatar');
      }
    } catch(e) { }
    
    if (user.addresses?.length > 0) {
      formData.append('addresses', JSON.stringify(user.addresses));
    }
    
    if (user.offices?.length > 0) {
      formData.append('offices', JSON.stringify(user.offices));
    }

    if (user.services?.length > 0) {
      formData.append('services', JSON.stringify(user.services));
    }

    if (user.skills_tags?.length > 0) {
      formData.append('skills_tags', JSON.stringify(user.skills_tags));
    }

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<User>>(new ServerApiPipe().transform(`users/${user.id}?_method=PUT`), formData, { headers });
  }

  save(user: User): Observable<Response<User>> {
    const formData: FormData = new FormData();
    
    Object.keys(user).forEach((k) => {
      if (!Array.isArray(user[k]) && user[k] !== '' && user[k] !== null) {
        formData.append(k, user[k]);
      } else {

      }
    });

    try {
      if (user.avatar && user.avatar instanceof File) {
        formData.append('avatar', user.avatar, 'avatar');
      }
    } catch(e) { }
    
    if (user.addresses?.length > 0) {
      formData.append('addresses', JSON.stringify(user.addresses));
    }
    
    if (user.offices?.length > 0) {
      formData.append('offices', JSON.stringify(user.offices));
    }

    if (user.services?.length > 0) {
      formData.append('services', JSON.stringify(user.services));
    }

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    
    return this.http.post<Response<User>>(new ServerApiPipe().transform(`users`), formData, { headers });
  }

  getAvatar(user: User): Observable<Blob> {
    return this.http.get(new ServerApiPipe().transform(`users/${user.id}/avatar`), { responseType: 'blob' });
  }

  setAvatar(user: User, avatar: File): Observable<Response<any>> { 
    const formData: FormData = new FormData();
    formData.append('avatar', avatar, avatar.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<any>>(new ServerApiPipe().transform(`users/${user.id}/avatar`), formData, { headers });
  }

  updateAddress(user: User, address: Address): Observable<Response<Address>> {
    return this.http.put<Response<Address>>(new ServerApiPipe().transform(`users/${user.id}/address/${address.id}`), address);
  }

  saveAddress(user: User, address: Address):Observable<Response<Address>> {
    return this.http.post<Response<Address>>(new ServerApiPipe().transform(`users/${user.id}/address`), address);
  }

  deleteAddress(user: User, address: Address): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`users/${user.id}/address/${address.id}`));
  }

  getAddress(user: User, address: Address): Observable<Response<Address>> {
    return this.http.get<Response<Address>>(new ServerApiPipe().transform(`users/${user.id}/address/${address.id}`));
  }

  postFile(user: User, file: File = null, body: any = null): Observable<any> { 
    const formData: FormData = new FormData();
    if (file !== null) {
      formData.append('file', file, file.name);
    }
    if (body) {
      Object.keys(body).forEach((p) => {
        formData.append(p, body[p]);
      });
    }

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<any>(new ServerApiPipe().transform(`users/${user.id}/archivo`), formData, { 
      headers, reportProgress: true, observe: 'events'
    });
  }

  postCard(user: User, body): Observable<Response<PaymentSource>> {
    return this.http.post<Response<PaymentSource>>(new ServerApiPipe().transform(`users/${user.id}/card`), body);
  }

  updateCard(user: User, card: PaymentSource): Observable<Response<PaymentSource>> {
    return this.http.put<Response<PaymentSource>>(new ServerApiPipe().transform(`users/${user.id}/card/${card.id}`), card);
  }

  deleteCard(user: User, card: PaymentSource): Observable<any> {
    return this.http.delete<any>(new ServerApiPipe().transform(`users/${user.id}/card/${card.id}`));
  }

  favCard(user: User, card: PaymentSource): Observable<Response<PaymentSource>> {
    return this.http.put<Response<PaymentSource>>(new ServerApiPipe().transform(`users/${user.id}/card/${card.id}/favorite`), card);
  }
}
