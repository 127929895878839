import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

import { StorageService } from 'app/core/services/storage.service';
import { UtilsService } from 'app/core/services/utils.service';

import { ChatBotResponse, User, Response, Active, Message } from 'app/core/interfaces/all.interface';

import { ServerApiPipe } from 'app/pipes/server_api.pipe';

@Injectable()
export class ChatService {
  private badgeCount: number = 0;
  onChatArrive: BehaviorSubject<any>;

  get User(): User {
    return this._storage.getCurrentUser() || ({} as User);
  }

  get canViewMessges(): boolean {
    return this._utils.hasAccess('messages/view');
  }

  constructor(
    private http: HttpClient,
    private _storage: StorageService,
    private _utils: UtilsService,
    private _fuseNavigationService: FuseNavigationService,
  ) {
    this.onChatArrive = new BehaviorSubject(null);
  }

  getLastMessages() {
  }

  sendMessage(message: Message, file: File = null): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    let metadata: any = { headers };

    const formData: FormData = new FormData();
    if (file !== null) {
      formData.append('file', file, file.name);

      metadata.reportProgress = true;
      metadata.observe = 'events';
    }
    if (message) {
      Object.keys(message).forEach((p) => {
        formData.append(p, message[p]);
      });
    }

    return this.http.post<any>(new ServerApiPipe().transform(`clients/messages`), formData, metadata);
  }

  setBadge(total: number) {
    this.badgeCount = total;
    this.updateBadge();
    
    return this.getBadge();
  }

  getBadge() {
    return Math.max(this.badgeCount || 0, 0);
  }

  addBadge(total: number = 1) {
    this.badgeCount += Math.max(Math.abs(total || 1), 1);
    this.updateBadge();
    
    return this.getBadge();
  }

  reduceBadge(total: number = 1) {
    this.badgeCount -= Math.max(Math.abs(total || 1), 1);
    this.updateBadge();

    return this.getBadge();
  }

  updateBadge() {
    if (this.canViewMessges) {
      let total = this.getBadge();
      this._fuseNavigationService.updateNavigationItem('messages', {
        hidden: true,
        badge    : { }
      });

      if (total <= 0) {
        this.clearBadge();
      } else {
        setTimeout(() => {
          this._fuseNavigationService.updateNavigationItem('messages', {
            hidden: false,
            badge    : {
              title: `${total}`,
              bg   : '#FF6F00',
              fg   : '#FFFFFF'
            }
          });
        } , 1);
      }
    }
  }

  clearBadge() {
    this._fuseNavigationService.updateNavigationItem('messages', {
      hidden: true,
      badge    : null
    });
    setTimeout(() => {
      this._fuseNavigationService.updateNavigationItem('messages', {
        hidden: false
      });
    } , 1);
  }
}
