import { Pipe, PipeTransform } from '@angular/core';

import { environment as env } from 'environments/environment';

@Pipe({
  name: 'server_file'
})
export class ServerFilePipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    return `${env.local.base}${value}`;
  }
}
