import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, Subject } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Settings, Response } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';
import * as firebase from 'firebase';

@Injectable()
export class SettingsService {
  
  private _settings: Settings = {} as Settings;
  private _subject: Subject<Settings> = new Subject();
  private _fireBaseApp: Subject<Settings> = new Subject();

  constructor(private http: HttpClient) {
  }

  all(): Observable<Settings> {
    this.http.get<Response<any[]>>(new ServerApiPipe().transform(`settings/all`)).subscribe((response) => {
      for(var p in response.data) {
        let _name = (response.data[p].name || '').toLowerCase();
        this._settings[_name] = response.data[p].value;
      }

      this._subject.next(this._settings);
    });

    return this._subject;
  }

  testMailServer(config: any): Observable<any> {
    return this.http.post<any>(new ServerApiPipe().transform(`settings/mail/test`), config);
  }

  getCurrentSettings(): Settings {
    return this._settings;
  }

  onSettingsChange(): Observable<Settings> {
    return this._subject;
  }

  setFirebaseApp() {
    if (this._settings.google_firebase && firebase.apps.length <= 0) {
      const app = firebase.initializeApp({
        databaseURL: this._settings.google_firebase
      });
      this._fireBaseApp.next(this._settings);
    }
  }

  onFirebaseSetted(): Observable<Settings> {
    return this._fireBaseApp;
  }

  save(settings: any): Observable<Response<any>> { 

    const formData: FormData = new FormData();
    if (settings.fileCompany) {
      formData.append('fileCompany', settings.fileCompany, 'company_logo');
    }
    if (settings.iconoCompany) {
      formData.append('iconoCompany', settings.iconoCompany, 'company_icon');
    }
    

    settings = { ...this._settings, ...settings };

    formData.append('company_name', settings.company_name || '');  
    formData.append('company_email', settings.company_email || '');
    formData.append('company_address', settings.company_address || '');
    formData.append('company_city', settings.company_city || '');
    formData.append('company_state', settings.company_state || '');
    formData.append('company_country', settings.company_country || '');
    formData.append('company_cp', settings.company_cp || '');
    formData.append('company_phone', settings.company_phone || '');
    formData.append('currency', settings.currency || '');
    

    if (settings.facturama_username && settings.facturama_password) {
      formData.append('facturama_client', btoa(`${settings.facturama_username}:${settings.facturama_password}`));
    } else {
      formData.append('facturama_client', '');
    }

    formData.append('facturama_mode', settings.facturama_mode || 'dev');
    
    formData.append('store_url', settings.store_url || '');
    formData.append('conekta_client', settings.conekta_client || '');
    formData.append('conekta_key', settings.conekta_key || '');
    formData.append('oxxo_pay_expiration', settings.oxxo_pay_expiration || '60');
    formData.append('twilio_account_sid', settings.twilio_account_sid || '');
    formData.append('twilio_auth_token', settings.twilio_auth_token || '');
    formData.append('twilio_phone_from', (settings.twilio_phone_from || '').toString().replace(/[^\d]+/g, ''));
    formData.append('google_api', settings.google_api || '');
    formData.append('google_firebase', settings.google_firebase || '');
    formData.append('paypal_client', settings.paypal_client || '');
    
    formData.append('chatbot_default_response', settings.chatbot_default_response || '');
    formData.append('chatbot_disable_time', settings.chatbot_disable_time || '');
    formData.append('message_notify_agent', settings.message_notify_agent || '');
    formData.append('message_offices_list', settings.message_offices_list || '');


    formData.append('mail_encryption', settings.mail_encryption || '');
    formData.append('mail_password', settings.mail_password || '');
    formData.append('mail_username', settings.mail_username || '');
    formData.append('mail_port', settings.mail_port || '');
    formData.append('mail_host', settings.mail_host || '');
    formData.append('default_mailer', settings.default_mailer || '');
    formData.append('sending_mail', settings.sending_mail || '');
    formData.append('theme', settings.theme || '');
    formData.append('google_trackid', settings.google_trackid || '');

    formData.append('company_facebook', settings.company_facebook || '');
    formData.append('company_twitter', settings.company_twitter || '');
    formData.append('company_instagram', settings.company_instagram || '');
    formData.append('company_linkedin', settings.company_linkedin || '');
    formData.append('company_pinterest', settings.company_pinterest || '');
    formData.append('company_mercadolibre', settings.company_mercadolibre || '');
    
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<any>>(new ServerApiPipe().transform(`settings`), formData, { headers });
  }
}
