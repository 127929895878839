<div fxLayout="column" fxFlex fusePerfectScrollbar>
  <mat-list class="date" cdkFocusRegionStart>
    <h3 matSubheader cdkFocusInit>
      <span>{{ 'QUICKPANEL.TODAY' | translate }}</span>
    </h3>

    <div class="secondary-text mat-display-1 mb-0 p-16">
      <div class="mb-12">
        {{ date | dateFormat: 'dddd' | translate }}
      </div>
      <div fxLayout="row" fxLayoutAlign="start start">
        <span> {{ date | dateFormat: 'D' }}</span>
        <span class="mat-subheading-1">th</span>
        <span> {{ date | dateFormat: 'MMMM' }}</span>
      </div>
    </div>
  </mat-list>

</div>