import { NgModule, Optional, SkipSelf } from '@angular/core';
import { fakeBackendProvider } from "./helper/fake-backend";
import { StorageService } from "./services/storage.service";
import { AuthorizatedGuard } from "./guards/authorizated.guard";
import { AuthenticationService } from './services/authentication.service';
import { UsersService } from './services/users.service';
import { UtilsService } from './services/utils.service';
import { RolesService } from './services/roles.service';
import { PermissionsService } from './services/permissions.service';
import { ClientsService } from './services/clients.service';
import { OfficesService } from './services/offices.service';
import { CategoriesService } from './services/categories.service';
import { PriceListService } from './services/priceLists.service';
import { ProductsService } from './services/products.service';
import { ProductTypesService } from './services/productTypes.service';
import { ApplicationTypesService } from './services/applicationTypes.service';
import { OrdersService } from './services/orders.service';
import { AppointmentsService } from './services/appointments.service';
import { CouponsService } from './services/coupons.service';
import { ProductsImportService } from './services/productsImport.service';
import { SettingsService } from './services/settings.service';
import { ChatbotService } from './services/chatbot.service';
import { ChatService } from './services/chat.service';
import { NotificationsService } from './services/notificationes.service';
import { FilesService } from './services/files.service';
import { DoctorsService } from './services/doctors.service';
import { MessagesService } from './services/messages.service';
import { FacturamaService } from './services/facturama.service';
import { FacturamaClient } from './helper/facturama.helper';
import { ThemesService } from './services/themes.service';
import { FormsService } from './services/forms.service';

@NgModule({
  declarations: [  ],
  imports: [],
  providers: [
    StorageService,
    AuthorizatedGuard,
    fakeBackendProvider,
    AuthenticationService,
    UsersService,
    UtilsService,
    RolesService,
    PermissionsService,
    ClientsService,
    OfficesService,
    CategoriesService,
    PriceListService,
    ProductsService,
    ProductTypesService,
    ApplicationTypesService,
    OrdersService,
    AppointmentsService,
    CouponsService,
    ProductsImportService,
    SettingsService,
    ChatbotService,
    ChatService,
    NotificationsService,
    FilesService,
    DoctorsService,
    MessagesService,
    FacturamaService,
    FacturamaClient,
    ThemesService,
    FormsService
  ],
  bootstrap: []
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
