import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { AuthenticationService } from 'app/core/services/authentication.service';
import { StorageService } from 'app/core/services/storage.service';
import { OfficesService } from 'app/core/services/offices.service';
import { User } from 'app/core/interfaces/user.interface';
import { Office } from 'app/core/interfaces/all.interface';
import { SettingsService } from 'app/core/services/settings.service';

@Component({
    selector     : 'navbar-vertical-style-1',
    templateUrl  : './style-1.component.html',
    styleUrls    : ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  @ViewChild('sucursales') sucursales: any;
  selected_sucursal: String = '----------';

  // Private
  private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
  private _unsubscribeAll: Subject<any>;
  private _unsubscribeUser: any;

  get User(): User {
    return this._storage.getCurrentUser() || ({} as User);
  }

  get COMPANY_NAME(): string {
    return this._settings.getCurrentSettings().company_name
  }

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {Router} _router
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _storage: StorageService,
    private _offices: OfficesService,
    private _settings: SettingsService,
    private _router: Router
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this._unsubscribeUser = this._storage.onCurrentUserChanged().subscribe((user: User) => {
      if (user) {
        if (user.role.is_sucursal && user.sucursales_rel && user.sucursales_rel.length > 0 && user.sucursales_rel[0]) {
          this.chooseSucursal(user.sucursales_rel[0].sucursal);
        }
      }
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Directive
  @ViewChild(FusePerfectScrollbarDirective, {static: true})
  set directive(theDirective: FusePerfectScrollbarDirective)
  {
      if ( !theDirective )
      {
          return;
      }

      this._fusePerfectScrollbar = theDirective;

      // Update the scrollbar on collapsable item toggle
      this._fuseNavigationService.onItemCollapseToggled
          .pipe(
              delay(500),
              takeUntil(this._unsubscribeAll)
          )
          .subscribe(() => {
              this._fusePerfectScrollbar.update();
          });

      // Scroll to the active item position
      this._router.events
          .pipe(
              filter((event) => event instanceof NavigationEnd),
              take(1)
          )
          .subscribe(() => {
                  setTimeout(() => {
                      this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
                  });
              }
          );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        if (this._fuseSidebarService.getSidebar('navbar') ) {
          this._fuseSidebarService.getSidebar('navbar').close();
        }
      });

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });

    // Get current navigation
    this._fuseNavigationService.onNavigationChanged
      .pipe(
        filter(value => value !== null),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this.navigation = this._fuseNavigationService.getCurrentNavigation();
      });


  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._unsubscribeUser.unsubscribe();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar opened status
   */
  toggleSidebarOpened(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleOpen();
  }

  /**
   * Toggle sidebar folded status
   */
  toggleSidebarFolded(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleFold();
  }

  showSucursales(element: any, list: any) {

    if (element.classList.value.indexOf('open') > -1) {
      element.classList.remove('open');
      list.style.height = `0px`;
    } else {
      element.classList.add('open');
      list.style.height = `${list.children[0].clientHeight}px`;
    }
  }

  chooseSucursal(sucursal: Office, element: any = false, list: any = false) {
    this.selected_sucursal = sucursal.name;
    this._offices.setCurrentOffice(sucursal);
    element && element.classList.remove('open');
    list && (list.style.height = `0px`);
  }
}
