import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'last4'
})
export class Last4Pipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    var cleaned = ('' + value).replace(/\D/g, '');
    
    return Array(4 - cleaned.length).fill('0').join('') + cleaned;
  }
}
