import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StorageService } from "../services/storage.service";

import { UsersService } from '../services/users.service';

import { NgxSpinnerService } from "ngx-spinner";
import { Response } from '../interfaces/response.interface';
import { User } from '../interfaces/user.interface';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class AuthorizatedGuard implements CanActivate {

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private _storage: StorageService, 
    private spinner: NgxSpinnerService,
    private _notifier: NotifierService,
    private _users: UsersService,
    private _translate: TranslateService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, next: RouterStateSnapshot) {
    let _validate = route.data?.validate === false ? false : true;

    if (_validate) {
      _validate = this.router.getCurrentNavigation().extras.state?.validate === false ? false : true;
    }
    if (_validate) {
      if (this._storage.isAuthenticated()) {
        if (!this._storage.getCurrentUser()) {
          this.spinner.show('profile');
          this._users.get(this._storage.getCurrentUserId()).subscribe((response: Response<User>) => {
            this._storage.setCurrentUser(response.data);
            this.spinner.hide('profile');
            if (!this.validNav(route, response.data)) {
              this.router.navigate([ '/unauthorized' ]);
            } else {
              this.router.navigate([ next.url ], { state: { validate: false }});
            }
          }, (error) => {
            this.spinner.hide('profile');
            this._storage.logout();
            return false;
          });
        } else {
          let _user: User = this._storage.getCurrentUser();
          if (!this.validNav(route, _user)) {
            this.router.navigate([ '/unauthorized' ]);
          }

          return true;
        }
      } else {
        // not logged in so redirect to login page
        this.router.navigate([ '/auth/login' ]);
        return false;
      }
    } else {
      return true
    }
  }

  validNav(route: ActivatedRouteSnapshot, user: User): boolean {
    let _valid = false;
    if (route.data && route.data.auth && route.data.auth.length > 0) {
      if (route.data.auth.find(f => ['guest'].concat(user.permisos.filter(p => p.access).map(p => `${p.controller}/${p.action}`)).includes(f))) {
        _valid = true;
      }
    } else {
      this._notifier.notify('error', this._translate.instant(`ERRORS.NO_AUTH`));
    }
    return _valid;
  }

}
