
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { PriceList, User, Response, Active , Product } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';

@Injectable()
export class PriceListService {

  constructor(private http: HttpClient) {}

  get(id: Number): Observable<Response<PriceList>> {
    return  id > 0 ? this.http.get<Response<PriceList>>(new ServerApiPipe().transform(`price_lists/${id}`)) : of({
      status: 'success',
      success: true,
      message: '',
      data: {
        name: '',
        description: '',
        active: false,
        sub_category: []
      } as PriceList
    } as Response<PriceList>);
  }

  all(body: any = {}): Observable<Response<PriceList[]>> {
    let params = new HttpParams().set("s", body.s || '');

    return this.http.get<Response<PriceList[]>>(new ServerApiPipe().transform(`price_lists`), { params });
  }

  availableOnline(pricelist: PriceList, active: boolean = true): Observable<Response<PriceList>> {
    return this.http.put<Response<PriceList>>(new ServerApiPipe().transform(`price_lists/${pricelist.id}`), pricelist);
  }

  delete(pricelist: PriceList): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`price_lists/${pricelist.id}`));
  }

  update(pricelist: PriceList): Observable<Response<PriceList>> {
    return this.http.put<Response<PriceList>>(new ServerApiPipe().transform(`price_lists/${pricelist.id}`), pricelist);
  }

  save(pricelist: PriceList): Observable<Response<PriceList>> {
    return this.http.post<Response<PriceList>>(new ServerApiPipe().transform(`price_lists`), pricelist);
  }

  list(): Observable<Response<PriceList[]>> {
    return this.http.get<Response<PriceList[]>>(new ServerApiPipe().transform(`price_lists/list`));
  }

  products(filter: string, id: number): Observable<Response<Product[]>> {
    return this.http.post<Response<Product[]>>(new ServerApiPipe().transform(`price_lists/products`), { s: filter, id });
  }

  priceList(price_list: number): Observable<Response<PriceList>> {
    return this.http.post<Response<PriceList>>(new ServerApiPipe().transform(`price_lists/${price_list}/products`), {});
  }
}
