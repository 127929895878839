export const locale = {
  lang: 'en',
  moment: 'en-US',
  data: {
    'QUICKPANEL': {
      'TODAY': 'Today'
    },
    'ERRORS': {
      'FORBIDDEN': 'Forbidden',
      'ROLE_CLIENTS_NOT_FOUND': 'No client role enabled',
      'UNAUTHORIZED': 'Access is denied due to invalid credentials',
      'NOT_FOUND_USER': 'Not found user',
      'NOT_FOUND': 'Unreachable server',
      'NOT_FOUND_PRODUCT': 'Not found product',
      'NOT_FOUND_ROLE': 'Not found role',
      'NOT_FOUND_OFFICE': 'Not found office',
      'NOT_FOUND_CATEGORY': 'Not found category',
      'NOT_FOUND_OFFICE_PRODUCT': 'Not found product',
      'NOT_SHORTCUTS': 'No shortcuts yet!',
      'SERVER ERROR':  'Unreachable server',
      'PRICE_LIST_USED': 'Price list used by {{ office }}',
      'WRONG_UPDATE_FILE': 'File upload error; An empty field: name, category, application type, price base or type',
      'USER_INACTIVE': 'You do not have permission to log in. please activate your account',
      'NO_FOUND_CONEKTA_USER': 'This user is not registered in conekta',
      'NO_FOUND_CARD_USER': 'This card is not assigned to the user',
      'WRONG_DATE_RANGE': 'The date range is wrong',
      'SKU': {
        'NOT_AVAILABLE': 'Used SKU',
      },
      'PRICE_LIST_ID': {
        'MIN_LIMIT': 'Please select a price list'
      },
      'CODE': {
        'NOT_AVAILABLE': 'the code is already in use'
      },
      'RESPONSE_MESSAGE': {
        'MAX_LIMIT': 'Max character limit'
      },
      'BDAY': {
        'INVALID_DATE': 'Invalid birthday date',
        'MAX_DATE_LIMIT': 'Birthday outside range'
      },
      'EMAIL': {
        'NOT_AVAILABLE': 'Already used email'
      },
      'ERROR_SENDING': 'Unable to send email',
      'PRODUCT_APPLICATION_TYPE_ID': {
        'WRONG_VALUE': 'No application type selected'
      }
    },
    'NAV': {
      'SHORTCUTS': {
        'PLACEHOLDER': 'Search for a page',
      },
      'BACK_HOME': 'Go back to home',
      'CLIENT': {
        'TITLE': 'Clients'
      },
      'OFFICES': {
        'TITLE': 'Offices'
      },
      'CATEGORIES': {
        'TITLE': 'Categories'
      },
      'PRODUCTS': {
        'TITLE': 'Products'
      },
      'SERVICES': {
        'TITLE': 'Services'
      },
      'PRODUCTS_OFFICE': {
        'TITLE': 'Products'
      },
      'MANAGEMENT'  : {
        'TITLE': 'Management',
        'USERS': 'Users',
        'ROLES': 'Roles'
      },
      'BANNERS_OFFICE': {
        'TITLE': 'Banners'
      },
      'APPLICATIONS': 'Applications',
      'DASHBOARDS'  : 'Dashboards',
      'CALENDAR'    :  { 
        'TITLE': 'Calendar'
      },
      'APPOINTMENT': {
        'TITLE': 'Appointments'
      },
      'ECOMMERCE'   : 'E-Commerce',
      'ACADEMY'     : 'Academy',
      'MAIL'        : {
        'TITLE': 'Mail',
        'BADGE': '25'
      },
      'MAIL_NGRX'        : {
        'TITLE': 'Mail Ngrx',
        'BADGE': '13'
      },
      'CHAT'        : 'Chat',
      'FILE_MANAGER': 'File Manager',
      'CONTACTS'    : 'Contacts',
      'TODO'        : 'To-Do',
      'SCRUMBOARD'  : 'Scrumboard',
      'PRICE_LISTS': {
        'TITLE': 'Price Lists'
      },
      'ORDERS': {
        'TITLE': 'Orders'
      },
      'COUPONS': {
        'TITLE': 'Coupons'
      },
      'CATALOGS': {
        'TITLE': 'Catalogs'
      },
      'LOGOUT': {
        'CLOSE_SESSION': 'User logout',
        'TITLE': 'Logout'
      },
      'SETTINGS': {
        'TITLE': 'Settings',
        'GENERAL': 'General settings',
        'CHATBOT': 'Chatbot settings',
        'APIS': 'API\'s',
        'MAIL': 'Mail settings',
        'THEME': 'Appearance'
      },
      'MESSAGES': 'Messages',
      'FORMS': {
        'TITLE': 'Forms'
      }
    },
    'MANAGEMENT': {
      'ROLES': {
        'TITLE': 'Roles',
        'SEARCH': 'Search for a role',
        'ADD_NEW': 'ADD NEW ROLE',
        'SUBTITLE': 'Role Detail',
        'ERROR': {
          'REQUIRED': 'Required'
        },
        'FORM': {
          'NAME': 'Name',
          'DESCRIPTION': 'Description',
          'AVAILABLE ONLINE': 'Available Online',
          'IS_CLIENT': 'Designated for clients',
          'IS_SUCURSAL': 'Designated for office branch',
          'IS_PROVIDER': 'Designated for providers'
        },
        'TABS': {
          'PERMISSIONS': 'Permissions',
          'BASIC INFO': 'Basic Info'
        },
        'TABLE': {
          'NAME': 'Name',
          'AVAILABLE ONLINE': 'Available Online',
          'AVAILABLE': 'Available',
          'UNAVAILABLE': 'Unavailable',
          'DESCRIPTION': 'Description',
          'ACCESS': 'Access',
          'BAN': 'Ban',
          'ACTIVATE': 'Activate',
          'EDIT': 'Edit',
          'REMOVE': 'Remove',
          'VIEW': 'View',
          'DELETE_TITLE': 'Confirm deletion',
          'DELETE_MESSAGE': 'Are you sure to delete the selected role?\n<br><strong><i>{{name}}</i></strong>',
          'DELETE_OK': 'Yes',
          'DELETE_CANCEL': 'No'
        },
        'PERMISSIONS': {
          'USERS_TITLE': 'User Module',
          'PERMISOS_TITLE': 'Permission Module',
          'ROLES_TITLE': 'Roles Module',
          'CLIENTS_TITLE': 'Clients Module',
          'USER_LIST': 'User list view',
          'USER_CREATE': 'User create access',
          'USER_EDIT': 'User edit access',
          'USER_DETAIL': 'View user details',
          'USER_DELETE': 'User delete access',
          'PERMISOS_LIST': 'Permission list view',
          'PERMISO_CREATE': 'Update or add new permissions',
          'PERMISO_DETAIL': 'View details for permission selected',
          'PERMISO_EDIT': 'Update permissions',
          'PERMISO_DELETE': 'Delete selected permission',
          'ROLES_LIST': 'View roles list',
          'ROLE_CREATE': 'Create new roles',
          'ROLE_VIEW': 'View details for selected role',
          'ROLE_EDIT': 'Update selected role',
          'ROLE_DELETE': 'Delete selected role',
          'CLIENT_LIST': 'View clients list',
          'CLIENT_CREATE': 'Create new client',
          'CLIENT_EDIT': 'Access for edit clients',
          'CLIENT_DETAIL': 'Access for view client´s details',
          'CLIENT_DELETE': 'Access for delete clients',
          'CATEGORIES_TITLE': 'Categories',
          'CATEGORIES_LIST': 'View category list',
          'CATEGORIES_CREATE': 'Create new categories',
          'CATEGORIES_EDIT': 'Edit categories information',
          'CATEGORIES_DETAIL': 'View details for selected category',
          'CATEGORIES_DELETE': 'Delete selected category',
          'OFFICES_TITLE': 'Offices',
          'OFFICE_LIST': 'View offices list',
          'OFFICE_CREATE': 'Create new offices',
          'OFFICE_EDIT': 'Edit selected office',
          'OFFICE_DETAIL': 'View details for selected office',
          'OFFICE_DELETE': 'Delete selected office',
          'PRODUCTS_TITLE': 'Products',
          'PRODUCTS_LIST': 'View products list',
          'PRODUCTS_CREATE': 'Create new products',
          'PRODUCTS_EDIT': 'Edit selected product',
          'PRODUCTS_DETAIL': 'View details for product',
          'PRODUCTS_DELETE': 'Delete selected product',
          'PRODUCTS_OFFICE_TITLE': 'Office Branch Products',
          'PRODUCTS_OFFICE_CREATE': 'Create new products',
          'PRODUCTS_OFFICE_LIST': 'View list of products',
          'PRODUCTS_OFFICE_EDIT': 'Edit selected product',
          'PRODUCTS_OFFICE_DETAIL': 'View details of products',
          'PRODUCTS_OFFICE_DELETE': 'Delete selected product',
          'BANNERS_OFFICE_TITLE': 'Banner Module',
          'BANNERS_OFFICE_LIST': 'View list of banners',
          'BANNERS_OFFICE_CREATE': 'Create new banners',
          'BANNERS_OFFICE_EDIT': 'Edit selected banner',
          'BANNERS_OFFICE_DETAIL': 'View details of selected banner',
          'BANNERS_OFFICE_DELETE': 'Delete selected banner',
          'SUBCATEGORIES_TITLE': 'Module Subcategories',
          'SUBCATEGORIES_VIEW': 'Can view subcategory tab inside category module',
          'SUBCATEGORIES_EDIT': 'Can edit selected subcategory',
          'SUBCATEGORIES_CREATE': 'can create new subcategories',
          'SUBCATEGORIES_DELETE': 'can delete selected subcategory',
          'PRICE_LIST_INDEX': 'Can view prices lists',
          'PRICE_LIST_TITLE': 'Price List Module',
          'PRICE_LIST_VIEW': 'Can view details of price list',
          'PRICE_LIST_EDIT': 'Can edit selected price list',
          'PRICE_LIST_CREATE': 'Can create new prices lists',
          'PRICE_LIST_DELETE': 'Can delete selected price list',
          'PRICE_LIST_PRODUCTS_TITLE': 'Products for price list',
          'PRICE_LIST_PRODUCTS_VIEW': 'Can view the list of products',
          'PRICE_LIST_PRODUCTS_EDIT': 'Can edit the selected product',
          'PRICE_LIST_PRODUCTS_CREATE': 'Can add new products to price list',
          'PRICE_LIST_PRODUCTS_DELETE': 'Can delete the selected product from price list',
          'SERVICES_TITLE': 'Services',
          'SERVICES_INDEX': 'View services list',
          'SERVICES_VIEW': 'Create new services',
          'SERVICES_EDIT': 'Edit selected services',
          'SERVICES_CREATE': 'View details for service',
          'SERVICES_DELETE': 'Delete selected service',
          'ORDERS_TITLE': 'Orders',
          'ORDERS_INDEX': 'Can view order\'s list',
          'ORDERS_VIEW': 'View order details',
          'ORDERS_EDIT': 'Can edit Order',
          'ORDERS_CREATE': 'Can create new orders',
          'ORDERS_DELETE': 'Can delete orders',
          'CALENDAR_TITLE': 'Calendar Module',
          'CALENDAR_INDEX': 'Can view appointments list',
          'CALENDAR_VIEW': 'Can view calendar ',
          'CALENDAR_EDIT': 'Can edit an appointment',
          'CALENDAR_CREATE': 'Can create new appointments',
          'CALENDAR_DELETE': 'Can delete appointments',
          'COUPONS_TITLE': 'Coupons module',
          'COUPONS_INDEX': 'View coupons list',
          'COUPONS_VIEW': 'Can view details of coupon',
          'COUPONS_EDIT': 'Can edit details of coupon',
          'COUPONS_CREATE': 'Can create new coupons',
          'COUPONS_DELETE': 'Can delete coupons',
          'CHATBOT_TITLE': 'Chatbot settings',
          'CHATBOT_VIEW': 'View Chatbot settings options',
          'CHATBOT_CREATE': 'Create new chatbot option',
          'CHATBOT_EDIT': 'Edit Chatbot settings',
          'CHATBOT_DELETE': 'Delete Chatbot settings',
          'SETTINGS_TITLE': 'settings module',
          'SETTINGS_VIEW': 'Access to see settings page',
          'SETTINGS_EDIT': 'Access to edit settings options',
          'SETTINGS_API_TITLE': 'Third party API\'s',
          'SETTINGS_API_VIEW': 'View API\'s Information',
          'SETTINGS_API_EDIT': 'Edit API\'s',
          'MESSAGES_TITLE': 'Chat online',
          'MESSAGES_VIEW': 'Can view chat online',
          'SETTINGS_GENERAL_TITLE': 'General settings module',
          'SETTINGS_GENERAL_EDIT': 'Can edit general settings',
          'SETTINGS_GENERAL_VIEW': 'Can view menu option general settings',
          'SETTINGS_MAIL_TITLE': 'Mail server settings',
          'SETTINGS_MAIL_VIEW': 'Can view menu option mail settings',
          'SETTINGS_MAIL_EDIT': 'Can edit mail server settings',
          'SETTINGS_THEME_TITLE': 'Module for configuration of themes and appearance',
          'SETTINGS_THEME_INDEX': 'See the appearance option in the configuration menu',
          'SETTINGS_THEME_CREATE': 'Possibility to add new themes',
          'SETTINGS_THEME_DELETE': 'Possibility of deleting themes from the list',
          'SETTINGS_THEME_PUBLISH': 'Possibility to publish the selected themes',
          'FORMS_TITLE': 'Forms Module',
          'FORMS_INDEX': 'Show forms option in menu',
          'FORMS_VIEW': 'Can view details form',
          'FORMS_CREATE': 'Create new forms',
          'FORMS_UPDATE': 'Can Update forms',
          'FORMS_DELETE': 'Can delete forms'
        }
      },
      'USERS': {
        'TITLE': 'Users',
        'SUBTITLE': 'User Detail',
        'SEARCH': 'Search for a user',
        'ADD_NEW': 'ADD NEW USER',
        'ERROR': {
          'REQUIRED': 'Required',
          'PASSWORD_DONT_MATCH': 'Password do not match',
          'EMPTY_SUCURSAL': 'Please select one office'
        },
        'FORM': {
          'TITLE': 'Title',
          'NAME': 'Name',
          'LAST NAME': 'Last Name',
          'PHONE NUMBER': 'Phone number',
          'OFFICE NUMBER': 'Office number',
          'EMAIL': 'Email',
          'JOB TITLE': 'Job title',
          'BIRTHDAY': 'Birthday',
          'GENDER': 'Gender',
          'MALE': 'Male',
          'FEMALE': 'Female',
          'OTHER': 'Other',
          'NOT SURE': 'Not sure',
          'RATHER NOT SAY': 'Rather not say',
          'RFC': 'RFC',
          'NO LICENCIA': 'No licencia',
          'CEDULA PROFESIONAL': 'Cedula profesional',
          'MATRICULA PROFESIONAL': 'Matricula profesional',
          'ESPECIALIDAD': 'Especialidad',
          'BIOGRAPHY': 'Biography',
          'USERNAME': 'Username',
          'PASSWORD': 'Password',
          'CONFIRM_PASSWORD': 'Confirm password',
          'ROLE': 'Role',
          'LAST LOGIN': 'Last login',
          'SUCURSALES': 'Office Branch',
          'QUOTE': 'Quote',
          'SKILLS': 'Skills',
          'SLOT_DURATION': 'Appointment default duration',
          'NEXT_DAY': 'Show availability after (n) days',
          'MAX_DAYS': 'Show availability this number of days',
          'EXTENDED_APPOINTMENT': {
            'ACTIVED': 'Accept appointment until the last minute',
            'DEACTIVED': 'Accept appointment before the last hour'
          },
          'MULTIPLE_SERVICES': {
            'ACTIVED': 'Enable multiple service selection',
            'DEACTIVED': 'Disable multiple service selection'
          },
          'WORKING_TIME': 'Working time',
          'OPEN_TIME': 'Start working',
          'CLOSE_TIME': 'End working'
        },
        'TABS': {
          'BASIC INFO': 'Basic Info',
          'ACCOUNT INFO': 'Account Info',
          'LOCATIONS': 'Addresses',
          'MESSAGES': 'Messages',
          'ORDERS': 'Orders',
          'FILES': 'Files',
          'CARDS': 'Cards',
          'SERVICES': 'Services',
          'PROVIDER_INFO': 'Provider Info',
          'QUESTIONNAIRES': 'Questionnaires'
        },
        'TABLE': {
          'NAME': 'Name',
          'EMAIL': 'Email',
          'PHONE': 'Phone',
          'JOB TITLE': 'Job Title',
          'ROLE': 'Role',
          'ACTIVE': 'Active',
          'INACTIVE': 'Inactive',
          'BAN': 'Ban',
          'ACTIVATE': 'Activate',
          'BANNED': 'banned',
          'EDIT': 'Edit',
          'REMOVE': 'Remove',
          'VIEW': 'View',
          'DELETE_TITLE': 'Confirm deletion',
          'DELETE_MESSAGE': 'Are you sure to delete the selected user?\n<br><strong><i>{{name}}</i></strong>',
          'DELETE_OK': 'Yes',
          'DELETE_CANCEL': 'No'
        },
        'MODAL': {
          'CHOOSE_PICTURE': 'Choose Profile Picture',
          'CROP CIRCLE': 'Crop circle',
          'CROP SQUARE': 'Crop square',
          'ROTATE PLUS 90': 'Rotate +90°',
          'ROTATE MINUS 90': 'Rotate -90°',
          'FLIP VERTICAL': 'Flip vertical',
          'FLIP HORIZONTAL': 'Flip horizontal',
          'CANCEL': 'Cancel',
          'SAVE': 'Save',
          'DELETE': {
            'TITLE': 'Removing address',
            'TEXT': 'It will be permanently removed',
            'CONFIRM': 'Ok',
            'CANCEL': 'cancel',
            'SUCCESS': 'Address deleted'
          }
        },
        'ADDRESS': {
          'MODAL': {
            'TITLE': { 
              'NEW': 'Add new address',
              'EDIT': 'Edit address'
            },
            'NAME': 'Alias',
            'STREET': 'Street',
            'NUMBER': 'Exterior number',
            'INT_NUMBER': 'Interior number',
            'SUBURB': 'Suburb',
            'TOWNSHIP': 'Township',
            'CITY': 'City',
            'STATE': 'State',
            'COUNTRY': 'Country',
            'DIRECTIONS': 'Directions',
            'POSTAL_CODE': 'Postal code'
          }
        },
        'FILES': {
          'FILES': '{{number}} Files',
          'PDF_FILES': '{{number}} Documents',
          'IMAGES_FILES': '{{number}} Images',
          'VIDEO_FILES': '{{number}} Video',
          'AUDIO_FILES': '{{number}} Audio',
          'OTHER_FILES': '{{number}} Others',
          'UPLOADING': 'Uploading',
          'FILE_UPLOAD': 'Upload File',
          'TAGS': 'Tags',
          'ERRORS': {
            'WRONG_TYPE': 'Wrong file type',
            'WRONG_SIZE': 'Max size exceeded',
          },
          'TABLE': {
            'FILES': 'Files',
            'NAME': 'Name',
            'TYPE': 'Type',
            'SIZE': 'Size',
            'UPDATED_AT': 'Last Modified',
            'EDIT': 'Open file',
            'SHARE': 'Share',
            'DELETED': 'Delete file'
          },
          'FILE_DETAILS': {
            'DETAILS': 'Details',
            'NAME': 'Name',
            'TAGS': 'Tags',
            'SIZE': 'Size',
            'LAST_MODIFIED': 'Last modified',
            'VERSION': 'Version',
            'SHARE': 'Share',
            'LINK': 'Link',
            'SAVE': 'Save',
            'DELETE': 'Delete',
            'FOLDER': 'Folder',
            'COPIED_CONTENT': 'Copied content',
            'DELETE_TITLE': 'Confirm deletion',
            'DELETE_MESSAGE': 'Are you sure to delete the selected file?',
            'DELETE_OK': 'Yes',
            'DELETE_CANCEL': 'No',
            'SENDED_CONTENT': 'File sent',
            'WAITING_UPLOAD': 'Waiting upload',
            'UPLOAD_NEW_VERSION': 'Upload new version'
          }
        },
        'CARDS': {
          'NAME': 'Name',
          'TYPE': 'Type',
          'BRAND': 'Brand',
          'EXPIRATION': 'Expiration',
          'CARD': 'Card number',
          'CARD_TITLE': 'Card details',
          'FAV': 'Favorite',
          'ADD_NEW': 'Add new',
          'CVC': 'CVC',
          'SAVE': 'Save',
          'DELETE': 'Delete',
          'DELETE_TITLE': 'Confirm deletion',
          'DELETE_MESSAGE': 'Are you sure to delete the selected card?',
          'DELETE_OK': 'Yes',
          'DELETE_CANCEL': 'No',
          'ERROR': {
            'DATE': 'Wrong expiration date'
          }
        },
        'SERVICES': {
          'NAME': 'Name',
          'DESCRIPTION': 'Description',
          'PRICE': 'Price',
          'ACTIVE': 'Active',
          'ADD_NEW': 'Add new',
          'CARD_TITLE': 'Service Details',
          'SAVE': 'Save',
          'DELETE': 'Delete',
          'DURATION': 'Duration'
        },
        'QUESTIONNAIRES': {
          'TABLE': {
            'NAME': 'Name',
            'FIELDS': 'Fields',
            'STATUS': 'Status',
            'UPDATED_AT': 'Last date',
            'EDIT': 'Open questionnaire',
            'DELETED': 'Delete'
          }
        }
      }
    },
    'CLIENTS': {
      'TITLE': 'Clients',
      'SUBTITLE': 'Client Detail',
      'SEARCH': 'Search',
      'ADD_NEW': 'Add new client',
      'ERROR': {
        'REQUIRED': 'Required',
        'PASSWORD_DONT_MATCH': 'Password do not match'
      },
      'TABLE': {
        'NAME': 'Name',
        'EMAIL': 'Email',
        'PHONE': 'Phone',
        'JOB TITLE': 'Job Title',
        'ROLE': 'Role',
        'ACTIVE': 'Active',
        'INACTIVE': 'Inactive',
        'BAN': 'Ban',
        'ACTIVATE': 'Activate',
        'BANNED': 'banned',
        'EDIT': 'Edit',
        'REMOVE': 'Remove',
        'VIEW': 'View'
      },
      'TABS': {
        'BASIC INFO': 'Basic Info',
        'ACCOUNT INFO': 'Account Info',
        'LOCATIONS': 'Addresses'
      },
      'FORM': {
        'TITLE': 'Title',
        'NAME': 'Name',
        'LAST NAME': 'Last Name',
        'PHONE NUMBER': 'Phone number',
        'OFFICE NUMBER': 'Office number',
        'EMAIL': 'Email',
        'JOB TITLE': 'Job title',
        'BIRTHDAY': 'Birthday',
        'GENDER': 'Gender',
        'MALE': 'Male',
        'FEMALE': 'Female',
        'OTHER': 'Other',
        'NOT SURE': 'Not sure',
        'RATHER NOT SAY': 'Rather not say',
        'RFC': 'RFC',
        'NO LICENCIA': 'No licencia',
        'CEDULA PROFESIONAL': 'Cedula profesional',
        'MATRICULA PROFESIONAL': 'Matricula profesional',
        'ESPECIALIDAD': 'Especialidad',
        'BIOGRAPHY': 'Biography',
        'USERNAME': 'Username',
        'PASSWORD': 'Password',
        'CONFIRM_PASSWORD': 'Confirm password',
        'ROLE': 'Role',
        'LAST LOGIN': 'Last login'
      },
    },
    'OFFICES': {
      'ERROR': {
        'REQUIRED': 'Required field',
        'INVALID': 'Invalid field'
      },
      'TITLE': 'Offices',
      'SUBTITLE': 'Office details',
      'SEARCH': 'Search',
      'ADD_NEW': 'Add new office',
      'TABLE': {
        'NAME': 'Name',
        'EMAIL': 'Email',
        'PHONE': 'Phone',
        'ACTIVE': 'Active',
        'BANNED': 'Banned',
        'ACTIVATE': 'Activate',
        'BAN': 'Ban',
        'EDIT': 'Edit',
        'REMOVE': 'Delete',
        'DIRECTIONS': 'Directions',
        'VIEW': 'View',
        'DELETE_TITLE': 'Confirm deletion',
        'DELETE_MESSAGE': 'Are you sure to delete the selected office?\n<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Yes',
        'DELETE_CANCEL': 'No'
      },
      'TABS': {
        'BASIC INFO': 'Details',
        'PRICE_LIST': 'Price List'
      },
      'FORM': {
        'NAME': 'Name',
        'COMPANY': 'Company',
        'PHONE': 'Phone',
        'EMAIL': 'Email',
        'RFC': 'FTR',
        'CURP': 'UPRK',
        'DIRECTIONS': 'Directions',
        'LOCATION': 'Location',
        'RANGE': 'Range',
        'STREET': 'Street',
        'COLONY': 'Town',
        'CITY': 'City',
        'STATE': 'State',
        'PRICE_LIST': 'Price list for this office',
        'STOCK': 'Stock',
        'DURATION': 'Appointment duration',
        'QTY': 'Appointment places',
        'OPEN_TIME': 'Opening time',
        'CLOSE_TIME': 'Closing time',
        'DISPATCHABLE': 'Dispatchable',
        'IN_OFFICE': 'In office branch',
        'TAX': 'Tax (informational only)',
      }
    },
    'CATEGORIES': {
      'ERROR': {
        'REQUIRED': 'Required'
      },
      'TITLE': 'Categories',
      'SUBTITLE': 'Category Details',
      'SEARCH': 'Search',
      'ADD_NEW': 'Add new category',
      'TABLE': {
        'NAME': 'Name',
        'COLOR': 'Color',
        'ACTIVE': 'Active',
        'BANNED': 'Banned',
        'ACTIVATE': 'Activate',
        'BAN': 'Ban',
        'EDIT': 'Edit',
        'REMOVE': 'Delete',
        'VIEW': 'View',
        'DELETE_TITLE': 'Confirm deletion',
        'DELETE_MESSAGE': 'Are you sure to delete the selected category?\n<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Yes',
        'DELETE_CANCEL': 'No'
      },
      'TABS': {
        'BASIC INFO': 'Details',
        'SUBCATEGORIES': 'Subcategories'
      },
      'FORM': {
        'NAME': 'Name',
        'COLOR': 'Color',
        'DESCRIPTION': 'Description',
        'ACTIVE': 'Activated'
      }
    },
    'PRODUCTS' :{
      'ERROR': {
        'REQUIRED': 'Required field',
        'INVALID': 'Invalid field',
        'SEND_METHOD_ERROR': 'Please select one',
        'CANT_ZERO': 'The value cannot be less than or equal to zero'
      },
      'TITLE': 'Products',
      'SUBTITLE': 'Product details',
      'SEARCH': 'Search',
      'ADD_NEW': 'Add new product',
      'TABLE': {
        'NAME': 'Name',
        'DESCRIPTION': 'Description',
        'PRICE': 'Price w/o Tax',
        'PRICE_VENTA': 'Price w/ Tax',
        'DISPATCHABLE': 'Modality',
        'REQUIREMENTS': 'Requirements',
        'CATEGORY': 'Category',
        'ACTIVE': 'Available',
        'BANNED': 'Banned',
        'ACTIVATE': 'Activate',
        'BAN': 'Ban',
        'EDIT': 'Edit',
        'REMOVE': 'Delete',
        'VIEW': 'View',
        'DELETE_TITLE': 'Confirm deletion',
        'DELETE_MESSAGE': 'Are you sure to delete the selected product?\n<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Yes',
        'DELETE_CANCEL': 'No',
        'EXPORT': 'Export',
        'IMPORT': 'Import'
      },
      'TABS': {
        'BASIC INFO': 'Details',
        'PRICES': 'Prices',
        'INVENTORY': 'Inventory',
        'SAT': 'IRS'
      },
      'FORM': {
        'NAME': 'Name',
        'CATEGORY': 'Category',
        'PRICE': 'Price w/o Tax',
        'DESCRIPTION': 'Description',
        'ACTIVE': 'Available',
        'DISPATCHABLE': 'Dispatchable',
        'IN_OFFICE': 'In office branch',
        'REQUIREMENTS': 'Requirements',
        'APPLICATION_TYPE': 'Application type',
        'PRICE_REAL': 'Price w/o Tax',
        'PRICE_VENTA': 'Price w/ Tax',
        'PRICE_PROMOCION': 'Discount Price',
        'PRICE_RENTA': 'Rental Price',
        'SKU': 'SKU',
        'BARCODE': 'Barcode',
        'OFFICE': {
          'PRICE_REAL': 'Cost for office branch',
          'PRICE_VENTA': 'Base Price for office branch',
          'PRICE_PROMOCION': 'Discount Price for office branch',
          'PRICE_RENTA': 'Rental Price for office branch'
        },
        'SAT_CLAVE': 'IRS Key',
        'SAT_UNIDAD': 'IRS Unity',
        'SAT_CONCEPTO': 'IRS Concept',
        'SAT_CONCEPTO_UNIDAD': 'IRS Unity Concept'
      },
      'EXPORT': {
        'TEMPLATE': 'Template'
      },
      'IMPORT': {
        'TITLE': 'Import',
        'SUBTITLE': 'Products',
        'SUCCESS_UPLOAD': 'Success upload',
        'DOWNLOAD_TEMPLATE': 'Download template',
        'ERROR': {
          'EMPTY': 'Can\'t be empty',
          'DUPLICATED': 'Duplicated',
          'NEW_CATEGORY': 'Wrong category',
          'NEW_APPLICATION_TYPE': 'Wrong application type',
          'NO_DISPATCHABLE': 'Select one option'
        },
        'TABLE': {
          'NAME': 'Name',
          'PRICES': 'Prices',
          'CATEGORY': 'Category',
          'SHIPPING': 'Shipping',
          'ACTIVE': 'Active',
          'BARCODE': 'Barcode',
          'SKU': 'Sku',
          'PRICE_REAL': 'w/o Tax',
          'PRICE_VENTA': 'w/ Tax',
          'PRICE_RENTA': 'Rent',
          'PRICE_PROMOCION': 'Promo',
          'APPLICATION_TYPE': 'Application type',
          'TAGS': 'Tags',
          'IN_OFFICE': 'In office',
          'DISPATCHABLE': 'Send to client',
          'SAT_CLAVE': 'IRS Key',
          'SAT_UNIDAD': 'IRS Unity',
        },
        'SIDEBAR': {
          'TITLE': 'Edit before upload',
          'FORM': {
            'NAME': 'Name',
            'CATEGORY': 'Category',
            'PRICE': 'Cost',
            'DESCRIPTION': 'Description',
            'ACTIVE': 'Available',
            'DISPATCHABLE': 'Dispatchable',
            'IN_OFFICE': 'In office branch',
            'REQUIREMENTS': 'Requirements',
            'APPLICATION_TYPE': 'Application type',
            'PRICE_REAL': 'Price w/o Tax',
            'PRICE_VENTA': 'Proce w/ Tax',
            'PRICE_PROMOCION': 'Discount Price',
            'PRICE_RENTA': 'Rental Price',
            'SKU': 'SKU',
            'BARCODE': 'Barcode',
            'OFFICE': {
              'PRICE_REAL': 'Cost for office branch',
              'PRICE_VENTA': 'Base Price for office branch',
              'PRICE_PROMOCION': 'Discount Price for office branch',
              'PRICE_RENTA': 'Rental Price for office branch'
            },
            'DETAILS': 'Details',
            'SHORT_DESCRIPTION': 'Short description',
            'PRICES': 'Prices',
            'PRICES_LIST': 'Price lists',
            'UPDATE': 'Update',
            'CANCEL': 'Cancel',
            'SAT_FIELDS': 'SAT',
            'SAT_CLAVE': 'IRS Key',
            'SAT_UNIDAD': 'IRS Unity',
            'SAT_CONCEPTO': 'IRS Concept',
            'SAT_CONCEPTO_UNIDAD': 'IRS Unity Concept'
          },
        }
      }
    },
    'BANNERS_OFFICE': {
      'ADD': {
        'TITLE': 'Create new banner'
      },
      'ERROR': {
        'REQUIRED': 'Required field',
        'INVALID': 'Invalid field'
      },
      'TITLE': 'Banners',
      'SUBTITLE': 'Banner details',
      'SEARCH': 'Search',
      'ADD_NEW': 'Add new banner',
      'TABLE': {
      },
      'TABS': {
        'BASIC INFO': 'Details'
      },
      'FORM': {
        'URL': 'Link',
        'BACKGROUND_COLOR': 'Background color',
        'BACKGROUND_IMAGE': 'Background Image',
        'MESSAGE': 'Message',
        'ALIGN': {
          'VERTICAL': 'Vertical align',
          'HORIZONTAL': 'Horizontal align',
          'CENTER': 'Center',
          'TOP': 'Top',
          'BOTTOM': 'Bottom',
          'LEFT': 'Left',
          'MIDDLE': 'Middle',
          'RIGHT': 'Right'
        },
        'ACTIVE': 'Active',
        'DATES': 'Date range'
      },
      'BANNERS LIST': 'Banner list',
      'BANNER CONFIG': 'Banner configuration',
      'DELETE_TITLE': 'Confirm deletion',
      'DELETE_MESSAGE': 'Are you sure to delete the selected banner?',
      'DELETE_OK': 'Yes',
      'DELETE_CANCEL': 'No',
    },
    'PRICE_LISTS': {
      'ERROR': {
        'REQUIRED': 'Required',
        'ZERO_VALUE': 'price in zero or negative value'
      },
      'TITLE': 'Price Lists',
      'SUBTITLE': 'Price list details',
      'SEARCH': 'Search',
      'ADD_NEW': 'Add new list',
      'TABLE': {
        'NAME': 'Name',
        'ACTIVE': 'Active',
        'BANNED': 'Banned',
        'ACTIVATE': 'Activate',
        'BAN': 'Ban',
        'EDIT': 'Edit',
        'REMOVE': 'Delete',
        'VIEW': 'View',
        'DELETE_TITLE': 'Confirm deletion',
        'DELETE_MESSAGE': 'Are you sure to delete the selected price list?\n<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Yes',
        'DELETE_CANCEL': 'No',
        'PRODUCTS': {
          'DELETE_TITLE': 'Confirm deletion',
          'DELETE_MESSAGE': 'Are you sure to delete the selected product from list?\n<br><strong><i>{{name}}</i></strong>',
          'DELETE_OK': 'Yes',
          'DELETE_CANCEL': 'No',
        }
      },
      'TABS': {
        'BASIC INFO': 'Basic info',
        'PRODUCTS': 'Products'
      },
      'FORM': {
        'NAME': 'Name',
        'DESCRIPTION': 'Description',
        'FILTER': 'Search',
        'ACTIVE': 'Active',
        'PRECIO_REAL': 'Price w/o Tax',
        'PRECIO_VENTA': 'Price w/ Tax',
        'PRECIO_PROMOCION': 'Promo price'
      }
    },
    'SERVICES' :{
      'ERROR': {
        'REQUIRED': 'Required field',
        'INVALID': 'Invalid field',
        'SEND_METHOD_ERROR': 'Please select one',
        'CANT_ZERO': 'The value cannot be less than or equal to zero'
      },
      'TITLE': 'Services',
      'SUBTITLE': 'Service details',
      'SEARCH': 'Search',
      'ADD_NEW': 'Add new service',
      'TABLE': {
        'NAME': 'Name',
        'DESCRIPTION': 'Description',
        'PRICE': 'Price w/o Tax',
        'PRICE_VENTA': 'Price w/ Tax',
        'DISPATCHABLE': 'Modality',
        'REQUIREMENTS': 'Requirements',
        'CATEGORY': 'Category',
        'ACTIVE': 'Available',
        'BANNED': 'Banned',
        'ACTIVATE': 'Activate',
        'BAN': 'Ban',
        'EDIT': 'Edit',
        'REMOVE': 'Delete',
        'VIEW': 'View',
        'DELETE_TITLE': 'Confirm deletion',
        'DELETE_MESSAGE': 'Are you sure to delete the selected service?\n<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Yes',
        'DELETE_CANCEL': 'No',
        'EXPORT': 'Export',
        'IMPORT': 'Import'
      },
      'TABS': {
        'BASIC INFO': 'Details',
        'PRICES': 'Prices',
        'INVENTORY': 'Inventory',
        'SAT': 'IRS'
      },
      'FORM': {
        'NAME': 'Name',
        'CATEGORY': 'Category',
        'PRICE': 'Price w/o Tax',
        'DESCRIPTION': 'Description',
        'ACTIVE': 'Available',
        'DISPATCHABLE': 'Dispatchable',
        'IN_OFFICE': 'In office branch',
        'REQUIREMENTS': 'Requirements',
        'APPLICATION_TYPE': 'Application type',
        'PRICE_REAL': 'Price w/o Tax',
        'PRICE_VENTA': 'Price w/ Tax',
        'PRICE_PROMOCION': 'Discount Price',
        'PRICE_RENTA': 'Rental Price',
        'SKU': 'SKU',
        'BARCODE': 'Barcode',
        'OFFICE': {
          'PRICE_REAL': 'Cost for office branch',
          'PRICE_VENTA': 'Base Price for office branch',
          'PRICE_PROMOCION': 'Discount Price for office branch',
          'PRICE_RENTA': 'Rental Price for office branch'
        },
        'SAT_CLAVE': 'IRS Key',
        'SAT_UNIDAD': 'IRS Unity',
        'SAT_CONCEPTO': 'IRS Concept',
        'SAT_CONCEPTO_UNIDAD': 'IRS Unity Concept'
      },
      'EXPORT': {
        'TEMPLATE': 'Template'
      },
      'IMPORT': {
        'TITLE': 'Import',
        'SUBTITLE': 'Services',
        'SUCCESS_UPLOAD': 'Success upload',
        'DOWNLOAD_TEMPLATE': 'Download template',
        'ERROR': {
          'EMPTY': 'Can\'t be empty',
          'DUPLICATED': 'Duplicated',
          'NEW_CATEGORY': 'Wrong category',
          'NEW_APPLICATION_TYPE': 'Wrong application type',
          'NO_DISPATCHABLE': 'Select one option'
        },
        'TABLE': {
          'NAME': 'Name',
          'PRICES': 'Prices',
          'CATEGORY': 'Category',
          'SHIPPING': 'Shipping',
          'ACTIVE': 'Active',
          'BARCODE': 'Barcode',
          'SKU': 'Sku',
          'PRICE_REAL': 'w/o Tax',
          'PRICE_VENTA': 'w/ Tax',
          'PRICE_RENTA': 'Rent',
          'PRICE_PROMOCION': 'Promo',
          'APPLICATION_TYPE': 'Application type',
          'TAGS': 'Tags',
          'IN_OFFICE': 'In office',
          'DISPATCHABLE': 'Send to client',
          'SAT_CLAVE': 'IRS Key',
          'SAT_UNIDAD': 'IRS Unity',
        },
        'SIDEBAR': {
          'TITLE': 'Edit before upload',
          'FORM': {
            'NAME': 'Name',
            'CATEGORY': 'Category',
            'PRICE': 'Price w/o Tax',
            'DESCRIPTION': 'Description',
            'ACTIVE': 'Available',
            'DISPATCHABLE': 'Dispatchable',
            'IN_OFFICE': 'In office branch',
            'REQUIREMENTS': 'Requirements',
            'APPLICATION_TYPE': 'Application type',
            'PRICE_REAL': 'Price w/o Tax',
            'PRICE_VENTA': 'Price w/ Tax',
            'PRICE_PROMOCION': 'Discount Price',
            'PRICE_RENTA': 'Rental Price',
            'SKU': 'SKU',
            'BARCODE': 'Barcode',
            'OFFICE': {
              'PRICE_REAL': 'Cost for office branch',
              'PRICE_VENTA': 'Base Price for office branch',
              'PRICE_PROMOCION': 'Discount Price for office branch',
              'PRICE_RENTA': 'Rental Price for office branch'
            },
            'DETAILS': 'Details',
            'SHORT_DESCRIPTION': 'Short description',
            'PRICES': 'Prices',
            'PRICES_LIST': 'Price lists',
            'UPDATE': 'Update',
            'CANCEL': 'Cancel',
            'SAT_FIELDS': 'SAT',
            'SAT_CLAVE': 'IRS Key',
            'SAT_UNIDAD': 'IRS Unity',
            'SAT_CONCEPTO': 'IRS Concept',
            'SAT_CONCEPTO_UNIDAD': 'IRS Unity Concept'
          },
        }
      }
    },
    'ORDERS': {
      'TITLE': 'Orders',
      'SUBTITLE': 'Order\'s Details',
      'SEARCH': 'Search',
      'TABLE': {
        'PAYMENT_METHOD': 'Payment method',
        'TOTAL': 'Total',
        'FROM': 'From',
        'CREATED': 'Created',
        'STATUS': 'Status',
        'CANCELED': 'Cancelled',
        'PAID': 'Paid',
        'PENDING': 'Pending',
        'AWAITING': 'Awaiting payment',
        'COMPLETED': 'Completed',
        'DECLINED': 'Declined',
        'REFUNDED': 'Refunded',
        'DISPUTED': 'Disputed',
        'VIEW': 'View',
        'REMOVE': 'Delete',
        'APPLICANT': 'Applicant',
        'CHANGE_STATUS': 'Change status',
        'REFERENCE': 'Reference',
        'INVOICE': 'Invoice',
        'GENERATE_INVOICE': 'Generate Invoice',
        'PRICE': 'Price',
        'QTY': 'Qty',
        'SUBTOTAL': 'Subtotal',
        'DISCOUNT': 'Discount',
        'SEND_INVOICE': 'Send invoice'
      },
      'DETAILS': {
        'PURCHASE_DETAILS': 'Purchase details',
        'ORDER_STATUS': 'Order status',
        'AMOUNT': 'Amount',
        'PAYMENT_METHOD': 'Payment method',
        'CREATION_DATE': 'Creation date',
        'CUSTOMER_DETAILS': 'Customer details',
        'CUSTOMER_NAME': 'Name',
        'CUSTOMER_PHONE': 'Phone',
        'CUSTOMER_EMAIL': 'Email',
        'PAYMENT_DETAILS': 'Payment details',
        'PAYMENT_AMOUNT': 'Amount',
        'PAYMENT_CARD': 'Card',
        'PAYMENT_CODE': 'Auth code',
        'PAYMENT_INVOICE': 'Invoice',
        'PAYMENT_OPEN_INVOICE': 'Open in new window',
        'PAYMENT_EXPIRED': 'EXPIRED',
        'PAYMENT_PAID': 'PAID',
      },
      'TABS': {
        'DETAILS': 'Details',
        'CONTENT': 'Content'
      },
      'INVOICE': {
        'MODAL': {
          'TITLE': 'Invoice',
          'CLOSE': 'Close'
        }
      },
      'GENERATE_INVOICE': {
        'MODAL': {
          'TITLE': 'Generate Invoice',
          'FORM': {
            'RFCS': 'Client´s Invoice saved',
            'RFC': 'RFC',
            'RAZON_SOCIAL': 'Business name',
            'CFDI': 'CFDI',
            'EMAIL': 'Email',
            'CLOSE': 'Close',
            'GENERATE': 'Generate'
          }
        }
      }
    },
    'CALENDAR': {
      'TITLE': 'Calendar',
      'SEACH': 'Search',
      'TODAY': 'Today',
      'DAY': 'Day',
      'WEEK': 'Week',
      'MONTH': 'Month',
      'CATEGORIES': 'Categories',
      'TABS': {
        'SCHEDULE': 'Schedule'
      },
      'SIDEBAR': {
        'TITLE': 'Appointment Details',
        'CLIENT': 'Client',
        'APPOINTMENT': 'Appointment',
        'DISPATCHABLE': 'Dispatchable',
        'IN_OFFICE': 'In office',
        'ORDER': 'Order',
        'CANCELED': 'Cancelled',
        'PAID': 'Paid',
        'PENDING': 'Pending',
        'AWAITING': 'Awaiting payment',
        'COMPLETED': 'Completed',
        'DECLINED': 'Declined',
        'REFUNDED': 'Refunded',
        'DISPUTED': 'Disputed',
        'RELATED_APPOINTMENTS': 'Related appointments',
        'SCHEDULED': 'Scheduled',
        'NO SHOW': 'No show',
        'PENDING APPROVAL': 'pending approval',
        'REQUEST EXPIRED': 'request expired',
        'NEW TIME PROPOSED': 'New time proposed',
        'PENDING RESCHEDULE': 'Pending reschedule',
        'PROVIDER': 'Assigned Doctor',
        'FORM': {
          'COMMENTS': 'Comments',
          'UPDATE': 'Update',
          'CANCEL': 'Cancel',
          'BACK': 'Back',
          'STATUS': 'Appointment status',
          'WAITING_UPLOAD': 'waiting to upload',
          'ARCHIVED': 'Archived'
        }
      },
      'MODAL': {
        'NEW_APPOINTMENT': {
          'TITLE': 'Appointments details',
          'START_DATE': 'Start Date',
          'START_TIME': 'Stat Time',
          'END_DATE': 'End Date',
          'END_TIME': 'End Time',
          'CLIENT': 'Client',
          'STATUS_DATE': 'Date status',
          'COMMENTS': 'Comments',
          'OFFICE_BRANCH': 'Office',
          'PROVIDER': 'Assigned doctor'
        }
      }
    },
    'COUPONS': {
      'TITLE': 'Coupons',
      'SEARCH': 'Search',
      'ADD_NEW': 'create new coupon',
      'SUBTITLE': 'Coupon details',
      'FORM': {
        'NAME': 'Name',
        'CODE': 'Code',
        'DISCOUNT_TYPE': 'Discount type',
        'TYPE': 'Type',
        'VALUE': 'Discount value',
        'EXPIRATION': 'Expiration range',
        'QTY': 'Max usage',
        'ACTIVE': 'Activated',
        'BY_PRODUCTS': 'By products',
        'BY_CART': 'By cart',
        'FILTER': 'Search product'
      },
      'ERROR': {
        'REQUIRED': 'Required',
        'LOWER_PERCENT': 'value is less than 1%'
      },
      'TABS': {
        'BASIC INFO': 'Details',
        'MODALITY': 'Modality',
        'PRODUCTS': 'Products'
      },
      'TABLE': {
        'NAME': 'Name',
        'CODE': 'Code',
        'DISCOUNT_TYPE': 'Discount',
        'TYPE': 'Discount by',
        'EXPIRATION': 'Expiration Time',
        'QTY': 'Max usage',
        'ACTIVE': 'Active',
        'BANNED': 'Banned',
        'ACTIVATE': 'Activate',
        'BAN': 'Ban',
        'EDIT': 'Edit',
        'REMOVE': 'Delete',
        'VIEW': 'View',
        'DELETE_TITLE': 'Confirm deletion',
        'DELETE_MESSAGE': 'Are you sure to delete the selected coupon?\n<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Yes',
        'DELETE_CANCEL': 'No',
        'START': 'Start',
        'END': 'End',
        'USED': 'Used',
        'PRODUCTS': {
          'DELETE_TITLE': 'Confirm deletion',
          'DELETE_MESSAGE': 'Are you sure to delete the selected product from list?\n<br><strong><i>{{name}}</i></strong>',
          'DELETE_OK': 'Yes',
          'DELETE_CANCEL': 'No',          
        }
      }
    },
    'APPOINTMENTS': {
      'TITLE': 'Appointments',
      'SEARCH': 'Search',
      'ADD_NEW': 'Create new appointment',
      'TABLE': {
        'DATE': 'Date',
        'CLIENT': 'Client',
        'STATUS': 'Status',
        'NOTES': 'Comments',
        'EDIT': 'Edit',
        'VIEW': 'View',
        'DISPATCHABLE': 'Dispatchable',
        'IN_OFFICE': 'In office',
        'ORDER': 'Order',
        'CANCELED': 'Cancelled',
        'PAID': 'Paid',
        'PENDING': 'Pending',
        'AWAITING': 'Awaiting payment',
        'COMPLETED': 'Completed',
        'DECLINED': 'Declined',
        'REFUNDED': 'Refunded',
        'DISPUTED': 'Disputed',
        'RELATED_APPOINTMENTS': 'Related appointments',
        'SCHEDULED': 'Scheduled',
        'NO SHOW': 'No show',
        'PENDING APPROVAL': 'pending approval',
        'REQUEST EXPIRED': 'request expired',
        'NEW TIME PROPOSED': 'New time proposed',
        'PENDING RESCHEDULE': 'Pending reschedule',
        'SERVICE': 'Service',
        'PHONE': 'Phone',
        'LOCATION': 'Location',
        'OFFICE': 'Office'
      }
    },
    'SETTINGS': {
      'TITLE': 'Settings',
      'ADD_NEW_CHAT': 'Add chat response',
      'TABS': {
        'BASIC': 'General configuration',
        'CHATBOT': 'Chatbot',
        'CALENDAR': 'Calendar',
        'API': 'API\'s'
      },
      'ERRORS': {
        'REQUIRED': 'Required',
        'LOWER_PERCENT': 'value is less than 1%',
        'SAVE': 'Please, try again in 5 minutes',
        'NO_LOAD': 'Unable to load page'
      },
      'TABLE': {
        'MESSAGE': 'Expected message',
        'RESPONSE': 'Bot response',
        'ACTIVE': 'Active',
        'BANNED': 'Banned',
        'ACTIVATE': 'Activate',
        'BAN': 'Ban',
        'EDIT': 'Edit',
        'REMOVE': 'Delete',
        'VIEW': 'View',
      },
      'FORM': {
        'GENARAL': {
          'NAME': 'Enterprise name'
        },
        'TITLE': 'Chatbot Settings',
        'MESSAGE': 'Expected message',
        'RESPONSE': 'Bot response',
        'DEACTIVED': 'Deactivated',
        'ACTIVED': 'Activated',
        'RESPONSE_OFFICES': 'Office branch list (max. 10)',
        'RESPONSE_AGENT': 'An agent will attend you in a few minutes',
        'SAVE': 'Save',
        'UPDATE': 'Update',
        'DELETE': 'Delete',
        'CANCEL': 'Cancel',
        'CHIPS': {
          'USERNAME': 'Client´s name',
          'PRODUCTS': 'Search products',
          'SERVICES': 'Search services',
          'OFFICES': 'Offices',
          'URL': 'url online store',
          'NOTIFY_AGENT': 'Notify agent',
          'FOLIO': 'Invoice number',
          'RESPONSE_VARIABLES': 'Variables you can use on responses',
          'REQUEST_VARIABLES': 'Variables you can use to match questions'
        },
        'NAME': 'Name',
        'LOGO': 'Logo',
        'ICON': 'Icon',
        'STORE_LINK': 'Online store link',
        'CURRENCY': 'Currency',
        'PAYMENTS_METHOD': 'Online payment provider',
        'CONEKTA_PUBLIC_KEY': 'Conekta public key',
        'CONEKTA_PRIVATE_KEY': 'Conekta private key',
        'CONEKTA_OXXO_EXPIRATION': 'Conekta OXXO payment expiration',
        'CONEKTA_OXXO_MINUTES': 'minutes',
        'CONEKTA_OXXO_DEFAULT_MINUTES': 'Default 60 minutes',
        'INVOICE_PROVIDER': 'Invoice provider',
        'FACTURAMA_USER': 'Facturama user',
        'FACTURAMA_PASSWORD': 'Facturama password',
        'FACTURAMA_MODE': 'Production environment',
        'FACTURAMA_MODE_LABEL': 'Production',
        'WHATSAPP_PROVIDER': 'Whatssap message provider',
        'TWILIO_ACCOUNT_SID': 'Account SID',
        'TWILIO_AUTH_TOKEN': 'Auth Token',
        'TWILIO_PHONE_FROM': 'Phone number from',
        'GOOGLE_API_DISCLAIMER': 'Google services API key',
        'GOOGLE_API': 'Google API',
        'GOOGLE_FIREBASE': 'Google Firebase database (Real-time Database)',
        'PAYPAL_API_DISCLAIMER': 'Paypal api integration',
        'PAYPAL_CLIENT': 'Paypal API key',
        'EMAIL': 'Email',
        'PHONE': 'Phone',
        'CP': 'Postal Code',
        'ADDRESS': 'No. & Street',
        'CITY': 'City',
        'STATE': 'State',
        'COUNTRY': 'Country',
        'USE_WEBHOOK': 'Use webhook',
        'WEBHOOK_URL': 'Webhook link',
        'WEBHOOK_METHOD': 'Webhook Method'
      },
      'MODAL': {
        'DELETE_TITLE': 'Confirm deletion',
        'DELETE_MESSAGE': 'Are you sure to delete the selected chatbot question?\n<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Yes',
        'DELETE_CANCEL': 'No'
      },
      'GENERAL': {
        'TITLE': 'General settings'
      },
      'CHATBOT': {
        'TITLE': 'Chatbot settings',
        'TABS': {
          'BASIC': 'General Info',
          'MESSAGES': 'Messages'
        },
        'FORM': {
          'DEFAULT_RESPONSE': 'Default response',
          'DISABLE_TIME': 'Duration that the bot will remain disabled'
        }
      },
      'APIS': {
        'TITLE': 'Api\'s integration'
      },
      'MAIL': {
        'TITLE': 'Server mail settings',
        'FORM': {
          'HOST': 'Host',
          'PORT': 'Port',
          'ENCRYPTION': 'Encryption',
          'USERNAME': 'Username',
          'PASSWORD': 'Password',
          'MAILER': 'Mail driver',
          'SENDER': 'Sending mail'
        },
        'TEST_MAIL_SERVER': 'Test Settings',
        'MODAL': {
          'TITLE': 'Testing email server',
          'EMAIL': 'Email',
          'SEND': 'Send'
        }
      },
      'THEME': {
        'TITLE': 'Appearance',
        'FORM': {
          'PUBLISH': 'Publish',
          'DELETE': 'Delete',
          'SAVE': 'Save',
          'NAME': 'Name',
          'CANCEL': 'Cancel',
          'EDIT': 'Edit',
          'CLAVE': 'Code name',
          'CLAVE_OPTIONAL': 'Optional',
          'UPDATE': 'Update'
        },
        'MODAL': {
          'DELETE_MESSAGE': 'The information can no longer be recovered',
          'DELETE_OK': 'Delete',
          'DELETE_TITLE': 'Delete theme files',
          'DELETE_CANCEL': 'Cancel'
        },
        'TABS': {
          'THEMES': 'Themes',
          'SETTINGS': 'Settings'
        },
        'CHOOSE_FILE': 'Choose choose the zip file',
        'OR_DRAG_HERE': 'or drag it here',
        'SENDING_FILE': 'Sending file'
      }
    },
    'TOOLBAR': {
      'USER': {
        'ACCOUNT': 'My Profile',
        'INBOX': 'Inbox',
        'LOGOUT': 'Logout'
      },
      'NOTIFICATIONS': {
        'TITLE': 'Notifications',
        'MARK_ALL': 'Mark all as read',
        'MARK_READ': 'Mark as read',
        'MARK_UNREAD': 'Mark as unread',
        'REMOVE': 'Remove',
        'REMOVE_MESSAGE': 'Are you sure you want to delete this notification?',
        'REMOVE_YES': 'Yes',
        'REMOVE_NO': 'No'
      }
    },
    'MESSAGES': {
      'TOOLBAR': {
        'MENU': {
          'PROFILE': 'Profile',
          'LOGOUT': 'Logout'
        },
        'SEARCH': {
          'PLACEHOLDER': 'Search for a message or client'
        }
      },
      'CHATS': {
        'TITLE': 'Chats',
        'ITEM': {
          'FILE': 'File'
        },
        'CHAT_CENTER': 'Message Center',
        'CHOOSE_A_CHAT': 'Select a contact to open a chat!',
        'CONTACT_INFO': 'Contact Info',
        'TYPE_A_MESSAGE': 'Type your message',
        'DISABLE_BOT': 'Disable Chatbot',
        'ENABLE_BOT': 'Enable Chatbot',
        'CHOOSE_FILE': 'Choose a file',
        'OR_DRAG_HERE': 'or drag it here',
        'SENDING_FILE': 'Sending file',
        'DISABLED_CHATBOT': 'Disabled chatbot for the client {{client}}',
        'ENABLED_CHATBOT': 'Enabled chatbot for the client {{client}}',
        'SEND_FILE': 'Send file',
        'REGISTER_CLIENT': 'Register client',
        'OPEN_GALLERY': 'Open files gallery preview',
        'ERRORS': {
          'INVALID_FORMAT': 'Invalid format file {{type}}',
          'INVALID_SIZE': 'File ({{size1}}) exeed the max limit size ({{size2}})'
        }
      }
    },
    'FORMS': {
      'TITLE': 'Forms',
      'SUBTITLE': 'Create forms to be able to send to your customers',
      'ADD_NEW': 'Add new form',
      'TABLE': {
        'NAME': 'Name',
        'FIELDS': 'Fields',
        'ACTIVE': 'Active',
        'BANNED': 'Banned',
        'BAN': 'Ban',
        'ACTIVATE': 'Activate',
        'EDIT': 'Edit',
        'VIEW': 'View',
        'REMOVE': 'Remove'
      },
      'TABS': {
        'BASIC INFO': 'Details',
        'FIELDS': 'Build form'
      },
      'FORM': {
        'NAME': 'Name',
        'ACTIVE': {
          'ACTIVED': 'Active',
          'DEACTIVED': 'Inactive'
        },
        'ADD_STEP': 'Add a step'
      }
    }
  }
};
