import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, Subject } from "rxjs";
import { filter } from 'rxjs/operators';
import { HttpClient, HttpParams } from "@angular/common/http";
import { UserFile, Response, User } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';

@Injectable()
export class FilesService {
  subject = new Subject();
  private currentFile: UserFile;
  private _onCurrentFileChanged: BehaviorSubject<UserFile>;
  private _onFileSaved: BehaviorSubject<UserFile>;
  private _onFileDeleted: BehaviorSubject<UserFile>;

  constructor(private http: HttpClient) {}

  get(user: User, file: UserFile): Observable<Response<UserFile>> {
    return this.http.get<Response<UserFile>>(new ServerApiPipe().transform(`users/${user.id}/archivo/${file.hash_name}`));
  }

  delete(user: User, file: UserFile): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`users/${user.id}/archivo/${file.hash_name}`));
  }

  update(user: User, file: UserFile): Observable<Response<UserFile>> {
    return this.http.put<Response<UserFile>>(new ServerApiPipe().transform(`users/${user.id}/archivo/${file.hash_name}`), file);
  }

  setCurrentFile(file: UserFile): void {
    this.currentFile = file;
    if (this._onCurrentFileChanged) {
      this._onCurrentFileChanged.next(file);
    } else {
      this._onCurrentFileChanged = new BehaviorSubject(file);
    }
  }

  getCurrentFile(): UserFile | null {
    return this.currentFile;
  }

  onCurrentFileChanged(): Observable<UserFile|null> {
    if (!this._onCurrentFileChanged) {
      this._onCurrentFileChanged = new BehaviorSubject(null);
    } 
    return this._onCurrentFileChanged?.asObservable();
  }

  onFileSaved(): Observable<UserFile|null> {
    if (!this._onFileSaved) {
      this._onFileSaved = new BehaviorSubject(null);
    } 
    return this._onFileSaved?.asObservable().pipe(filter(v => !!v));
  }

  doFileSave(file) {
    this._onFileSaved?.next(file);
  }

  onFileDeleted(): Observable<UserFile|null> {
    if (!this._onFileDeleted) {
      this._onFileDeleted = new BehaviorSubject(null);
    } 
    return this._onFileDeleted?.asObservable().pipe(filter(v => !!v));
  }

  doFileDelete(file) {
    this._onFileDeleted?.next(file);
  }
}
