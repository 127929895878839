<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
  <div class="logo">
    <img class="logo-icon" src="{{ '/storage/company/company_icon.png' | server_file }}">
    <span class="logo-text secondary-text">{{ COMPANY_NAME || 'FUSE' }}</span>
  </div>

  <div class="buttons">
    <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
      <mat-icon class="secondary-text">menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
      <mat-icon class="secondary-text">arrow_back</mat-icon>
    </button>
  </div>
</div>

<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
  <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground" [class.has-sucursales]="User && User.role && User.role.is_sucursal">
    <div class="h3 username">{{ User.title }} {{ User.full_name }} </div>
    <div class="sucursales" #sucursales *ngIf="User && User.role && User.role.is_sucursal">
      <ng-container *ngIf="User.sucursales_rel && User.sucursales_rel.length > 1">
        <div class="selected_sucursal" (click)="showSucursales(sucursales, sucursales_list)">{{ selected_sucursal }}</div>
        <div class="sucursales_list" #sucursales_list>
          <mat-action-list>
            <button mat-list-item (click)="chooseSucursal(sucursal.sucursal , sucursales, sucursales_list)" *ngFor="let sucursal of User.sucursales_rel" >
              {{ sucursal.sucursal.name }}
            </button>
          </mat-action-list>
        </div>
      </ng-container>
      <ng-container *ngIf="User.sucursales_rel && User.sucursales_rel.length == 1">
        <div class="selected_sucursal alone">{{ selected_sucursal }}</div>
      </ng-container>
    </div>
    <div class="h5 email hint-text mt-8">{{ User.email }}</div>
    <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
      <img class="avatar" src="{{ User.avatar }}" *ngIf="User.avatar">
    </div>
  </div>

  <div class="navbar-content">
    <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
  </div>
  <ngx-spinner name="profile" [fullScreen]="false" type="ball-clip-rotate-multiple" size="small"></ngx-spinner>
</div>
