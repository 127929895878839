
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Role, Permiso, Response, Active, PermisoType } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';



@Injectable({ providedIn: 'root' })
export class PermissionsService {
  constructor(private http: HttpClient) {}

  get(id: Number): Observable<Response<Permiso>> {
    return this.http.get<Response<Permiso>>(new ServerApiPipe().transform(`permisos/${id}`));
  }

  all(body: any = {}): Observable<Response<PermisoType[]>> {
    let params = new HttpParams().set("s", body.s || '');

    return this.http.get<Response<PermisoType[]>>(new ServerApiPipe().transform(`permisos`), { params });
  }

  delete(permiso: Permiso): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`permisos/${permiso.id}`));
  }

  update(permiso: Permiso): Observable<Response<Permiso>> {
    return this.http.put<Response<Permiso>>(new ServerApiPipe().transform(`permisos/${permiso.id}`), permiso);
  }

  save(permiso: Permiso): Observable<Response<Permiso>> {
    return this.http.post<Response<Permiso>>(new ServerApiPipe().transform(`permisos`), permiso);
  }
}
