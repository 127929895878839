import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, Subject } from "rxjs";
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders, HttpEvent, HttpEventType } from "@angular/common/http";
import { Appointment, Response, AppointmentStatus } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';
import * as moment from 'moment';

@Injectable()
export class AppointmentsService {
  subject = new Subject();
  private currentAppointment: Appointment;
  private _onCurrentAppointmentChanged: BehaviorSubject<Appointment>;
  private _onAppointmentSaved: BehaviorSubject<Appointment>;

  constructor(private http: HttpClient) {}

  get(id: Number): Observable<Response<Appointment>> {
    return this.http.get<Response<Appointment>>(new ServerApiPipe().transform(`appointments/${id}`));
  }

  all(body: any = {}): Observable<Response<Appointment[]>> {
    let params = new HttpParams();
    
    Object.keys(body).forEach(p => {
      params = params.set(p, body[p] || '');
    });

    return this.http.get<Response<Appointment[]>>(new ServerApiPipe().transform(`appointments`), { params });
  }

  delete(appointment: Appointment): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`appointments/${appointment.id}`));
  }

  update(appointment: Appointment, postFiles: any[] = null): Observable<Response<Appointment>> {
    const formData: FormData = new FormData();
    if (postFiles !== null) {
      postFiles.forEach(f => {
        formData.append('postedFiles[]', f, f.name);
      });
    }

    if (appointment) {
      Object.keys(appointment).forEach((p) => {
        if (['start_date', 'end_date'].includes(p)) {
          formData.append(p, moment(appointment[p]).format('YYYY-MM-DD HH:mm:ss'));
        } else {
          formData.append(p, appointment[p]);
        }
      });
    }

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    let observable_ = this.http.post<Response<Appointment>>(new ServerApiPipe().transform(`appointments/${appointment.id}/update`), formData, { 
      headers, reportProgress: true, observe: 'events'
    }).subscribe((response: any) => {
      if (response.type === HttpEventType.Response) {
        this.subject.next(response);
        this._onAppointmentSaved.next(response.data);
      }
    });

    return this.subject;
  }

  save(appointment: Appointment, postFiles: any[] = null): Observable<Response<Appointment>> {
    const formData: FormData = new FormData();
    if (postFiles !== null) {
      postFiles.forEach(f => {
        formData.append('postedFiles[]', f, f.name);
      });
    }

    if (appointment) {
      Object.keys(appointment).forEach((p) => {
        if (['start_date', 'end_date'].includes(p)) {
          formData.append(p, moment(appointment[p]).format('YYYY-MM-DD HH:mm:ss'));
        } else {
          formData.append(p, appointment[p]);
        }
      });
    }

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    let observable_ = this.http.post<Response<Appointment>>(new ServerApiPipe().transform(`appointments`), formData, { 
      headers, reportProgress: true, observe: 'events'
    }).subscribe((response: any) => {
      if (response.type === HttpEventType.Response) {
        this.subject.next(response);
        this._onAppointmentSaved.next(response.data);
      }
    });

    return this.subject;
  }

  list(): Observable<Response<Appointment[]>> {
    return this.http.get<Response<Appointment[]>>(new ServerApiPipe().transform(`appointments/list`));
  }

  status(): Observable<Response<AppointmentStatus[]>> {
    return this.http.get<Response<AppointmentStatus[]>>(new ServerApiPipe().transform(`appointments/status`));
  }

  setCurrentAppointment(appointment: Appointment): void {
    if (appointment) {
      this.get(appointment.id).subscribe((response: Response<Appointment>) => {
        this.currentAppointment = response.data;
        if (this._onCurrentAppointmentChanged) {
          this._onCurrentAppointmentChanged.next(response.data);
        } else {
          this._onCurrentAppointmentChanged = new BehaviorSubject(response.data);
        }
      }, (error) => {
        if (this._onCurrentAppointmentChanged) {
          this._onCurrentAppointmentChanged.next(null);
        } else {
          this._onCurrentAppointmentChanged = new BehaviorSubject(null);
        }
      });
    } else {
      if (this._onCurrentAppointmentChanged) {
        this._onCurrentAppointmentChanged.next(null);
      } else {
        this._onCurrentAppointmentChanged = new BehaviorSubject(null);
      }
    }
  }

  getCurrentAppointment(): Appointment | null {
    return this.currentAppointment;
  }

  onCurrentAppointmentChanged(): Observable<Appointment|null> {
    if (!this._onCurrentAppointmentChanged) {
      this._onCurrentAppointmentChanged = new BehaviorSubject(null);
    } 
    return this._onCurrentAppointmentChanged?.asObservable();
  }

  onAppointmentSaved(): Observable<Appointment|null> {
    if (!this._onAppointmentSaved) {
      this._onAppointmentSaved = new BehaviorSubject(null);
    } 
    return this._onAppointmentSaved?.asObservable();
  }
}
