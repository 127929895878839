import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, Subject } from "rxjs";
import { filter } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Form, Response, User } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';

@Injectable()
export class FormsService {

  constructor(private http: HttpClient) {}

  all(body: any = false): Observable<Response<Form[]>> {
    return this.http.get<Response<Form[]>>(new ServerApiPipe().transform(`forms`));
  }

  get(form: Form): Observable<Response<Form>> {
    return this.http.get<Response<Form>>(new ServerApiPipe().transform(`forms/${form.id}`));
  }

  save(form: Form, file: File): Observable<Response<Form>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    Object.keys(form).forEach((k) => {
      if (!Array.isArray(form[k]) && form[k] !== '' && form[k] !== null) {
        formData.append(k, form[k]);
      } else {

      }
    });

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<Form>>(new ServerApiPipe().transform(`forms`), formData, { headers });
  }

  delete(form: Form): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`forms/${form.id}`));
  }

  update(form: Form, file: File = null): Observable<Response<Form>> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    
    Object.keys(form).forEach((k) => {
      if (!Array.isArray(form[k]) && form[k] !== '' && form[k] !== null) {
        formData.append(k, form[k]);
      } else {

      }
    });

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<Form>>(new ServerApiPipe().transform(`forms/${form.id}?_method=PUT`), formData, { headers });
  }
}
