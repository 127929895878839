import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseMarkup',
  pure: false
})
export class ParseMarkupPipe implements PipeTransform {
  private linkExp = /\*([A-Za-zÀ-ÿ0-9._%+\-\s\(\)\_\~\<\>\=\"]*)\*/i;
  private linkExp2 = /\_([A-Za-zÀ-ÿ0-9._%+\-\s\(\)\*\~\<\>\=\"]*)\_/i;
  private linkExp3 = /\~([A-Za-zÀ-ÿ0-9._%+\-\s\(\)\_\*\<\>\=\"]*)\~/i;
  
  transform(value: string): any {
    if (typeof value !== 'string') { return value; }
    if (value === null || value === '') { return value; }
    let rawText = value;
    let html = [];
    let match;
    let i;

    // eslint-disable-next-line no-cond-assign
    while ((match = rawText.match(this.linkExp)) && match[1]) {
      i = match.index;
      if (rawText.substr(0, i)) {
        html.push(rawText.substr(0, i));
      }
      html.push('<b>');
      html.push(match[1]);
      html.push('</b>');
      rawText = rawText.substring(i + match[0].length);
    }
    if (rawText) {
      html.push(rawText);
    }
    rawText = html.join('');
    html = [];
    while ((match = rawText.match(this.linkExp2)) && match[1]) {
      i = match.index;
      if (rawText.substr(0, i)) {
        html.push(rawText.substr(0, i));
      }
      html.push('<span class="text-italic">');
      html.push(match[1]);
      html.push('</span>');
      rawText = rawText.substring(i + match[0].length);
    }
    if (rawText) {
      html.push(rawText);
    }
    rawText = html.join('');
    html = [];
    while ((match = rawText.match(this.linkExp3)) && match[1]) {
      i = match.index;
      if (rawText.substr(0, i)) {
        html.push(rawText.substr(0, i));
      }
      html.push('<span class="text-strike">');
      html.push(match[1]);
      html.push('</span>');
      rawText = rawText.substring(i + match[0].length);
    }
    if (rawText) {
      html.push(rawText);
    }
    return html.join('');
  };
}