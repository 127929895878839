export const locale = {
  lang: 'tr',
  moment: 'tr',
  data: {
    'QUICKPANEL': {
      'TODAY': 'bugün'
    },
    'ERRORS': {
      'FORBIDDEN': 'Yasak',
      'ROLE_CLIENTS_NOT_FOUND': 'İstemci rolü etkinleştirilmedi',
      'UNAUTHORIZED': 'Geçersiz kimlik bilgileri nedeniyle erişim engellendi',
      'NOT_FOUND_USER': 'Kullanıcı bulunamadı',
      'NOT_FOUND': 'erişilemeyen sunucu',
      'NOT_FOUND_PRODUCT': 'Ürün bulunamadı',
      'NOT_FOUND_ROLE': 'Rol bulunamadı',
      'NOT_FOUND_OFFICE': 'ofis bulunamadı',
      'NOT_FOUND_CATEGORY': 'Kategori bulunamadı',
      'NOT_FOUND_OFFICE_PRODUCT': 'Ürün bulunamadı',
      'NOT_SHORTCUTS': 'Henüz kısayol yok!',
      'SERVER ERROR':  'erişilemeyen sunucu',
      'PRICE_LIST_USED': 'tarafından kullanılan fiyat listesi {{ office }}',
      'WRONG_UPDATE_FILE': 'Dosya yükleme hatası; Boş bir alan: ad, kategori, uygulama türü, fiyat tabanı veya tür',
      'USER_INACTIVE': 'You do not have permission to log in. please activate your account',
      'NO_FOUND_CONEKTA_USER': 'This user is not registered in conekta',
      'NO_FOUND_CARD_USER': 'This card is not assigned to the user',
      'WRONG_DATE_RANGE': 'The date range is wrong',
      'SKU': {
        'NOT_AVAILABLE': 'Used SKU',
      },
      'PRICE_LIST_ID': {
        'MIN_LIMIT': 'lütfen bir fiyat listesi seçin'
      },
      'CODE': {
        'NOT_AVAILABLE': 'kod zaten kullanılıyor'
      },
      'RESPONSE_MESSAGE': {
        'MAX_LIMIT': 'Max character limit'
      },
      'BDAY': {
        'INVALID_DATE': 'Invalid birthday date',
        'MAX_DATE_LIMIT': 'Birthday outside range'
      },
      'EMAIL': {
        'NOT_AVAILABLE': 'Already used email'
      },
      'ERROR_SENDING': 'Unable to send email',
      'PRODUCT_APPLICATION_TYPE_ID': {
        'WRONG_VALUE': 'No application type selected'
      }
    },
    'NAV': {
      'SHORTCUTS': {
        'PLACEHOLDER': 'bir sayfa ara',
      },
      'BACK_HOME': 'Başlamak için geri dön',
      'CLIENT': {
        'TITLE': 'Müşteriler'
      },
      'OFFICES': {
        'TITLE': 'Şube'
      },
      'CATEGORIES': {
        'TITLE': 'Kategoriler'
      },
      'PRODUCTS': {
        'TITLE': 'Ürün:% s'
      },
      'SERVICES': {
        'TITLE': 'Hizmetler'
      },
      'PRODUCTS_OFFICE': {
        'TITLE': 'Ürün:% s'
      },
      'MANAGEMENT'  : {
        'TITLE': 'Yönetim',
        'USERS': 'Kullanıcılar',
        'ROLES': 'Roller'
      },
      'BANNERS_OFFICE': {
        'TITLE': 'afişler'
      },
      'APPLICATIONS': 'Programlar',
      'DASHBOARDS'  : 'Kontrol Paneli',
      'CALENDAR'    : { 
        'TITLE': 'Takvim'
      },
      'APPOINTMENT': {
        'TITLE': 'Appointments'
      },
      'ECOMMERCE'   : 'E-Ticaret',
      'ACADEMY'     : 'Akademi',
      'MAIL'        : {
        'TITLE': 'Posta',
        'BADGE': '15'
      },
      'MAIL_NGRX'        : {
        'TITLE': 'Posta Ngrx',
        'BADGE': '13'
      },
      'CHAT'        : 'Sohbet',
      'FILE_MANAGER': 'Dosya Yöneticisi',
      'CONTACTS'    : 'Kişiler',
      'TODO'        : 'Yapılacaklar',
      'SCRUMBOARD'  : 'Proje',
      'PRICE_LISTS': {
        'TITLE': 'Fiyat listeleri'
      },
      'ORDERS': {
        'TITLE': 'Emirler'
      },
      'COUPONS': {
        'TITLE': 'Coupons'
      },
      'CATALOGS': {
        'TITLE': 'Catalogs'
      },
      'LOGOUT': {
        'CLOSE_SESSION': 'User logout',
        'TITLE': 'Logout'
      },
      'SETTINGS': {
        'TITLE': 'Settings',
        'GENERAL': 'General settings',
        'CHATBOT': 'Chatbot settings',
        'APIS': 'API\'s',
        'MAIL': 'Mail settings',
        'THEME': 'Appearance'
      },
      'MESSAGES': 'Messages',
      'FORMS': {
        'TITLE': 'Forms'
      }
    },
    'MANAGEMENT': {
      'ROLES': {
        'TITLE': 'Rol',
        'SEARCH': 'Bir rol arayın',
        'ADD_NEW': 'YENİ ROL EKLE',
        'SUBTITLE': 'Rol Ayrıntısı',
        'ERROR': {
          'REQUIRED': 'gereklidir'
        },
        'FORM': {
          'NAME': 'İsim Soyisim',
          'DESCRIPTION': 'Açıklama',
          'AVAILABLE ONLINE': 'Çevrimiçi Mevcut',
          'IS_CLIENT': 'müşteriler için belirlenmiş',
          'IS_SUCURSAL': 'Ofis şubesi için belirlenmiş',
          'IS_PROVIDER': 'Sağlayıcılar için belirlenmiş'
        },
        'TABS': {
          'PERMISSIONS': 'izinler',
          'BASIC INFO': 'Temel bilgi'
        },
        'TABLE': {
          'NAME': 'İsim Soyisim',
          'AVAILABLE ONLINE': 'Çevrimiçi Mevcut',
          'AVAILABLE': 'Mevcut',
          'UNAVAILABLE': 'Kullanım dışı',
          'DESCRIPTION': 'Açıklama',
          'ACCESS': 'Giriş',
          'BAN': 'Yasakla',
          'ACTIVATE': 'Etkinleştir',
          'EDIT': 'Düzenle',
          'REMOVE': 'Kaldırmak',
          'VIEW': 'Görünüm',
          'DELETE_TITLE': 'Silme işlemini onayla',
          'DELETE_MESSAGE': 'Seçilen rolü silmek istediğinizden emin misiniz?\n<br><strong><i>{{name}}</i></strong>',
          'DELETE_OK': 'Evet',
          'DELETE_CANCEL': 'Hayır'
        },
        'PERMISSIONS': {
          'USERS_TITLE': 'Kullanıcılar Modülü',
          'PERMISOS_TITLE': 'İzin Modülü',
          'ROLES_TITLE': 'Roller Modülü',
          'CLIENTS_TITLE': 'Müşteriler Modülü',
          'USER_LIST': 'Kullanıcı listesi görünümü',
          'USER_CREATE': 'Kullanıcı erişim oluştur',
          'USER_EDIT': 'Kullanıcı düzenleme erişimi',
          'USER_DETAIL': 'Kullanıcı ayrıntılarını görüntüleyin',
          'USER_DELETE': 'Kullanıcı silme erişimi',
          'PERMISOS_LIST': 'İzin listesi görünümü',
          'PERMISO_CREATE': 'Yeni izinleri güncelleyin veya ekleyin',
          'PERMISO_DETAIL': 'Seçilen izin ayrıntılarını görüntüleyin',
          'PERMISO_EDIT': 'İzinleri güncelleyin',
          'PERMISO_DELETE': 'Seçili izni sil',
          'ROLES_LIST': 'Rol listesini görüntüleyin',
          'ROLE_CREATE': 'Yeni roller oluşturun',
          'ROLE_VIEW': 'Seçili rol için ayrıntıları görüntüleyin',
          'ROLE_EDIT': 'Seçili rolü güncelleyin',
          'ROLE_DELETE': 'Seçili rolü sil',
          'CLIENT_LIST': 'Müşteri listesini görüntüleyin',
          'CLIENT_CREATE': 'Yeni müşteri oluştur',
          'CLIENT_EDIT': 'Düzenleme istemcileri için erişim',
          'CLIENT_DETAIL': 'Müşterinin ayrıntılarını görüntülemek için erişim',
          'CLIENT_DELETE': 'Silme istemcileri için erişim',
          'CATEGORIES_TITLE': 'Kategoriler',
          'CATEGORIES_LIST': 'Kategori listesini görüntüle',
          'CATEGORIES_CREATE': 'Yeni kategoriler oluşturun',
          'CATEGORIES_EDIT': 'Kategori bilgilerini düzenle',
          'CATEGORIES_DETAIL': 'Seçilen kategori için ayrıntıları görüntüle',
          'CATEGORIES_DELETE': 'Seçili kategoriyi sil',
          'OFFICES_TITLE': 'Şube',
          'OFFICE_LIST': 'şube listesine bakın',
          'OFFICE_CREATE': 'yeni şube oluştur',
          'OFFICE_EDIT': 'şubeyi düzenle',
          'OFFICE_DETAIL': 'şube detaylarını görüntüle',
          'OFFICE_DELETE': 'Şubeyi sil',
          'PRODUCTS_TITLE': 'Ürün:% s',
          'PRODUCTS_LIST': 'Ürün listesini görüntüle',
          'PRODUCTS_CREATE': 'Yeni ürünler oluşturun',
          'PRODUCTS_EDIT': 'Seçili ürünü düzenle',
          'PRODUCTS_DETAIL': 'Ürün için ayrıntıları görüntüleyin',
          'PRODUCTS_DELETE': 'Seçili ürünü sil',
          'PRODUCTS_OFFICE_TITLE': 'ofis şube Ürünler',
          'PRODUCTS_OFFICE_CREATE': 'Yeni ürünler oluşturun',
          'PRODUCTS_OFFICE_LIST': 'Ürün listesini görüntüle',
          'PRODUCTS_OFFICE_EDIT': 'Seçili ürünü düzenle',
          'PRODUCTS_OFFICE_DETAIL': 'Ürün için ayrıntıları görüntüleyin',
          'PRODUCTS_OFFICE_DELETE': 'Seçili ürünü sil',
          'BANNERS_OFFICE_TITLE': 'Afiş Modülü',
          'BANNERS_OFFICE_LIST': 'Banner listesini görüntüle',
          'BANNERS_OFFICE_CREATE': 'Yeni afişler oluşturun',
          'BANNERS_OFFICE_EDIT': 'Seçili banner\'ı düzenle',
          'BANNERS_OFFICE_DETAIL': 'Seçili banner\'ın ayrıntılarını görüntüleyin',
          'BANNERS_OFFICE_DELETE': 'Seçili başlığı sil',
          'SUBCATEGORIES_TITLE': 'Modül Alt Kategorileri',
          'SUBCATEGORIES_VIEW': 'Kategori modülü içindeki alt kategori sekmesini görüntüleyebilir',
          'SUBCATEGORIES_EDIT': 'Seçili alt kategoriyi düzenleyebilir',
          'SUBCATEGORIES_CREATE': 'yeni alt kategoriler oluşturabilir',
          'SUBCATEGORIES_DELETE': 'seçili alt kategoriyi silebilir',
          'PRICE_LIST_INDEX': 'Fiyat listelerini görüntüleyebilir',
          'PRICE_LIST_TITLE': 'Fiyat Listesi Modülü',
          'PRICE_LIST_VIEW': 'Fiyat listesinin ayrıntılarını görüntüleyebilir',
          'PRICE_LIST_EDIT': 'Seçilen fiyat listesini düzenleyebilir',
          'PRICE_LIST_CREATE': 'Yeni fiyat listeleri oluşturabilir',
          'PRICE_LIST_DELETE': 'Seçilen fiyat listesini silebilir',
          'PRICE_LIST_PRODUCTS_TITLE': 'Fiyat listesi için ürünler',
          'PRICE_LIST_PRODUCTS_VIEW': 'Ürün listesini görüntüleyebilir',
          'PRICE_LIST_PRODUCTS_EDIT': 'Seçilen ürünü düzenleyebilir',
          'PRICE_LIST_PRODUCTS_CREATE': 'Fiyat listesine yeni ürünler ekleyebilir',
          'PRICE_LIST_PRODUCTS_DELETE': 'Seçilen ürünü fiyat listesinden silebilir',
          'SERVICES_TITLE': 'Hizmetler',
          'SERVICES_INDEX': 'Hizmetler listesini görüntüle',
          'SERVICES_VIEW': 'Yeni hizmetler oluştur',
          'SERVICES_EDIT': 'Seçili hizmetleri düzenle',
          'SERVICES_CREATE': 'Hizmet ayrıntılarını görüntüleyin',
          'SERVICES_DELETE': 'Seçili hizmeti sil',
          'ORDERS_TITLE': 'Emir',
          'ORDERS_INDEX': 'Sipariş listesini görüntüleyebilir',
          'ORDERS_VIEW': 'View order details',
          'ORDERS_EDIT': 'Siparişi düzenleyebilir',
          'ORDERS_CREATE': 'Yeni siparişler oluşturabilir',
          'ORDERS_DELETE': 'Siparişleri silebilir',
          'CALENDAR_TITLE': 'Takvim Modülü',
          'CALENDAR_INDEX': 'Randevu listesini görüntüleyebilir',
          'CALENDAR_VIEW': 'Takvimi görüntüleyebilir ',
          'CALENDAR_EDIT': 'Bir randevuyu düzenleyebilir',
          'CALENDAR_CREATE': 'Yeni randevular oluşturabilir',
          'CALENDAR_DELETE': 'Randevuları silebilir',
          'COUPONS_TITLE': 'Coupons module',
          'COUPONS_INDEX': 'View coupons list',
          'COUPONS_VIEW': 'Can view details of coupon',
          'COUPONS_EDIT': 'Can edit details of coupon',
          'COUPONS_CREATE': 'Can create new coupons',
          'COUPONS_DELETE': 'Can delete coupons',
          'CHATBOT_TITLE': 'Chatbot settings',
          'CHATBOT_VIEW': 'View Chatbot settings options',
          'CHATBOT_CREATE': 'Create new chatbot option',
          'CHATBOT_EDIT': 'Edit Chatbot settings',
          'CHATBOT_DELETE': 'Delete Chatbot settings',
          'SETTINGS_TITLE': 'settings module',
          'SETTINGS_VIEW': 'Access to see settings page',
          'SETTINGS_EDIT': 'Access to edit settings options',
          'SETTINGS_API_TITLE': 'Third party API\'s',
          'SETTINGS_API_VIEW': 'View API\'s Information',
          'SETTINGS_API_EDIT': 'Edit API\'s',
          'MESSAGES_TITLE': 'Chat online',
          'MESSAGES_VIEW': 'Can view chat online',
          'SETTINGS_GENERAL_TITLE': 'General settings module',
          'SETTINGS_GENERAL_EDIT': 'Can edit general settings',
          'SETTINGS_GENERAL_VIEW': 'Can view menu option general settings',
          'SETTINGS_MAIL_TITLE': 'Mail server settings',
          'SETTINGS_MAIL_VIEW': 'Can view menu option mail settings',
          'SETTINGS_MAIL_EDIT': 'Can edit mail server settings',
          'SETTINGS_THEME_TITLE': 'Module for configuration of themes and appearance',
          'SETTINGS_THEME_INDEX': 'See the appearance option in the configuration menu',
          'SETTINGS_THEME_CREATE': 'Possibility to add new themes',
          'SETTINGS_THEME_DELETE': 'Possibility of deleting themes from the list',
          'SETTINGS_THEME_PUBLISH': 'Possibility to publish the selected themes',
          'FORMS_TITLE': 'Forms Module',
          'FORMS_INDEX': 'Show forms option in menu',
          'FORMS_VIEW': 'Can view details form',
          'FORMS_CREATE': 'Create new forms',
          'FORMS_UPDATE': 'Can Update forms',
          'FORMS_DELETE': 'Can delete forms'
        }
      },
      'USERS': {
        'TITLE': 'Kullanıcılar',
        'SUBTITLE': 'Kullanıcı Ayrıntısı',
        'SEARCH': 'Bir kullanıcı arayın',
        'ADD_NEW': 'YENİ KULLANICI EKLE',
        'ERROR': {
          'REQUIRED': 'gereklidir',
          'PASSWORD_DONT_MATCH': 'Parola eşleşmedi',
          'EMPTY_SUCURSAL': 'Lütfen bir ofis seçin'
        },
        'FORM': {
          'TITLE': 'Başlık',
          'NAME': 'İsim Soyisim',
          'LAST NAME': 'Soyadı',
          'PHONE NUMBER': 'Telefon numarası',
          'OFFICE NUMBER': 'Ofis telefonu',
          'EMAIL': 'E-posta adresi',
          'JOB TITLE': 'İş ismi',
          'BIRTHDAY': 'Doğum günü',
          'GENDER': 'Cinsiyet',
          'MALE': 'Erkek',
          'FEMALE': 'Kadın',
          'OTHER': 'Diğer',
          'NOT SURE': 'Emin değil',
          'RATHER NOT SAY': 'Söylememeyi tercih etmek',
          'RFC': 'RFC',
          'NO LICENCIA': 'Lisans yok',
          'CEDULA PROFESIONAL': 'Profesyonel ehliyet',
          'MATRICULA PROFESIONAL': 'Profesyonel kayıt',
          'ESPECIALIDAD': 'Uzmanlık',
          'BIOGRAPHY': 'Biyografi',
          'USERNAME': 'Kullanıcı adı',
          'PASSWORD': 'Parola',
          'CONFIRM_PASSWORD': 'Şifreyi Onayla',
          'ROLE': 'Rol',
          'LAST LOGIN': 'Son giriş',
          'SUCURSALES': 'Şube',
          'QUOTE': 'Quote',
          'SKILLS': 'Skills',
          'SLOT_DURATION': 'Appointment default duration',
          'NEXT_DAY': 'Show availability after (n) days',
          'MAX_DAYS': 'Show availability this number of days',
          'EXTENDED_APPOINTMENT': {
            'ACTIVED': 'Accept appointment until the last minute',
            'DEACTIVED': 'Accept appointment before the last hour'
          },
          'MULTIPLE_SERVICES': {
            'ACTIVED': 'Enable multiple service selection',
            'DEACTIVED': 'Disable multiple service selection'
          },
          'WORKING_TIME': 'Working time',
          'OPEN_TIME': 'Start working',
          'CLOSE_TIME': 'End working'
        },
        'TABS': {
          'BASIC INFO': 'Temel bilgi',
          'ACCOUNT INFO': 'Hesap bilgisi',
          'LOCATIONS': 'Adresler',
          'MESSAGES': 'Messages',
          'ORDERS': 'Orders',
          'FILES': 'Files',
          'CARDS': 'Cards',
          'SERVICES': 'Services',
          'PROVIDER_INFO': 'Provider Info',
          'QUESTIONNAIRES': 'Questionnaires'
        },
        'TABLE': {
          'NAME': 'İsim Soyisim',
          'EMAIL': 'E-posta adresi',
          'PHONE': 'Telefon',
          'JOB TITLE': 'İş ismi',
          'ROLE': 'Rol',
          'ACTIVE': 'Aktif',
          'INACTIVE': 'Etkin değil',
          'BAN': 'Yasakla',
          'ACTIVATE': 'Etkinleştir',
          'BANNED': 'yasaklandı',
          'EDIT': 'Düzenle',
          'REMOVE': 'Kaldırmak',
          'VIEW': 'Görünüm',
          'DELETE_TITLE': 'Silme işlemini onayla',
          'DELETE_MESSAGE': 'Seçilen kullanıcıyı silmek istediğinizden emin misiniz?\n<br><strong><i>{{name}}</i></strong>',
          'DELETE_OK': 'Evet',
          'DELETE_CANCEL': 'Hayır'
        },
        'MODAL': {
          'CHOOSE_PICTURE': 'Profil Resmi Seçin',
          'CROP CIRCLE': 'Ekin çemberi',
          'CROP SQUARE': 'Kare kırpma',
          'ROTATE PLUS 90': '+90 ° döndür',
          'ROTATE MINUS 90': '-90 ° döndür',
          'FLIP VERTICAL': 'Dikey Çevir',
          'FLIP HORIZONTAL': 'Yatay çevirmek',
          'CANCEL': 'İptal etmek',
          'SAVE': 'Kayıt etmek',
          'DELETE': {
            'TITLE': 'Removing address',
            'TEXT': 'It will be permanently removed',
            'CONFIRM': 'Ok',
            'CANCEL': 'cancel',
            'SUCCESS': 'Address deleted'
          }
        },
        'ADDRESS': {
          'MODAL': {
            'TITLE': { 
              'NEW': 'yeni adres ekleyin',
              'EDIT': 'Adresi düzelt'
            },
            'NAME': 'Takma ad',
            'STREET': 'sokak',
            'NUMBER': 'Dış numara',
            'INT_NUMBER': 'İç numara',
            'SUBURB': 'Kenar mahalle',
            'TOWNSHIP': 'İlçe',
            'CITY': 'Kent',
            'STATE': 'Durum',
            'COUNTRY': 'Ülke',
            'DIRECTIONS': 'Talimatlar',
            'POSTAL_CODE': 'Posta kodu'
          }
        },
        'FILES': {
          'FILES': '{{number}} Files',
          'PDF_FILES': '{{number}} Documents',
          'IMAGES_FILES': '{{number}} Images',
          'VIDEO_FILES': '{{number}} Video',
          'AUDIO_FILES': '{{number}} Audio',
          'OTHER_FILES': '{{number}} Others',
          'UPLOADING': 'Uploading',
          'FILE_UPLOAD': 'Upload File',
          'TAGS': 'Tags',
          'ERRORS': {
            'WRONG_TYPE': 'Wrong file type',
            'WRONG_SIZE': 'Max size exceeded',
          },
          'TABLE': {
            'FILES': 'Files',
            'NAME': 'Name',
            'TYPE': 'Type',
            'SIZE': 'Size',
            'UPDATED_AT': 'Last Modified',
            'EDIT': 'Open file',
            'SHARE': 'Share',
            'DELETED': 'Delete file'
          },
          'FILE_DETAILS': {
            'DETAILS': 'Details',
            'NAME': 'Name',
            'TAGS': 'Tags',
            'SIZE': 'Size',
            'LAST_MODIFIED': 'Last modified',
            'VERSION': 'Version',
            'SHARE': 'Share',
            'LINK': 'Link',
            'SAVE': 'Save',
            'DELETE': 'Delete',
            'FOLDER': 'Folder',
            'COPIED_CONTENT': 'Copied content',
            'DELETE_TITLE': 'Confirm deletion',
            'DELETE_MESSAGE': 'Are you sure to delete the selected file?',
            'DELETE_OK': 'Yes',
            'DELETE_CANCEL': 'No',
            'SENDED_CONTENT': 'File sent',
            'WAITING_UPLOAD': 'Waiting upload',
            'UPLOAD_NEW_VERSION': 'Upload new version'
          }
        },
        'CARDS': {
          'NAME': 'Name',
          'TYPE': 'Type',
          'BRAND': 'Brand',
          'EXPIRATION': 'Expiration',
          'CARD': 'Card number',
          'CARD_TITLE': 'Card details',
          'FAV': 'Favorite',
          'ADD_NEW': 'Add new',
          'CVC': 'CVC',
          'SAVE': 'Save',
          'DELETE': 'Delete',
          'DELETE_TITLE': 'Confirm deletion',
          'DELETE_MESSAGE': 'Are you sure to delete the selected card?',
          'DELETE_OK': 'Yes',
          'DELETE_CANCEL': 'No',
          'ERROR': {
            'DATE': 'Wrong expiration date'
          }
        },
        'SERVICES': {
          'NAME': 'Name',
          'DESCRIPTION': 'Description',
          'PRICE': 'Price',
          'ACTIVE': 'Active',
          'ADD_NEW': 'Add new',
          'CARD_TITLE': 'Service Details',
          'SAVE': 'Save',
          'DELETE': 'Delete',
          'DURATION': 'Duration'
        },
        'QUESTIONNAIRES': {
          'TABLE': {
            'NAME': 'Name',
            'FIELDS': 'Fields',
            'STATUS': 'Status',
            'UPDATED_AT': 'Last date',
            'EDIT': 'Open questionnaire',
            'DELETED': 'Delete'
          }
        }
      }
    },
    'CLIENTS': {
      'TITLE': 'Müşteriler',
      'SUBTITLE': 'Kullanıcı Detayı',
      'SEARCH': 'Arama',
      'ADD_NEW': 'Yeni müşteri ekle',
      'ERROR': {
        'REQUIRED': 'gereklidir',
        'PASSWORD_DONT_MATCH': 'Parola eşleşmedi'
      },
      'TABLE': {
        'NAME': 'İsim Soyisim',
        'EMAIL': 'E-posta adresi',
        'PHONE': 'Telefon',
        'JOB TITLE': 'İş ismi',
        'ROLE': 'Rol',
        'ACTIVE': 'Aktif',
        'INACTIVE': 'Etkin değil',
        'BAN': 'Yasakla',
        'ACTIVATE': 'Etkinleştir',
        'BANNED': 'yasaklandı',
        'EDIT': 'Düzenle',
        'REMOVE': 'Kaldırmak',
        'VIEW': 'Görünüm'
      },
      'FORM': {
        'TITLE': 'Başlık',
        'NAME': 'İsim Soyisim',
        'LAST NAME': 'Soyadı',
        'PHONE NUMBER': 'Telefon numarası',
        'OFFICE NUMBER': 'Ofis telefonu',
        'EMAIL': 'E-posta adresi',
        'JOB TITLE': 'İş ismi',
        'BIRTHDAY': 'Doğum günü',
        'GENDER': 'Cinsiyet',
        'MALE': 'Erkek',
        'FEMALE': 'Kadın',
        'OTHER': 'Diğer',
        'NOT SURE': 'Emin değil',
        'RATHER NOT SAY': 'Söylememeyi tercih etmek',
        'RFC': 'RFC',
        'NO LICENCIA': 'Lisans yok',
        'CEDULA PROFESIONAL': 'Profesyonel ehliyet',
        'MATRICULA PROFESIONAL': 'Profesyonel kayıt',
        'ESPECIALIDAD': 'Uzmanlık',
        'BIOGRAPHY': 'Biyografi',
        'USERNAME': 'Kullanıcı adı',
        'PASSWORD': 'Parola',
        'CONFIRM_PASSWORD': 'Şifreyi Onayla',
        'ROLE': 'Rol',
        'LAST LOGIN': 'Son giriş'
      },
      'TABS': {
        'BASIC INFO': 'Temel bilgi',
        'ACCOUNT INFO': 'Hesap bilgisi',
        'LOCATIONS': 'Adresler'
      },
    },
    'OFFICES': {
      'ERROR': {
        'REQUIRED': 'gerekli alan',
        'INVALID': 'Geçersiz alan'
      },
      'TITLE': 'Şube',
      'SUBTITLE': 'Şube detay',
      'SEARCH': 'Arama',
      'ADD_NEW': 'Yeni ofis ekle',
      'TABLE': {
        'NAME': 'isim',
        'EMAIL': 'E-posta',
        'PHONE': 'Telefon',
        'ACTIVE': 'Aktif',
        'BANNED': 'yasaklandı',
        'ACTIVATE': 'etkinleştir',
        'BAN': 'yasakla',
        'EDIT': 'Düzenle',
        'REMOVE': 'Sil',
        'DIRECTIONS': 'Talimatlar',
        'VIEW': 'Görünüm',
        'DELETE_TITLE': 'Silme işlemini onayla',
        'DELETE_MESSAGE': 'Seçili kategoriyi silmek istediğinizden emin misiniz?<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Evet',
        'DELETE_CANCEL': 'Hayır'
      },
      'TABS': {
        'BASIC INFO': 'Ayrıntılar',
        'PRICE_LIST': 'Fiyat listesi'
      },
      'FORM': {
        'NAME': 'isim',
        'COMPANY': 'şirket',
        'PHONE': 'Telefon',
        'EMAIL': 'E-posta',
        'RFC': 'RFC',
        'CURP': 'CURP',
        'DIRECTIONS': 'Talimatlar',
        'LOCATION': 'yer',
        'RANGE': 'Aralık',
        'STREET': 'Sokak',
        'COLONY': 'Kasaba',
        'CITY': 'Şehir',
        'STATE': 'Devlet',
        'PRICE_LIST': 'Bu ofis için fiyat listesi',
        'STOCK': 'Stoklamak',
        'DURATION': 'randevu süresi',
        'QTY': 'randevu yerleri',
        'OPEN_TIME': 'Açılış zamanı',
        'CLOSE_TIME': 'Kapanış saati',
        'DISPATCHABLE': 'Sevk edilebilir',
        'IN_OFFICE': 'ofis şubesinde',
        'TAX': 'Tax (informational only)',
      }
    },
    'CATEGORIES': {
      'ERROR': {
        'REQUIRED': 'gereklidir'
      },
      'TITLE': 'Kategoriler',
      'SUBTITLE': 'Kategori Ayrıntıları',
      'SEARCH': 'Arama',
      'ADD_NEW': 'Yeni Kategori Ekle',
      'TABLE': {
        'NAME': 'isim',
        'COLOR': 'Renk',
        'ACTIVE': 'Aktif',
        'BANNED': 'yasaklandı',
        'ACTIVATE': 'etkinleştir',
        'BAN': 'yasakla',
        'EDIT': 'Düzenle',
        'REMOVE': 'Sil',
        'VIEW': 'Görünüm',
        'DELETE_TITLE': 'Silme işlemini onayla',
        'DELETE_MESSAGE': 'Seçilen dalı silmek konusunda emin misiniz? <br> <strong><i>{{name}}</i> </strong>',
        'DELETE_OK': 'Evet',
        'DELETE_CANCEL': 'Hayır'
      },
      'TABS': {
        'BASIC INFO': 'Ayrıntılar',
        'SUBCATEGORIES': 'alt kategoriler'
      },
      'FORM': {
        'NAME': 'isim',
        'COLOR': 'Renk',
        'DESCRIPTION': 'Açıklama',
        'ACTIVE': 'Aktif'
      }
    },
    'PRODUCTS' :{
      'ERROR': {
        'REQUIRED': 'gerekli alan',
        'INVALID': 'Geçersiz alan',
        'SEND_METHOD_ERROR': 'Lütfen birini seçin',
        'CANT_ZERO': 'Değer sıfırdan küçük veya sıfıra eşit olamaz'
      },
      'TITLE': 'Ürün:% s',
      'SUBTITLE': 'Ürün Detayları',
      'SEARCH': 'Arama',
      'ADD_NEW': 'Yeni ürün ekle',
      'TABLE': {
        'NAME': 'isim',
        'DESCRIPTION': 'Açıklama',
        'PRICE': 'Maliyet',
        'PRICE_VENTA': 'Taban fiyat',
        'DISPATCHABLE': 'modalite',
        'REQUIREMENTS': 'Gereksinimler',
        'CATEGORY': 'Kategori',
        'ACTIVE': 'Mevcut',
        'BANNED': 'yasaklandı',
        'ACTIVATE': 'etkinleştir',
        'BAN': 'yasakla',
        'EDIT': 'Düzenle',
        'REMOVE': 'Sil',
        'VIEW': 'Görünüm',
        'DELETE_TITLE': 'Silme işlemini onayla',
        'DELETE_MESSAGE': 'Seçilen ürünü silmek istediğinizden emin misiniz?<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Evet',
        'DELETE_CANCEL': 'Hayır',
        'EXPORT': 'Export',
        'IMPORT': 'Import'
      },
      'TABS': {
        'BASIC INFO': 'Ayrıntılar',
        'PRICES': 'Fiyat:% s',
        'INVENTORY': 'Envanter',
        'SAT': 'IRS'
      },
      'FORM': {
        'NAME': 'isim',
        'CATEGORY': 'Kategori',
        'PRICE': 'Maliyet',
        'DESCRIPTION': 'Açıklama',
        'ACTIVE': 'Mevcut',
        'DISPATCHABLE': 'Sevk edilebilir',
        'IN_OFFICE': 'ofis şubesinde',
        'REQUIREMENTS': 'Gereksinimler',
        'APPLICATION_TYPE': 'Uygulama türü',
        'PRICE_REAL': 'Maliyet',
        'PRICE_VENTA': 'Taban fiyat',
        'PRICE_PROMOCION': 'indirimli fiyat',
        'PRICE_RENTA': 'kiralama fiyatı',
        'SKU': 'SKU (benzersiz depolama kodu)',
        'BARCODE': 'Barkod',
        'OFFICE': {
          'PRICE_REAL': 'Ofis şubesi maliyeti',
          'PRICE_VENTA': 'Ofis şubesi için taban fiyat',
          'PRICE_PROMOCION': 'Ofis şubesi için indirimli fiyat',
          'PRICE_RENTA': 'Ofis şubesi için kira bedeli'
        },
        'SAT_CLAVE': 'IRS Key',
        'SAT_UNIDAD': 'IRS Unity',
        'SAT_CONCEPTO': 'IRS Concept',
        'SAT_CONCEPTO_UNIDAD': 'IRS Unity Concept'
      },
      'EXPORT': {
        'TEMPLATE': 'Şablon'
      },
      'IMPORT': {
        'TITLE': 'İçe aktarmak',
        'SUBTITLE': 'Ürün:% s',
        'SUCCESS_UPLOAD': 'Başarılı yükleme',
        'DOWNLOAD_TEMPLATE': 'Şablonu indir',
        'ERROR': {
          'EMPTY': 'boş olamaz',
          'DUPLICATED': 'yinelenen',
          'NEW_CATEGORY': 'Yanlış kategori',
          'NEW_APPLICATION_TYPE': 'Yanlış uygulama türü',
          'NO_DISPATCHABLE': 'Bir seçenek belirleyin'
        },
        'TABLE': {
          'NAME': 'İsim',
          'PRICES': 'Fiyat:% s',
          'CATEGORY': 'Kategori',
          'SHIPPING': 'Nakliye',
          'ACTIVE': 'Aktif',
          'BARCODE': 'Barkod',
          'SKU': 'Sku',
          'PRICE_REAL': 'Temel',
          'PRICE_VENTA': 'Satış',
          'PRICE_RENTA': 'Kiraya vermek',
          'PRICE_PROMOCION': 'promosyon',
          'APPLICATION_TYPE': 'Uygulama türü',
          'TAGS': 'Etiketler',
          'IN_OFFICE': 'Ofiste',
          'DISPATCHABLE': 'müşteriye gönder',
          'SAT_CLAVE': 'IRS Key',
          'SAT_UNIDAD': 'IRS Unity',
        },
        'SIDEBAR': {
          'TITLE': 'Yüklemeden önce düzenle',
          'FORM': {
            'NAME': 'isim',
            'CATEGORY': 'Kategori',
            'PRICE': 'Maliyet',
            'DESCRIPTION': 'Açıklama',
            'ACTIVE': 'Mevcut',
            'DISPATCHABLE': 'Sevk edilebilir',
            'IN_OFFICE': 'ofis şubesinde',
            'REQUIREMENTS': 'Gereksinimler',
            'APPLICATION_TYPE': 'Uygulama türü',
            'PRICE_REAL': 'Maliyet',
            'PRICE_VENTA': 'Taban fiyat',
            'PRICE_PROMOCION': 'indirimli fiyat',
            'PRICE_RENTA': 'kiralama fiyatı',
            'SKU': 'SKU (benzersiz depolama kodu)',
            'BARCODE': 'Barkod',
            'OFFICE': {
              'PRICE_REAL': 'Ofis şubesi maliyeti',
              'PRICE_VENTA': 'Ofis şubesi için taban fiyat',
              'PRICE_PROMOCION': 'Ofis şubesi için indirimli fiyat',
              'PRICE_RENTA': 'Ofis şubesi için kira bedeli'
            },
            'DETAILS': 'Detaylar',
            'SHORT_DESCRIPTION': 'Kısa Açıklama',
            'PRICES': 'Fiyat:% s',
            'PRICES_LIST': 'Fiyat listeleri',
            'UPDATE': 'Güncelleme',
            'CANCEL': 'İptal',
            'SAT_FIELDS': 'SAT',
            'SAT_CLAVE': 'IRS Key',
            'SAT_UNIDAD': 'IRS Unity',
            'SAT_CONCEPTO': 'IRS Concept',
            'SAT_CONCEPTO_UNIDAD': 'IRS Unity Concept'
          },
        }
      }
    },
    'BANNERS_OFFICE': {
      'ADD': {
        'TITLE': 'Yeni afiş oluştur'
      },
      'ERROR': {
        'REQUIRED': 'gerekli alan',
        'INVALID': 'Geçersiz alan'
      },
      'TITLE': 'Afişler',
      'SUBTITLE': 'Afiş ayrıntıları',
      'SEARCH': 'Arama',
      'ADD_NEW': 'Yeni afiş ekle',
      'TABLE': {
      },
      'TABS': {
        'BASIC INFO': 'Detaylar'
      },
      'FORM': {
        'URL': 'Bağlantı',
        'BACKGROUND_COLOR': 'Arka plan rengi',
        'BACKGROUND_IMAGE': 'Arka plan görüntüsü',
        'MESSAGE': 'İleti',
        'ALIGN': {
          'VERTICAL': 'Dikey hizalama',
          'HORIZONTAL': 'yatay hizalama',
          'CENTER': 'ortalanmış',
          'TOP': 'Daha yüksek',
          'BOTTOM': 'daha düşük',
          'LEFT': 'Ayrıldı',
          'MIDDLE': 'Ortada',
          'RIGHT': 'Doğru'
        },
        'ACTIVE': 'Aktif',
        'DATES': 'Tarih aralığı'
      },
      'BANNERS LIST': 'Afiş listesi',
      'BANNER CONFIG': 'Afiş yapılandırması',
      'DELETE_TITLE': 'Silme işlemini onayla',
      'DELETE_MESSAGE': 'Seçili başlığı silmek istediğinizden emin misiniz?',
      'DELETE_OK': 'Evet',
      'DELETE_CANCEL': 'Hayır',
    },
    'PRICE_LISTS': {
      'ERROR': {
        'REQUIRED': 'gereklidir',
        'ZERO_VALUE': 'sıfır veya negatif değerde fiyat'
      },
      'TITLE': 'Fiyat listeleri',
      'SUBTITLE': 'Fiyat listesi ayrıntıları',
      'SEARCH': 'Arama',
      'ADD_NEW': 'Yeni liste ekle',
      'TABLE': {
        'NAME': 'isim',
        'ACTIVE': 'Aktif',
        'BANNED': 'yasaklandı',
        'ACTIVATE': 'etkinleştir',
        'BAN': 'yasakla',
        'EDIT': 'Düzenle',
        'REMOVE': 'Sil',
        'VIEW': 'Görünüm',
        'DELETE_TITLE': 'Silme işlemini onayla',
        'DELETE_MESSAGE': 'Seçilen fiyat listesini silmek istediğinizden emin misiniz? <br> <strong><i>{{name}}</i> </strong>',
        'DELETE_OK': 'Evet',
        'DELETE_CANCEL': 'Hayır',
        'PRODUCTS': {
          'DELETE_TITLE': 'Silme işlemini onayla',
          'DELETE_MESSAGE': 'Seçilen ürünü listeden silmek istediğinizden emin misiniz?\n<br><strong><i>{{name}}</i></strong>',
          'DELETE_OK': 'Evet',
          'DELETE_CANCEL': 'Hayır',
        }
      },
      'TABS': {
        'BASIC INFO': 'Ayrıntılar',
        'PRODUCTS': 'Ürün:% s'
      },
      'FORM': {
        'NAME': 'isim',
        'DESCRIPTION': 'Açıklama',
        'FILTER': 'Arama',
        'ACTIVE': 'Aktif',
        'PRECIO_REAL': 'Taban fiyat',
        'PRECIO_VENTA': 'Satış ücreti',
        'PRECIO_PROMOCION': 'Promosyon fiyatı'
      }
    },
    'SERVICES' :{
      'ERROR': {
        'REQUIRED': 'gerekli alan',
        'INVALID': 'Geçersiz alan',
        'SEND_METHOD_ERROR': 'Lütfen birini seçin',
        'CANT_ZERO': 'Değer sıfırdan küçük veya sıfıra eşit olamaz'
      },
      'TITLE': 'Hizmetler',
      'SUBTITLE': 'Hizmet ayrıntıları',
      'SEARCH': 'Arama',
      'ADD_NEW': 'Yeni hizmet ekle',
      'TABLE': {
        'NAME': 'isim',
        'DESCRIPTION': 'Açıklama',
        'PRICE': 'Maliyet',
        'PRICE_VENTA': 'Taban fiyat',
        'DISPATCHABLE': 'modalite',
        'REQUIREMENTS': 'Gereksinimler',
        'CATEGORY': 'Kategori',
        'ACTIVE': 'Mevcut',
        'BANNED': 'yasaklandı',
        'ACTIVATE': 'etkinleştir',
        'BAN': 'yasakla',
        'EDIT': 'Düzenle',
        'REMOVE': 'Sil',
        'VIEW': 'Görünüm',
        'DELETE_TITLE': 'Silme işlemini onayla',
        'DELETE_MESSAGE': 'Seçilen hizmeti silmek istediğinizden emin misiniz?<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Evet',
        'DELETE_CANCEL': 'Hayır',
        'EXPORT': 'Export',
        'IMPORT': 'Import'
      },
      'TABS': {
        'BASIC INFO': 'Ayrıntılar',
        'PRICES': 'Fiyat:% s',
        'INVENTORY': 'Envanter',
        'SAT': 'IRS'
      },
      'FORM': {
        'NAME': 'isim',
        'CATEGORY': 'Kategori',
        'PRICE': 'Maliyet',
        'DESCRIPTION': 'Açıklama',
        'ACTIVE': 'Mevcut',
        'DISPATCHABLE': 'Sevk edilebilir',
        'IN_OFFICE': 'ofis şubesinde',
        'REQUIREMENTS': 'Gereksinimler',
        'APPLICATION_TYPE': 'Uygulama türü',
        'PRICE_REAL': 'Maliyet',
        'PRICE_VENTA': 'Taban fiyat',
        'PRICE_PROMOCION': 'indirimli fiyat',
        'PRICE_RENTA': 'kiralama fiyatı',
        'SKU': 'SKU (benzersiz depolama kodu)',
        'BARCODE': 'Barkod',
        'OFFICE': {
          'PRICE_REAL': 'Ofis şubesi maliyeti',
          'PRICE_VENTA': 'Ofis şubesi için taban fiyat',
          'PRICE_PROMOCION': 'Ofis şubesi için indirimli fiyat',
          'PRICE_RENTA': 'Ofis şubesi için kira bedeli'
        },
        'SAT_CLAVE': 'IRS Key',
        'SAT_UNIDAD': 'IRS Unity',
        'SAT_CONCEPTO': 'IRS Concept',
        'SAT_CONCEPTO_UNIDAD': 'IRS Unity Concept'
      },
      'EXPORT': {
        'TEMPLATE': 'Şablon'
      },
      'IMPORT': {
        'TITLE': 'İçe aktarmak',
        'SUBTITLE': 'Hizmetler',
        'SUCCESS_UPLOAD': 'Başarılı yükleme',
        'DOWNLOAD_TEMPLATE': 'Şablonu indir',
        'ERROR': {
          'EMPTY': 'boş olamaz',
          'DUPLICATED': 'yinelenen',
          'NEW_CATEGORY': 'Yanlış kategori',
          'NEW_APPLICATION_TYPE': 'Yanlış uygulama türü',
          'NO_DISPATCHABLE': 'Bir seçenek belirleyin'
        },
        'TABLE': {
          'NAME': 'İsim',
          'PRICES': 'Fiyat:% s',
          'CATEGORY': 'Kategori',
          'SHIPPING': 'Nakliye',
          'ACTIVE': 'Aktif',
          'BARCODE': 'Barkod',
          'SKU': 'Sku',
          'PRICE_REAL': 'Temel',
          'PRICE_VENTA': 'Satış',
          'PRICE_RENTA': 'Kiraya vermek',
          'PRICE_PROMOCION': 'promosyon',
          'APPLICATION_TYPE': 'Uygulama türü',
          'TAGS': 'Etiketler',
          'IN_OFFICE': 'Ofiste',
          'DISPATCHABLE': 'müşteriye gönder',
          'SAT_CLAVE': 'IRS Key',
          'SAT_UNIDAD': 'IRS Unity',
        },
        'SIDEBAR': {
          'TITLE': 'Yüklemeden önce düzenle',
          'FORM': {
            'NAME': 'isim',
            'CATEGORY': 'Kategori',
            'PRICE': 'Maliyet',
            'DESCRIPTION': 'Açıklama',
            'ACTIVE': 'Mevcut',
            'DISPATCHABLE': 'Sevk edilebilir',
            'IN_OFFICE': 'ofis şubesinde',
            'REQUIREMENTS': 'Gereksinimler',
            'APPLICATION_TYPE': 'Uygulama türü',
            'PRICE_REAL': 'Maliyet',
            'PRICE_VENTA': 'Taban fiyat',
            'PRICE_PROMOCION': 'indirimli fiyat',
            'PRICE_RENTA': 'kiralama fiyatı',
            'SKU': 'SKU (benzersiz depolama kodu)',
            'BARCODE': 'Barkod',
            'OFFICE': {
              'PRICE_REAL': 'Ofis şubesi maliyeti',
              'PRICE_VENTA': 'Ofis şubesi için taban fiyat',
              'PRICE_PROMOCION': 'Ofis şubesi için indirimli fiyat',
              'PRICE_RENTA': 'Ofis şubesi için kira bedeli'
            },
            'DETAILS': 'Detaylar',
            'SHORT_DESCRIPTION': 'Kısa Açıklama',
            'PRICES': 'Fiyat:% s',
            'PRICES_LIST': 'Fiyat listeleri',
            'UPDATE': 'Güncelleme',
            'CANCEL': 'İptal',
            'SAT_FIELDS': 'SAT',
            'SAT_CLAVE': 'IRS Key',
            'SAT_UNIDAD': 'IRS Unity',
            'SAT_CONCEPTO': 'IRS Concept',
            'SAT_CONCEPTO_UNIDAD': 'IRS Unity Concept'
          },
        }
      }
    },
    'ORDERS': {
      'TITLE': 'Emirler',
      'SUBTITLE': 'Order\'s Details',
      'SEARCH': 'Arama',
      'TABLE': {
        'PAYMENT_METHOD': 'Ödeme şekli',
        'TOTAL': 'Toplam',
        'FROM': 'İtibaren',
        'CREATED': 'oluşturuldu',
        'STATUS': 'Durum',
        'CANCELED': 'İptal edildi',
        'PAID': 'Paralı',
        'PENDING': 'Askıda olması',
        'AWAITING': 'Bekleyen ödeme',
        'COMPLETED': 'Tamamlanmış',
        'DECLINED': 'reddedildi',
        'REFUNDED': 'geri ödendi',
        'DISPUTED': 'tartışmalı',
        'VIEW': 'görüş',
        'REMOVE': 'Silmek',
        'APPLICANT': 'başvuru sahibi',
        'CHANGE_STATUS': 'durum değiştirmek',
        'REFERENCE': 'Referans',
        'INVOICE': 'Invoice',
        'GENERATE_INVOICE': 'Generate Invoice',
        'PRICE': 'Price',
        'QTY': 'Qty',
        'SUBTOTAL': 'Subtotal',
        'DISCOUNT': 'Discount',
        'SEND_INVOICE': 'Send invoice'
      },
      'DETAILS': {
        'PURCHASE_DETAILS': 'Purchase details',
        'ORDER_STATUS': 'Order status',
        'AMOUNT': 'Amount',
        'PAYMENT_METHOD': 'Payment method',
        'CREATION_DATE': 'Creation date',
        'CUSTOMER_DETAILS': 'Customer details',
        'CUSTOMER_NAME': 'Name',
        'CUSTOMER_PHONE': 'Phone',
        'CUSTOMER_EMAIL': 'Email',
        'PAYMENT_DETAILS': 'Payment details',
        'PAYMENT_AMOUNT': 'Amount',
        'PAYMENT_CARD': 'Card',
        'PAYMENT_CODE': 'Auth code',
        'PAYMENT_INVOICE': 'Invoice',
        'PAYMENT_OPEN_INVOICE': 'Open in new window',
        'PAYMENT_EXPIRED': 'EXPIRED',
        'PAYMENT_PAID': 'PAID',
      },
      'TABS': {
        'DETAILS': 'Detaylar',
        'CONTENT': 'İçerik'
      },
      'INVOICE': {
        'MODAL': {
          'TITLE': 'Invoice',
          'CLOSE': 'Close'
        }
      },
      'GENERATE_INVOICE': {
        'MODAL': {
          'TITLE': 'Generate Invoice',
          'FORM': {
            'RFCS': 'Client´s Invoice saved',
            'RFC': 'RFC',
            'RAZON_SOCIAL': 'Business name',
            'CFDI': 'CFDI',
            'EMAIL': 'Email',
            'CLOSE': 'Close',
            'GENERATE': 'Generate'
          }
        }
      }
    },
    'CALENDAR': {
      'TITLE': 'Takvim',
      'SEARCH': 'Arama',
      'TODAY': 'Bugün',
      'DAY': 'Gün',
      'WEEK': 'Hafta',
      'MONTH': 'Ay',
      'CATEGORIES': 'Kategoriler',
      'TABS': {
        'SCHEDULE': 'Takvim'
      },
      'SIDEBAR': {
        'TITLE': 'Randevu Detayları',
        'CLIENT': 'Müşteri',
        'APPOINTMENT': 'Randevu',
        'DISPATCHABLE': 'Sevk edilebilir',
        'IN_OFFICE': 'ofis şubesinde',
        'ORDER': 'Order',
        'CANCELED': 'İptal edildi',
        'PAID': 'Paralı',
        'PENDING': 'Askıda olması',
        'AWAITING': 'Bekleyen ödeme',
        'COMPLETED': 'Tamamlanmış',
        'DECLINED': 'reddedildi',
        'REFUNDED': 'geri ödendi',
        'DISPUTED': 'tartışmalı',
        'RELATED_APPOINTMENTS': 'ilgili randevular',
        'SCHEDULED': 'zamanlanmış',
        'NO SHOW': 'Gösteri yok',
        'PENDING APPROVAL': 'Onay bekleyen',
        'REQUEST EXPIRED': 'isteğin süresi doldu',
        'NEW TIME PROPOSED': 'Önerilen yeni zaman',
        'PENDING RESCHEDULE': 'Bekleyen yeniden planlama',
        'PROVIDER': 'Assigned Doctor',
        'FORM': {
          'COMMENTS': 'yorumlar',
          'UPDATE': 'Güncelleme',
          'CANCEL': 'İptal',
          'BACK': 'Geri',
          'STATUS': 'Randevu durumu',
          'WAITING_UPLOAD': 'waiting to upload',
          'ARCHIVED': 'Archived'
        }
      },
      'MODAL': {
        'NEW_APPOINTMENT': {
          'TITLE': 'Appointments details',
          'START_DATE': 'Start Date',
          'START_TIME': 'Stat Time',
          'END_DATE': 'End Date',
          'END_TIME': 'End Time',
          'CLIENT': 'Client',
          'STATUS_DATE': 'Date status',
          'COMMENTS': 'Comments',
          'OFFICE_BRANCH': 'Office',
          'PROVIDER': 'Assigned doctor'
        }
      }
    },
    'COUPONS': {
      'TITLE': 'Kuponlar',
      'SEARCH': 'Arama',
      'ADD_NEW': 'yeni kupon oluştur',
      'SUBTITLE': 'Kupon ayrıntıları',
      'FORM': {
        'NAME': 'İsim',
        'CODE': 'kod',
        'DISCOUNT_TYPE': 'indirim türü',
        'TYPE': 'Tip',
        'VALUE': 'İndirim tutarı',
        'EXPIRATION': 'son kullanma aralığı',
        'QTY': 'Maksimum kullanım',
        'ACTIVE': 'Aktif',
        'BY_PRODUCTS': 'Ürünler için varlık',
        'BY_CART': 'Alışveriş sepeti için aktif',
        'FILTER': 'Search product'
      },
      'ERROR': {
        'REQUIRED': 'Gerekli',
        'LOWER_PERCENT': 'değer %1\'den az'
      },
      'TABS': {
        'BASIC INFO': 'Detaylar',
        'MODALITY': 'modalite',
        'PRODUCTS': 'Ürün:% s'
      },
      'TABLE': {
        'NAME': 'İsim',
        'CODE': 'kod',
        'DISCOUNT_TYPE': 'İndirim',
        'TYPE': 'İndirim',
        'EXPIRATION': 'Son Kullanma Süresi',
        'QTY': 'Maksimum kullanım',
        'ACTIVE': 'aktif',
        'BANNED': 'yasaklandı',
        'ACTIVATE': 'etkinleştir',
        'BAN': 'yasakla',
        'EDIT': 'Düzenle',
        'REMOVE': 'Sil',
        'VIEW': 'Görünüm',
        'DELETE_TITLE': 'Silme işlemini onayla',
        'DELETE_MESSAGE': 'Seçilen kuponu silmek istediğinizden emin misiniz? <br> <strong><i>{{name}}</i> </strong>',
        'DELETE_OK': 'Evet',
        'DELETE_CANCEL': 'Hayır',
        'START': 'Başlangıç',
        'END': 'Son',
        'USED': 'Kullanılmış',
        'PRODUCTS': {
          'DELETE_TITLE': 'Silme işlemini onayla',
          'DELETE_MESSAGE': 'Seçilen ürünü listeden silmek istediğinizden emin misiniz?\n<br><strong><i>{{name}}</i></strong>',
          'DELETE_OK': 'Evet',
          'DELETE_CANCEL': 'Hayır',
        }
      }
    },
    'APPOINTMENTS': {
      'TITLE': 'Appointments',
      'SEARCH': 'Search',
      'ADD_NEW': 'Create new appointment',
      'TABLE': {
        'DATE': 'Date',
        'CLIENT': 'Client',
        'STATUS': 'Status',
        'NOTES': 'Comments',
        'EDIT': 'Edit',
        'VIEW': 'View',
        'DISPATCHABLE': 'Sevk edilebilir',
        'IN_OFFICE': 'ofis şubesinde',
        'ORDER': 'Order',
        'CANCELED': 'İptal edildi',
        'PAID': 'Paralı',
        'PENDING': 'Askıda olması',
        'AWAITING': 'Bekleyen ödeme',
        'COMPLETED': 'Tamamlanmış',
        'DECLINED': 'reddedildi',
        'REFUNDED': 'geri ödendi',
        'DISPUTED': 'tartışmalı',
        'RELATED_APPOINTMENTS': 'ilgili randevular',
        'SCHEDULED': 'zamanlanmış',
        'NO SHOW': 'Gösteri yok',
        'PENDING APPROVAL': 'Onay bekleyen',
        'REQUEST EXPIRED': 'isteğin süresi doldu',
        'NEW TIME PROPOSED': 'Önerilen yeni zaman',
        'PENDING RESCHEDULE': 'Bekleyen yeniden planlama',
        'SERVICE': 'Service',
        'PHONE': 'Phone',
        'LOCATION': 'Location',
        'OFFICE': 'Office'
      }
    },
    'SETTINGS': {
      'TITLE': 'Settings',
      'ADD_NEW_CHAT': 'Add chat response',
      'TABS': {
        'BASIC': 'General configuration',
        'CHATBOT': 'Chatbot',
        'CALENDAR': 'Calendar',
        'API': 'API\'s'
      },
      'ERRORS': {
        'REQUIRED': 'Gerekli',
        'LOWER_PERCENT': 'değer %1\'den az',
        'SAVE': 'Please, try again in 5 minutes',
        'NO_LOAD': 'Unable to load page',
      },
      'TABLE': {
        'MESSAGE': 'Expected message',
        'RESPONSE': 'Bot repsonse',
        'ACTIVE': 'aktif',
        'BANNED': 'yasaklandı',
        'ACTIVATE': 'etkinleştir',
        'BAN': 'yasakla',
        'EDIT': 'Düzenle',
        'REMOVE': 'Sil',
        'VIEW': 'Görünüm',
      },
      'FORM': {
        'GENARAL': {
          'NAME': 'Enterprise name'
        },
        'TITLE': 'Chatbot Settings',
        'MESSAGE': 'Expected message',
        'RESPONSE': 'Bot response',
        'DEACTIVED': 'Deactivated',
        'ACTIVED': 'Activated',
        'RESPONSE_OFFICES': 'Office branch list (max. 10)',
        'RESPONSE_AGENT': 'An agent will attend you in a few minutes',
        'SAVE': 'Save',
        'UPDATE': 'Update',
        'DELETE': 'Delete',
        'CANCEL': 'Cancel',
        'CHIPS': {
          'USERNAME': 'Client´s name',
          'PRODUCTS': 'Search products',
          'SERVICES': 'Search services',
          'OFFICES': 'Offices',
          'URL': 'url çevrimiçi mağaza',
          'NOTIFY_AGENT': 'Notify agent',
          'FOLIO': 'Invoice number',
          'RESPONSE_VARIABLES': 'Variables you can use on responses',
          'REQUEST_VARIABLES': 'Variables you can use to match questions'
        },
        'NAME': 'Name',
        'LOGO': 'Logo',
        'ICON': 'Icon',
        'STORE_LINK': 'Online store link',
        'CURRENCY': 'Currency',
        'PAYMENTS_METHOD': 'Online payment provider',
        'CONEKTA_PUBLIC_KEY': 'Conekta public key',
        'CONEKTA_PRIVATE_KEY': 'Conekta private key',
        'CONEKTA_OXXO_EXPIRATION': 'Conekta OXXO payment expiration',
        'CONEKTA_OXXO_MINUTES': 'minutes',
        'CONEKTA_OXXO_DEFAULT_MINUTES': 'Default 60 minutes',
        'INVOICE_PROVIDER': 'Invoice provider',
        'FACTURAMA_USER': 'Facturama user',
        'FACTURAMA_PASSWORD': 'Facturama password',
        'FACTURAMA_MODE': 'Production environment',
        'FACTURAMA_MODE_LABEL': 'Production',
        'WHATSAPP_PROVIDER': 'Whatssap message provider',
        'TWILIO_ACCOUNT_SID': 'Account SID',
        'TWILIO_AUTH_TOKEN': 'Auth Token',
        'TWILIO_PHONE_FROM': 'Phone number from',
        'GOOGLE_API_DISCLAIMER': 'Google services API key',
        'GOOGLE_API': 'Google API',
        'GOOGLE_FIREBASE': 'Google Firebase database (Real-time Database)',
        'PAYPAL_API_DISCLAIMER': 'Paypal api integration',
        'PAYPAL_CLIENT': 'Paypal API key',
        'EMAIL': 'Email',
        'PHONE': 'Phone',
        'CP': 'Postal Code',
        'ADDRESS': 'No. & Street',
        'CITY': 'City',
        'STATE': 'State',
        'COUNTRY': 'Country',
        'USE_WEBHOOK': 'Use webhook',
        'WEBHOOK_URL': 'Webhook link',
        'WEBHOOK_METHOD': 'Webhook Method'
      },
      'MODAL': {
        'DELETE_TITLE': 'Silme işlemini onayla',
        'DELETE_MESSAGE': 'Seçili sohbet robotu sorusunu silmek istediğinizden emin misiniz?\n<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Evet',
        'DELETE_CANCEL': 'Hayır'
      },
      'GENERAL': {
        'TITLE': 'General settings'
      },
      'CHATBOT': {
        'TITLE': 'Chatbot settings',
        'TABS': {
          'BASIC': 'General Info',
          'MESSAGES': 'Messages'
        },
        'FORM': {
          'DEFAULT_RESPONSE': 'Default response',
          'DISABLE_TIME': 'Duration that the bot will remain disabled'
        }
      },
      'APIS': {
        'TITLE': 'Api\'s integration'
      },
      'MAIL': {
        'TITLE': 'Server mail settings',
        'FORM': {
          'HOST': 'Host',
          'PORT': 'Port',
          'ENCRYPTION': 'Encryption',
          'USERNAME': 'Username',
          'PASSWORD': 'Password',
          'MAILER': 'Mail driver',
          'SENDER': 'Sending mail'
        },
        'TEST_MAIL_SERVER': 'Test Settings',
        'MODAL': {
          'TITLE': 'Testing email server',
          'EMAIL': 'Email',
          'SEND': 'Send'
        }
      },
      'THEME': {
        'TITLE': 'Appearance',
        'FORM': {
          'PUBLISH': 'Publish',
          'DELETE': 'Delete',
          'SAVE': 'Save',
          'NAME': 'Name',
          'CANCEL': 'Cancel',
          'EDIT': 'Edit',
          'CLAVE': 'Code name',
          'CLAVE_OPTIONAL': 'Optional',
          'UPDATE': 'Update'
        },
        'MODAL': {
          'DELETE_MESSAGE': 'The information can no longer be recovered',
          'DELETE_OK': 'Delete',
          'DELETE_TITLE': 'Delete theme files',
          'DELETE_CANCEL': 'Cancel'
        },
        'TABS': {
          'THEMES': 'Themes',
          'SETTINGS': 'Settings'
        },
        'CHOOSE_FILE': 'Choose choose the zip file',
        'OR_DRAG_HERE': 'or drag it here',
        'SENDING_FILE': 'Sending file'
      }
    },
    'TOOLBAR': {
      'USER': {
        'ACCOUNT': 'My Profile',
        'INBOX': 'Inbox',
        'LOGOUT': 'Logout'
      },
      'NOTIFICATIONS': {
        'TITLE': 'Notifications',
        'MARK_ALL': 'Mark all as read',
        'MARK_READ': 'Mark as read',
        'MARK_UNREAD': 'Mark as unread',
        'REMOVE': 'Remove',
        'REMOVE_MESSAGE': 'Are you sure you want to delete this notification?',
        'REMOVE_YES': 'Yes',
        'REMOVE_NO': 'No'
      }
    },
    'MESSAGES': {
      'TOOLBAR': {
        'MENU': {
          'PROFILE': 'Profile',
          'LOGOUT': 'Logout'
        },
        'SEARCH': {
          'PLACEHOLDER': 'Search for a message or client'
        }
      },
      'CHATS': {
        'TITLE': 'Chats',
        'ITEM': {
          'FILE': 'File'
        },
        'CHAT_CENTER': 'Message Center',
        'CHOOSE_A_CHAT': 'Select a contact to open a chat!',
        'CONTACT_INFO': 'Contact Info',
        'TYPE_A_MESSAGE': 'Type your message',
        'DISABLE_BOT': 'Disable Chatbot',
        'ENABLE_BOT': 'Enable Chatbot',
        'CHOOSE_FILE': 'Choose a file',
        'OR_DRAG_HERE': 'or drag it here',
        'SENDING_FILE': 'Sending file',
        'DISABLED_CHATBOT': 'Disabled chatbot for the client {client}',
        'ENABLED_CHATBOT': 'Enabled chatbot for the client {{client}}',
        'SEND_FILE': 'Send file',
        'REGISTER_CLIENT': 'Register client',
        'OPEN_GALLERY': 'Open files gallery preview',
        'ERRORS': {
          'INVALID_FORMAT': 'Invalid format file {{type}}',
          'INVALID_SIZE': 'File ({{size1}}) exeed the max limit size ({{size2}})'
        }
      }
    },
    'FORMS': {
      'TITLE': 'Forms',
      'SUBTITLE': 'Create forms to be able to send to your customers',
      'ADD_NEW': 'Add new form',
      'TABLE': {
        'NAME': 'Name',
        'FIELDS': 'Fields',
        'ACTIVE': 'Active',
        'BANNED': 'Banned',
        'BAN': 'Ban',
        'ACTIVATE': 'Activate',
        'EDIT': 'Edit',
        'VIEW': 'View',
        'REMOVE': 'Remove'
      },
      'TABS': {
        'BASIC INFO': 'Details',
        'FIELDS': 'Build form'
      },
      'FORM': {
        'NAME': 'Name',
        'ACTIVE': {
          'ACTIVED': 'Active',
          'DEACTIVED': 'Inactive'
        },
        'ADD_STEP': 'Add a step'
      }
    }
  }
};
