import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxSpinnerModule } from 'ngx-spinner';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';

import { PipesModule } from 'app/pipes/pipes.module';

@NgModule({
  declarations: [
    ToolbarComponent
  ],
  imports     : [
    RouterModule,
    MatBadgeModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxSpinnerModule,

    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    TranslateModule.forChild(),
    PipesModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports     : [
    ToolbarComponent,
    TranslateModule
  ]
})
export class ToolbarModule { }
