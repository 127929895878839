
import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, Subject } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Response } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';
import * as moment from 'moment';

@Injectable()
export class ProductsImportService {
  subject = new Subject();
  private currentProduct: any;
  private _onCurrentProductChanged: BehaviorSubject<any>;
  private _onProductSaved: BehaviorSubject<any>;

  constructor(private http: HttpClient) {}

  setCurrentProduct(product: any): void {
    this.currentProduct = product;
    if (this._onCurrentProductChanged) {
      this._onCurrentProductChanged.next(product);
    } else {
      this._onCurrentProductChanged = new BehaviorSubject(product);
    }
  }

  getCurrentProduct(): any | null {
    return this.currentProduct;
  }

  onCurrentProductChanged(): Observable<any|null> {
    if (!this._onCurrentProductChanged) {
      this._onCurrentProductChanged = new BehaviorSubject(null);
    } 
    return this._onCurrentProductChanged?.asObservable();
  }

  onProductSaved(): Observable<any|null> {
    if (!this._onProductSaved) {
      this._onProductSaved = new BehaviorSubject(null);
    } 
    return this._onProductSaved?.asObservable();
  }

  save(product) {
    this._onProductSaved.next(product);
  }

  uploadFile(file: File): Observable<Response<any>> { 
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<any>>(new ServerApiPipe().transform(`products/import`), formData, { headers });
  }
}
