import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ 
  name: 'waFormat',
  pure: false
})
export class WaFormatPipe implements PipeTransform {
  transform(value: string): any {
    return (value || '').replace(/\*([\w\W]+)\*/, '<b>$1</b>');
  }
}