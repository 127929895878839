import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { SettingsService } from 'app/core/services/settings.service';
import { Settings } from 'app/core/interfaces/all.interface';

@Injectable()
export class FacturamaClient {
  constructor(
    private _settings: SettingsService,
    private http: HttpClient
  ) {}

  createAuthorizationHeader(): HttpHeaders {
    const _facturama = this._settings.getCurrentSettings().facturama_client || '';
    if (_facturama.trim() != '') {
      return new HttpHeaders({
        'Authorization': 'Basic ' + _facturama
      });
    }

    return new HttpHeaders({});
  }

  get<T>(url) {
    return this.http.get<T>(url, {
      headers: this.createAuthorizationHeader()
    });
  }

  post<T>(url, data) {
    return this.http.post<T>(url, data, {
      headers: this.createAuthorizationHeader()
    });
  }
}