import { Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpEventType } from "@angular/common/http";
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { UtilsService } from 'app/core/services/utils.service';
import { FilesService } from 'app/core/services/files.service';
import { UsersService } from 'app/core/services/users.service';
import { ChatService } from 'app/core/services/chat.service';
import { SettingsService } from 'app/core/services/settings.service';
import { Response, Active, UserFile, User } from 'app/core/interfaces/all.interface';
import { ServerFilePipe } from 'app/pipes/server_file.pipe';
import { Track, AudioPlayerComponent } from 'ngx-audio-player';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatChipInputEvent } from '@angular/material/chips';
import { BytesPipe } from 'angular-pipes';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';

@Component({
  selector: 'file-details-sidebar',
  templateUrl: './file-details-sidebar.component.html',
  styleUrls: ['./file-details-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class FileDetailsSidebarComponent implements OnInit, OnDestroy {
  file: UserFile;
  maxfileVersion: string = '';
  fileWaiting: File;
  fileWaitingPosting: any = {
    posted_data: 0,
    posted_total: 0,
    progress: 58
  };
  msaapPlaylist: Track[] = [];
  pdfUrl: SafeResourceUrl;
  form: FormGroup;
  tags: any[] = [];

  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  @ViewChild(AudioPlayerComponent) musicPlayer: AudioPlayerComponent;
  @ViewChild('media') videoPlayer: any;

  @HostBinding('class.bar-closed') barClosed: boolean;
  private _unsubscribeAll: Subject<any>;
  private _unsubscribeFile: any;

  get canEdit(): boolean {
    return this._utils.hasAccess('users/update') || this._utils.hasAccess('clients/update');
  }

  get canAdd(): boolean {
    return this._utils.hasAccess('users/create') || this._utils.hasAccess('clients/create');
  }

  get isImage() {
    return this.file.type.indexOf('image/') != -1;
  }

  get isAudio() {
    return this.file.type.indexOf('audio/') != -1;
  }

  get isVideo() {
    return this.file.type.indexOf('video/') != -1;
  }

  get isDocument() {
    return this.file.type.indexOf('application/pdf') != -1;
  }

  get isOtherFile() {
    return this.file.type.indexOf('application/pdf') == -1 && 
      this.file.type.indexOf('image/') == -1 &&
      this.file.type.indexOf('audio/') == -1 &&
      this.file.type.indexOf('video/') == -1;
  }

  get TwilioPhone(): string {
    return this._settings.getCurrentSettings().twilio_phone_from;
  }

  constructor(
    private _fuseSidebarService: FuseSidebarService,
    private _files: FilesService,
    public _matDialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _utils: UtilsService,
    private _users: UsersService,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    private _chat: ChatService,
    private _translate: TranslateService,
    private _settings: SettingsService
  ) {
    // Set the defaults
    this.barClosed = true;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._unsubscribeFile = this._files.onCurrentFileChanged().subscribe((file: UserFile) => {
      this.fileWaiting = this.file = null;
      this._utils.showLoading('file_details_loading');
      if (file) {
        this.file = { ...file };
        this.maxfileVersion = Math.max.apply(null, this.file.versions.map(v => v.version));

        if (!this.form) {
          this.form = this._formBuilder.group({
            id: this._formBuilder.control({ value: file.id, disabled: (!this.canEdit) }),
            name: this._formBuilder.control({ value: file.name, disabled: (!this.canEdit) }),
            type: this._formBuilder.control({ value: file.type, disabled: true }),
            size: this._formBuilder.control({ value: new BytesPipe().transform(file.size), disabled: true }),
            tags: this._formBuilder.control({ value: file.tags, disabled: (!this.canEdit) }),
            version: this._formBuilder.control({ value: file.version, disabled: this.file.versions.length <= 1 }),
            hash_name: this._formBuilder.control({ value: file.hash_name, disabled: true }),
            extension: this._formBuilder.control(file.extension),
            path: this._formBuilder.control(new ServerFilePipe().transform(this.file.path)),
            folder: this._formBuilder.control(file.folder),
            updated_at: this._formBuilder.control({ value: new DatePipe('en').transform(file.updated_at, 'yyyy-MM-dd hh:mm a'), disabled: true }),
          });
        } else {
          this.form.setValue({
            id: file.id,
            name: file.name,
            type: file.type,
            size: new BytesPipe().transform(file.size),
            tags: file.tags,
            version: file.version,
            hash_name: file.hash_name,
            extension: file.extension,
            path: new ServerFilePipe().transform(this.file.path),
            folder: file.folder,
            updated_at: new DatePipe('en').transform(file.updated_at, 'yyyy-MM-dd hh:mm a'),
          });
          if (this.file.versions.length <= 1) {
            this.form.get('version').disable();
          } else {
            this.form.get('version').enable();
          }
        }
        this.tags = [...this.file.tags];

        if (this.isAudio) {
          this.msaapPlaylist = [{
            title: this.file.name,
            link: new ServerFilePipe().transform(this.file.path),
            artist: ''
          }];
        }
        if (this.isVideo) {
          
        }
        if (this.isDocument) {
          this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(new ServerFilePipe().transform(this.file.path));
        }
        setTimeout(() => {
          this._utils.hideLoading('file_details_loading')
        }, 1000);
      } else {
        if (this.musicPlayer) {
          this.msaapPlaylist = [];
        }

        if (this.videoPlayer) {
          this.videoPlayer.nativeElement.pause();
          this.videoPlayer.nativeElement.currentTime = 0;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeFile?.unsubscribe();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebarOpen(key): void {
    if (this.musicPlayer) {
      this.msaapPlaylist = [];
    }

    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.pause();
      this.videoPlayer.nativeElement.currentTime = 0;
    }
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
  
  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add tag
    if ( value ) {
      this.tags.push(value);
    }

    // Reset the input value
    if ( input ) {
      input.value = '';
    }
  }

  removeTag(tag): void {
    const index = this.tags.indexOf(tag);

    if ( index >= 0 ) {
      this.tags.splice(index, 1);
    }
  }

  updateFile() {
    if (this.form.valid) {
      this._utils.showLoading('file_details_loading');
      let isMedia = this.fileWaiting;
      this._users.postFile({
        id: this.file.user_id
      } as User, this.fileWaiting, { 
        ...this.form.getRawValue(), 
        ...{ tags: this.tags, version: this.maxfileVersion }
      }).subscribe((responseFile) => {
        if (isMedia) {
          if (responseFile.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * responseFile.loaded / responseFile.total);
            this.fileWaitingPosting.progress = percentDone;
            this.fileWaitingPosting.posted_data = responseFile.loaded;
            this.fileWaitingPosting.posted_total = responseFile.total;
          }
        }
        if (responseFile.type === HttpEventType.Response) {
          this.toggleSidebarOpen('FileDetails');

          if (isMedia) {
            this._files.doFileSave({
              ...responseFile.body.data, 
              ...{ new: true }
            });
          } else {
            this._files.doFileSave({...this.form.getRawValue(), ...{ tags: this.tags }});
          }
          this._utils.hideLoading('file_details_loading');
          this.fileWaiting = null;
        }
      }, (error) => {
          this._utils.hideLoading('file_details_loading')
      });
    }
  }

  copiedLink() {
    this._snackBar.open(this._translate.instant(`MANAGEMENT.USERS.FILES.FILE_DETAILS.COPIED_CONTENT`), null, {
      duration: 3000,
      panelClass: ['success-snackbar']
    });
  }

  deleteFile() {
    this._utils.showLoading('file_details_loading');
    this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    this.confirmDialogRef.componentInstance.confirmMessage = this._translate.instant(`MANAGEMENT.USERS.FILES.FILE_DETAILS.DELETE_MESSAGE`);
    this.confirmDialogRef.componentInstance.confirmOk = this._translate.instant(`MANAGEMENT.USERS.FILES.FILE_DETAILS.DELETE_OK`);
    this.confirmDialogRef.componentInstance.confirmTitle = this._translate.instant(`MANAGEMENT.USERS.FILES.FILE_DETAILS.DELETE_TITLE`);
    this.confirmDialogRef.componentInstance.confirmCancel = this._translate.instant(`MANAGEMENT.USERS.FILES.FILE_DETAILS.DELETE_CANCEL`);

    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._files.delete({
        id: this.file.user_id
      } as User, this.file).subscribe((response: Response<any>) => {
          this._utils.hideLoading(`file_details_loading`);
          if (response.success) {
            this.toggleSidebarOpen('FileDetails');
            this._files.doFileDelete(this.form.value);
          }
        }, (error) => {
          this._utils.hideLoading(`file_details_loading`);
        });
      } else {
        this._utils.hideLoading(`file_details_loading`);
      }
      this.confirmDialogRef = null;
    });
  }

  sharWhatsapp() {
    if (!this.form.value.path) {
      return;
    }

    if (!this.file.user.phone) {
      return;
    }

    let _message = {
      body: this.isOtherFile ? this.form.value.path : '',
      user_id: +this.file.user_id,
      date_sent: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      direction: 'outbound',
      from_phone: `whatsapp:+${this.TwilioPhone}`,
      status: 'queued',
      to_phone: `whtasapp:+${this.file.user.phone}`,
      media: this.isOtherFile ? '' : this.form.value.path
    };

    this._chat.sendMessage(_message).subscribe((response) => {
      this._snackBar.open(this._translate.instant(`MANAGEMENT.USERS.FILES.FILE_DETAILS.SENDED_CONTENT`), null, {
        duration: 3000,
        panelClass: ['success-snackbar']
      });
    }, (error) => {
    });
  }

  fileChangeEvent(event) {
    console.log([...event.srcElement.files]);

    [...event.srcElement.files].forEach((f) => {
      this.fileWaiting = f;
      if (this.validateFileType(f) && this.validateFileSize(f.size)) {
      } else if (!this.validateFileType(f)) {
        this.fileWaiting['error'] = "MANAGEMENT.USERS.FILES.ERRORS.WRONG_TYPE";
        setTimeout(() => {
          this.fileWaiting = null;
        }, 20000);
      } else if (!this.validateFileSize(f.size)) {
        this.fileWaiting['error'] = "MANAGEMENT.USERS.FILES.ERRORS.WRONG_SIZE";
        setTimeout(() => {
          this.fileWaiting = null;
        }, 20000);
      } else {
        this.fileWaiting['error'] = "MANAGEMENT.USERS.FILES.ERRORS.WRONG_TYPE";
        setTimeout(() => {
          this.fileWaiting = null;
        }, 20000);
      }
    });
  }

  validateFileType(file) {
    const valid_extensions = ['.docx', '.jpg', '.jpng', '.png', '.pdf', '.doc', '.ppt', '.pptx', '.csv', '.xls', '.xlsx', '.png', '.gif', '.tiff', '.bmp', '.mp3', '.acc', '.wma', '.json', '.avi'];
    const extension = file.name.slice((file.name.lastIndexOf(".") - 2 >>> 0) + 2);
    return file.type.indexOf('application/pdf') != -1 || 
      file.type.indexOf('application/msword') != -1 ||
      file.type.indexOf('application/vnd.ms-powerpoint') != -1 ||
      file.type.indexOf('application/vnd.ms-excel') != -1 ||
      file.type.indexOf('application/vnd.openxmlformats-officedocument.presentationml.presentation') != -1 ||
      file.type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') != -1 ||
      file.type.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') != -1 ||
      file.type.indexOf('image/') != -1 ||
      file.type.indexOf('audio/') != -1 ||
      file.type.indexOf('video/') != -1 || 
      file.type.indexOf('text/') != -1 ||
      valid_extensions.includes(extension);
  }

  validateFileSize(size) {
    return size <= 5242880;
  }

  changeVersion(file_version: string) {
    if (this.musicPlayer) {
      this.msaapPlaylist = [];
    }

    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.pause();
      this.videoPlayer.nativeElement.currentTime = 0;
    }

    const file = this.file.versions.find(v => v.version == file_version);
    if (file) {
      this.file.name = file.name;
      this.file.type = file.type;
      this.file.size = file.size;
      this.file.updated_at = file.updated_at;
      this.file.extension = file.extension;
      this.file.path = file.path;
      this.file.tags = file.tags;
      
      if (this.isAudio) {
        this.msaapPlaylist = [{
          title: this.file.name,
          link: new ServerFilePipe().transform(this.file.path),
          artist: ''
        }];
      }

      if (this.isDocument) {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(new ServerFilePipe().transform(this.file.path));
      }

      this.form.patchValue({
        id: file.id,
        name: file.name,
        type: file.type,
        extension: file.extension,
        size: new BytesPipe().transform(file.size),
        path: new ServerFilePipe().transform(file.path),
        updated_at: new DatePipe('en').transform(file.updated_at, 'yyyy-MM-dd hh:mm a'),
      });

      this.tags = [...this.file.tags];
    }
  }
}
