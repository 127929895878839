
import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Login, Office, Response, Active, Address, Product, ProductOffice, Banner } from 'app/core/interfaces/all.interface';

import { ServerApiPipe } from 'app/pipes/server_api.pipe';

@Injectable()
export class OfficesService {
  private currentOffice: Office;
  private _onCurrentOfficeChanged: BehaviorSubject<Office>;

  constructor(private http: HttpClient) {}

  all(body: any): Observable<Response<Office[]>> {
    let params = new HttpParams().set("s", body.s || '');

    return this.http.get<Response<Office[]>>(new ServerApiPipe().transform(`sucursales`), { params });
  }

  get(id: Number): Observable<Response<Office>> {
    return id > 0 ? this.http.get<Response<Office>>(new ServerApiPipe().transform(`sucursales/${id}`)) : of({
      status: 'success',
      success: true,
      message: '',
      data: {
        name: '',
        empresa: '',
        directions: '',
        active: false,
        phone: '',
        email: '',
        rfc: '',
        street: '',
        colony: '',
        internal_number: '',
        external_number: '',
        city: '',
        state: '',
        country: '',
        curp: '',
        registro_patronal: '',
        longitude: '',
        latitude: '',
        zoom: 17,
        area: '',
        social_facebook: '',
        social_twitter: '',
        social_whatsapp: '',
        logotipo: '',
        price_list_id: -1,
        open_time: '',
        close_time: '',
        products: [],
        tax: 16
      } as Office
    } as Response<Office>);
  }

  activate(office: Office, active: boolean = true): Observable<Response<Active>> {
    return this.http.put<Response<Active>>(new ServerApiPipe().transform(`sucursales/${office.id}/activate`), { active: active });
  }

  delete(office: Office): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`sucursales/${office.id}`));
  }

  update(office: Office): Observable<Response<Office>> {
    return this.http.put<Response<Office>>(new ServerApiPipe().transform(`sucursales/${office.id}`), office);
  }

  save(office: Office): Observable<Response<Office>> {
    return this.http.post<Response<Office>>(new ServerApiPipe().transform(`sucursales`), office);
  }

  getLogotipo(office: Office): Observable<Blob> {
    return this.http.get(new ServerApiPipe().transform(`sucursales/${office.id}/logotipo`), { responseType: 'blob' });
  }

  setLogotipo(office: Office, logotipo: File): Observable<Response<any>> { 
    const formData: FormData = new FormData();
    formData.append('logotipo', logotipo, logotipo.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<any>>(new ServerApiPipe().transform(`sucursales/${office.id}/logotipo`), formData, { headers });
  }

  list(): Observable<Response<Office[]>> {
    return this.http.get<Response<Office[]>>(new ServerApiPipe().transform(`sucursales/list`));
  }

  setCurrentOffice(office: Office): void {
    this.currentOffice = office;
    if (this._onCurrentOfficeChanged) {
      this._onCurrentOfficeChanged.next(office);
    } else {
      this._onCurrentOfficeChanged = new BehaviorSubject(office);
    }
  }

  getCurrentOffice(): Office | null {
    return this.currentOffice;
  }

  onCurrentOfficeChanged(): Observable<Office|null> {
    if (!this._onCurrentOfficeChanged) {
      this._onCurrentOfficeChanged = new BehaviorSubject(null);
    } 
    return this._onCurrentOfficeChanged?.asObservable();
  }

  products(body: any = {}): Observable<Response<Product[]>> {
    let params = new HttpParams().set("s", body.s || '');
    return this.http.get<Response<Product[]>>(new ServerApiPipe().transform(`sucursales/${this.currentOffice.id}/products`), { params });
  }

  updateProduct(product: Product, relation: ProductOffice): Observable<Response<ProductOffice>> {
    return this.http.put<Response<ProductOffice>>(new ServerApiPipe().transform(`sucursales/${this.currentOffice.id}/products/${product.id}`), relation);
  }

  deleteProduct(relation: ProductOffice): Observable<Response<ProductOffice>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`sucursales/${this.currentOffice.id}/products/${relation.id}`));
  }

  createProduct(product: Product): Observable<Response<ProductOffice>> {
    return this.http.post<Response<ProductOffice>>(new ServerApiPipe().transform(`sucursales/${this.currentOffice.id}/products/${product.id}`), { ...product });
  }

  getProduct(id: number): Observable<Response<ProductOffice>> {
    return this.http.get<Response<ProductOffice>>(new ServerApiPipe().transform(`sucursales/${this.currentOffice.id}/products/${id}`));
  }

  banners(): Observable<Response<Banner[]>> {
    return this.http.get<Response<Banner[]>>(new ServerApiPipe().transform(`sucursales/${this.currentOffice.id}/banners`));
  }

  imageBanner(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  updateBanner(banner: Banner): Observable<Response<Banner>> {
    const formData: FormData = new FormData();
    banner.banner_image && formData.append('banner_image', banner.banner_image, 'banner_image');
    formData.append('id', banner.id.toString());
    formData.append('url', banner.url || '');
    formData.append('backround_color', banner.backround_color || '');
    formData.append('message', banner.message || '');
    formData.append('vertical', banner.vertical || '');
    formData.append('horizontal', banner.horizontal || '');
    formData.append('office_id', banner.office_id?.toString());

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<Banner>>(new ServerApiPipe().transform(`sucursales/${this.currentOffice.id}/banners/${banner.id}`), formData, { headers });
  }

  createBanner(banner: Banner): Observable<Response<Banner>> {
    const formData: FormData = new FormData();
    banner.banner_image && formData.append('banner_image', banner.banner_image, 'banner_image');
    
    formData.append('url', banner.url || '');
    formData.append('backround_color', banner.backround_color || '');
    formData.append('message', banner.message || '');
    formData.append('vertical', banner.vertical || '');
    formData.append('horizontal', banner.horizontal || '');
    formData.append('office_id', banner.office_id?.toString());

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<Banner>>(new ServerApiPipe().transform(`sucursales/${this.currentOffice.id}/banners`), formData, { headers });
  }

  deleteBanner(banner: Banner): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`sucursales/${this.currentOffice.id}/banners/${banner.id}`));
  }
}
