import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ 
  name: 'dateFormat',
  pure: false
})
export class DateFormatPipe implements PipeTransform {
  transform(value: Date | moment.Moment, dateFormat: string, action: string = 'format'): any {
    return moment(value)[action](dateFormat);
  }
}