import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatMessage'
})
export class FormatMessagePipe implements PipeTransform {
  constructor(
    private _translate: TranslateService
  ) {
  }

  transform(value: string, ...args: any[]): string {
    return value
      .replace('{{user_name}}', 'Mr. Luis Castillo')
      .replace('{{offices_list}}', this._translate.instant(`SETTINGS.FORM.RESPONSE_OFFICES`))
      .replace(/(?:\r\n|\r|\n)/g, '<br>')
      .replace('{{store_url}}', 'https://store.demosite.com')
      .replace('{{notify_agent}}', this._translate.instant(`SETTINGS.FORM.RESPONSE_AGENT`))
      .replace('{{folio}}', 'ABCDE-12');
  }
}
