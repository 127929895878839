import { Pipe, PipeTransform } from '@angular/core';

import { environment as env } from 'environments/environment';

@Pipe({
  name: 'server_api'
})
export class ServerApiPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    return `${env.api.url}${value}`;
  }
}
