export const locale = {
  lang: 'mx',
  moment: 'es',
  data: {
    'QUICKPANEL': {
      'TODAY': 'Hoy'
    },
    'ERRORS': {
      'FORBIDDEN': 'Prohibido',
      'ROLE_CLIENTS_NOT_FOUND': 'No existe rol para clientes',
      'UNAUTHORIZED': 'Acceso denegado debido a credenciales no válidas',
      'NOT_FOUND_USER': 'No se encontro el usuario',
      'NOT_FOUND': 'Servidor inalcanzable',
      'NOT_FOUND_PRODUCT': 'No se encontro el producto',
      'NOT_FOUND_ROLE': 'No se encontro el perfil',
      'NOT_FOUND_OFFICE': 'No se encontro la sucursal',
      'NOT_FOUND_CATEGORY': 'No se encontro la categoria',
      'NOT_FOUND_OFFICE_PRODUCT': 'No se encontro el producto',
      'NOT_SHORTCUTS': 'Sin accesos directos todavia',
      'SERVER ERROR':  'Servidor inalcanzable',
      'PRICE_LIST_USED': 'Se encuentra en uso por {{ office }}',
      'WRONG_UPDATE_FILE': 'Error al subir el archivo; Un campo vacio: nombre, categoria, tipo aplicación, precio base o tipo',
      'USER_INACTIVE': 'No tienes permiso para iniciar sesion, por favor active su cuenta',
      'NO_FOUND_CONEKTA_USER': 'Este usuario no está registrado en conekta.',
      'NO_FOUND_CARD_USER': 'Esta tarjeta no está asignada al usuario',
      'WRONG_DATE_RANGE': 'El rango de fechas esta mal',
      'SKU': {
        'NOT_AVAILABLE': 'SKU ya usado',
      },
      'PRICE_LIST_ID': {
        'MIN_LIMIT': 'Por favor selecciona una lista de precio'
      },
      'CODE': {
        'NOT_AVAILABLE': 'el código ya se encuentra en uso'
      },
      'RESPONSE_MESSAGE': {
        'MAX_LIMIT': 'Limite de caracteres'
      },
      'BDAY': {
        'INVALID_DATE': 'Fecha de cumpleaños invalida',
        'MAX_DATE_LIMIT': 'Cumpleaños fuera del rango'
      },
      'EMAIL': {
        'NOT_AVAILABLE': 'Correo electrónico ya esta en uso'
      },
      'ERROR_SENDING': 'No fue posible enviar el correo electrónico',
      'PRODUCT_APPLICATION_TYPE_ID': {
        'WRONG_VALUE': 'No se eligio un tipo de aplicación'
      }
    },
    'NAV': {
      'SHORTCUTS': {
        'PLACEHOLDER': 'buscar una página',
      },
      'BACK_HOME': 'Volver a inicio',
      'CLIENT': {
        'TITLE': 'Clientes'
      },
      'OFFICES': {
        'TITLE': 'Sucursales'
      },
      'CATEGORIES': {
        'TITLE': 'Categorias'
      },
      'PRODUCTS': {
        'TITLE': 'Productos'
      },
      'SERVICES': {
        'TITLE': 'Servicios'
      },
      'PRODUCTS_OFFICE': {
        'TITLE': 'Productos'
      },
      'MANAGEMENT'  : {
        'TITLE': 'Administración',
        'USERS': 'Usuarios',
        'ROLES': 'Roles'
      },
      'BANNERS_OFFICE': {
        'TITLE': 'Banners'
      },
      'APPLICATIONS': 'Aplicaciones',
      'DASHBOARDS'  : 'Tablero principal',
      'CALENDAR'    :  { 
        'TITLE': 'Calendario'
      },
      'APPOINTMENT': {
        'TITLE': 'Citas'
      },
      'ECOMMERCE'   : 'Tienda',
      'ACADEMY'     : 'Academy',
      'MAIL'        : {
        'TITLE': 'Mail',
        'BADGE': '25'
      },
      'MAIL_NGRX'        : {
        'TITLE': 'Mail Ngrx',
        'BADGE': '13'
      },
      'CHAT'        : 'Mensajes',
      'FILE_MANAGER': 'Archivos',
      'CONTACTS'    : 'Contactos',
      'TODO'        : 'Tareas',
      'SCRUMBOARD'  : 'Scrumboard',
      'PRICE_LISTS': {
        'TITLE': 'Lista de precios'
      },
      'ORDERS': {
        'TITLE': 'Pedidos'
      },
      'COUPONS': {
        'TITLE': 'Cupones'
      },
      'CATALOGS': {
        'TITLE': 'Catalogos'
      },
      'LOGOUT': {
        'CLOSE_SESSION': 'Sesion del usuario',
        'TITLE': 'Salir'
      },
      'SETTINGS': {
        'TITLE': 'Configuraciones',
        'GENERAL': 'General',
        'CHATBOT': 'Chatbot',
        'APIS': 'Integraciones (api\'s)',
        'MAIL': 'Servidor de correos',
        'THEME': 'Apariencia'
      },
      'MESSAGES': 'Mensajes',
      'FORMS': {
        'TITLE': 'Formularios'
      }
    },
    'MANAGEMENT': {
      'ROLES': {
        'TITLE': 'Roles',
        'SEARCH': 'Buscar un rol',
        'ADD_NEW': 'AGREGAR NUEVO ROL',
        'SUBTITLE': 'Detalles',
        'ERROR': {
          'REQUIRED': 'No puede ir vacío'
        },
        'FORM': {
          'NAME': 'Nombre',
          'DESCRIPTION': 'Descripción',
          'AVAILABLE ONLINE': 'Disponible en sitio',
          'IS_CLIENT': 'Designado para clientes',
          'IS_SUCURSAL': 'Designado para sucursales',
          'IS_PROVIDER': 'Designado para proveedores'
        },
        'TABS': {
          'PERMISSIONS': 'Permisos',
          'BASIC INFO': 'Detalles'
        },
        'TABLE': {
          'NAME': 'Nombre',
          'AVAILABLE ONLINE': 'Disponible en sitio',
          'AVAILABLE': 'Disponible',
          'UNAVAILABLE': 'No Disponible',
          'DESCRIPTION': 'Detalle',
          'ACCESS': 'Acceso',
          'BAN': 'Bloquear',
          'ACTIVATE': 'Activar',
          'EDIT': 'Editar',
          'REMOVE': 'Eliminar',
          'VIEW': 'Ver',
          'DELETE_TITLE': 'Confirmar borrado',
          'DELETE_MESSAGE': '¿Esta seguro de borrar el perfil seleccionado?<br><strong><i>{{name}}</i></strong>',
          'DELETE_OK': 'Si',
          'DELETE_CANCEL': 'No'
        },
        'PERMISSIONS': {
          'USERS_TITLE': 'Modulo usuarios',
          'PERMISOS_TITLE': 'Modulo permisos',
          'ROLES_TITLE': 'Modulo roles',
          'CLIENTS_TITLE': 'Modulo clientes',
          'USER_LIST': 'Vista usuarios',
          'USER_CREATE': 'Crear nuevos usuarios',
          'USER_EDIT': 'Acceso para actualizar usuarios',
          'USER_DETAIL': 'Vista detalle usuarios',
          'USER_DELETE': 'Acceso para eliminar usuarios',
          'PERMISOS_LIST': 'Ver lista de permisos',
          'PERMISO_CREATE': 'Actualizar / agregar permisos',
          'PERMISO_DETAIL': 'Ver detalles del permiso seleccionado',
          'PERMISO_EDIT': 'Actualizar permisos',
          'PERMISO_DELETE': 'Borrar permisos',
          'ROLES_LIST': 'Ver lista de roles',
          'ROLE_CREATE': 'Crear nuevos roles',
          'ROLE_VIEW': 'Ver detalles del rol seleccionado',
          'ROLE_EDIT': 'Actualizar rol seleccionado',
          'ROLE_DELETE': 'Borrar rol seleccionado',
          'CLIENT_LIST': 'Ver listado de clientes',
          'CLIENT_CREATE': 'Crear nuevo cliente',
          'CLIENT_EDIT': 'Permiso para editar el cliente',
          'CLIENT_DETAIL': 'Permiso para ver detalles del cliente',
          'CLIENT_DELETE': 'Permiso para borrar clientes',
          'CATEGORIES_TITLE': 'Categorias',
          'CATEGORIES_LIST': 'Ver listado de categorias',
          'CATEGORIES_CREATE': 'Crear nuevas categorias',
          'CATEGORIES_EDIT': 'Editar información de la categoria',
          'CATEGORIES_DETAIL': 'Ver detalles de la categoria',
          'CATEGORIES_DELETE': 'Eliminar la categoria',
          'OFFICES_TITLE': 'Sucursales',
          'OFFICE_LIST': 'Ver listado de sucursales',
          'OFFICE_CREATE': 'Crear nueva sucursal',
          'OFFICE_EDIT': 'Editar sucursal',
          'OFFICE_DETAIL': 'Ver detalles de la sucursal',
          'OFFICE_DELETE': 'Eliminar sucursal',
          'PRODUCTS_TITLE': 'Productos',
          'PRODUCTS_LIST': 'Ver listado de productos',
          'PRODUCTS_CREATE': 'Crear nuevos productos',
          'PRODUCTS_EDIT': 'Editar productos',
          'PRODUCTS_DETAIL': 'Ver detalles del producto',
          'PRODUCTS_DELETE': 'Eliminar productos',
          'PRODUCTS_OFFICE_TITLE': 'Productos de sucursal',
          'PRODUCTS_OFFICE_CREATE': 'Crear nuevos productos',
          'PRODUCTS_OFFICE_LIST': 'Ver lista de productos',
          'PRODUCTS_OFFICE_EDIT': 'Editar producto seleccionado',
          'PRODUCTS_OFFICE_DETAIL': 'Ver detalles del producto',
          'PRODUCTS_OFFICE_DELETE': 'Eliminar producto',
          'BANNERS_OFFICE_TITLE': 'Modulo de banners',
          'BANNERS_OFFICE_LIST': 'Ver listado de banners',
          'BANNERS_OFFICE_CREATE': 'Crear nuevos banners',
          'BANNERS_OFFICE_EDIT': 'Editar el banner',
          'BANNERS_OFFICE_DETAIL': 'Ver detalles del banner',
          'BANNERS_OFFICE_DELETE': 'Poder borrar banners',
          'SUBCATEGORIES_TITLE': 'Modulo subcategorias',
          'SUBCATEGORIES_VIEW': 'Poder ver lista de subcategorias dentro del modulo categorias',
          'SUBCATEGORIES_EDIT': 'Poder editar la subcategoria seleccionada',
          'SUBCATEGORIES_CREATE': 'Poder crear nuevas subcategorias',
          'SUBCATEGORIES_DELETE': 'Poder eliminar la subcategoria seleccionada',
          'PRICE_LIST_INDEX': 'Ver listado de precios',
          'PRICE_LIST_TITLE': 'Modulo lista de precios',
          'PRICE_LIST_VIEW': 'Ver detalles de la lista de precios seleccionada',
          'PRICE_LIST_EDIT': 'Poder editar la lista de precios',
          'PRICE_LIST_CREATE': 'Poder crear nuevas listas de precios',
          'PRICE_LIST_DELETE': 'Poder eliminar la lista de precios',
          'PRICE_LIST_PRODUCTS_TITLE': 'Productos para la lista de precios',
          'PRICE_LIST_PRODUCTS_VIEW': 'Puede ver los productos de la lista de precios',
          'PRICE_LIST_PRODUCTS_EDIT': 'Puede editar el producto de la lista',
          'PRICE_LIST_PRODUCTS_CREATE': 'Puede agregar nuevos productos a la lista',
          'PRICE_LIST_PRODUCTS_DELETE': 'Puede eliminar el producto de la lista',
          'SERVICES_TITLE': 'Servicios',
          'SERVICES_INDEX': 'Ver listado de servicios',
          'SERVICES_VIEW': 'Crear nuevos servicios',
          'SERVICES_EDIT': 'Editar servicios',
          'SERVICES_CREATE': 'Ver detalles del servicio',
          'SERVICES_DELETE': 'Eliminar servicios',
          'ORDERS_TITLE': 'Ordenes',
          'ORDERS_INDEX': 'Ver listado de ordenes',
          'ORDERS_VIEW': 'Ver detalle de la orden',
          'ORDERS_EDIT': 'Poder editar la orden',
          'ORDERS_CREATE': 'Poder agregar nuevas ordenes',
          'ORDERS_DELETE': 'Poder elimiar ordenes',
          'CALENDAR_TITLE': 'Modulo calendario',
          'CALENDAR_INDEX': 'Ver listado de citas',
          'CALENDAR_VIEW': 'Ver calendario ',
          'CALENDAR_EDIT': 'Poder editar citas',
          'CALENDAR_CREATE': 'Poder crear nuevas citas',
          'CALENDAR_DELETE': 'Poder eliminar citas',
          'COUPONS_TITLE': 'Cupones',
          'COUPONS_INDEX': 'Ver listado de cupones',
          'COUPONS_VIEW': 'Ver detalles el cupon',
          'COUPONS_EDIT': 'Editar el cupon seleccionado',
          'COUPONS_CREATE': 'Crear nuevos cupones',
          'COUPONS_DELETE': 'Borrar cupones',
          'CHATBOT_TITLE': 'Configuraciones del chatbot',
          'CHATBOT_VIEW': 'Ver opciones de configuracíon del chatbot',
          'CHATBOT_CREATE': 'create nuevas opciones del chatbot',
          'CHATBOT_EDIT': 'Editar opciones del chatbot',
          'CHATBOT_DELETE': 'Eliminar configuraciones del chatbot',
          'SETTINGS_TITLE': 'Permisos en modulo configuraciones',
          'SETTINGS_VIEW': 'Permiso para ver las configuraciones',
          'SETTINGS_EDIT': 'Permiso para editar las configuraciones',
          'SETTINGS_API_TITLE': 'Integración de API\'s',
          'SETTINGS_API_VIEW': 'Ver información de API\'s',
          'SETTINGS_API_EDIT': 'Editar información de API\'s',
          'MESSAGES_TITLE': 'Modulo de chat en vivo',
          'MESSAGES_VIEW': 'Poder entrar al modulo del chat',
          'SETTINGS_GENERAL_TITLE': 'Modulo configuración general',
          'SETTINGS_GENERAL_EDIT': 'Poder editar clas configuraciones generales',
          'SETTINGS_GENERAL_VIEW': 'Poder ver la opción de configuración general en el menu',
          'SETTINGS_MAIL_TITLE': 'Modulo para configuración del servidor de correos',
          'SETTINGS_MAIL_VIEW': 'Poder ver la opción de configuracion de correo en el menu',
          'SETTINGS_MAIL_EDIT': 'Poder editar la configuración del servidor de correos',
          'SETTINGS_THEME_TITLE': 'Modulo para configuración de temas y apariencia',
          'SETTINGS_THEME_INDEX': 'Ver la opcion de apariencia en el menu configuración',
          'SETTINGS_THEME_CREATE': 'Posibilidad de agragar nuevos temas',
          'SETTINGS_THEME_DELETE': 'Posibilidad de borrar temas del listado',
          'SETTINGS_THEME_PUBLISH': 'Posibilidad de publicar el tema seleccionado',
          'FORMS_TITLE': 'Modulo Formularios',
          'FORMS_INDEX': 'Motrar la opcion formulario en el menu',
          'FORMS_VIEW': 'Puede ver el detalle del formulario',
          'FORMS_CREATE': 'Puede crear nuevos formularios',
          'FORMS_UPDATE': 'Puede actualizar formularios',
          'FORMS_DELETE': 'Puede eliminar formularios'
        }
      },
      'USERS': {
        'TITLE': 'Usuarios',
        'SUBTITLE': 'Detalles de usuario',
        'SEARCH': 'Busqueda de usuarios',
        'ADD_NEW': 'AGREGAR NUEVO USUARIO',
        'ERROR': {
          'REQUIRED': 'Requerido',
          'PASSWORD_DONT_MATCH': 'Contraseñas no coinciden',
          'EMPTY_SUCURSAL': 'Favor de seleccionar una sucursal'
        },
        'FORM': {
          'TITLE': 'Titulo',
          'NAME': 'Nombre',
          'LAST NAME': 'Apellido',
          'PHONE NUMBER': 'No. telefónico',
          'OFFICE NUMBER': 'No. oficina',
          'EMAIL': 'Correo electrónico',
          'JOB TITLE': 'Profesión',
          'BIRTHDAY': 'Cumpleaños',
          'GENDER': 'Genero',
          'MALE': 'Masculino',
          'FEMALE': 'Femenino',
          'OTHER': 'Otro',
          'NOT SURE': 'No estoy seguro',
          'RATHER NOT SAY': 'Prefiero no decir',
          'RFC': 'RFC',
          'NO LICENCIA': 'Numero licencia',
          'CEDULA PROFESIONAL': 'Cédula profesional',
          'MATRICULA PROFESIONAL': 'Matricula profesional',
          'ESPECIALIDAD': 'Especialidad',
          'BIOGRAPHY': 'Biografía',
          'USERNAME': 'Nombre usuario',
          'PASSWORD': 'Contraseña',
          'CONFIRM_PASSWORD': 'Confirmar contraseña',
          'ROLE': 'Role',
          'LAST LOGIN': 'Última sesion',
          'SUCURSALES': 'Sucursal',
          'QUOTE': 'Cita (frase)',
          'SKILLS': 'Habilidades',
          'SLOT_DURATION': 'Duración default para cita',
          'NEXT_DAY': 'Mostrar disponibilidad después de (n) dias',
          'MAX_DAYS': 'Mostrar disponibilidad esta cantidad de días',
          'EXTENDED_APPOINTMENT': {
            'ACTIVED': 'Aceptar cita hasta la última hora',
            'DEACTIVED': 'Aceptar cita antes de la última hora'
          },
          'MULTIPLE_SERVICES': {
            'ACTIVED': 'Habilitar multiple selección de servicios',
            'DEACTIVED': 'Deshabilitar multiple selección de servicios'
          },
          'WORKING_TIME': 'Horarios',
          'OPEN_TIME': 'Hora de inicio',
          'CLOSE_TIME': 'Hora de cierre'
        },
        'TABS': {
          'BASIC INFO': 'Información basica',
          'ACCOUNT INFO': 'Información cuenta',
          'LOCATIONS': 'Direcciones',
          'MESSAGES': 'Mensajes',
          'ORDERS': 'Pedidos',
          'FILES': 'Archivos',
          'CARDS': 'Tarjetas',
          'SERVICES': 'Servicios',
          'PROVIDER_INFO': 'Información del proveedor',
          'QUESTIONNAIRES': 'Cuestionarios'
        },
        'TABLE': {
          'NAME': 'Nombre',
          'EMAIL': 'Correo electrónico',
          'PHONE': 'Teléfono',
          'JOB TITLE': 'Profesión',
          'ROLE': 'Role',
          'ACTIVE': 'Activo',
          'INACTIVE': 'Inactivo',
          'BAN': 'Bloquear',
          'ACTIVATE': 'Activar',
          'BANNED': 'Bloqueado',
          'EDIT': 'Editar',
          'REMOVE': 'Eliminar',
          'VIEW': 'Ver',
          'DELETE_TITLE': 'Confirmar borrado',
          'DELETE_MESSAGE': '¿Esta seguro de borrar el usuario seleccionado?<br><strong><i>{{name}}</i></strong>',
          'DELETE_OK': 'Si',
          'DELETE_CANCEL': 'No'
        },
        'MODAL': {
          'CHOOSE_PICTURE': 'Elige imagen de perfil',
          'CROP CIRCLE': 'Cortar en circulo',
          'CROP SQUARE': 'Cortar en cuadrado',
          'ROTATE PLUS 90': 'Rotar +90°',
          'ROTATE MINUS 90': 'Rotar -90°',
          'FLIP VERTICAL': 'Voltear vertical',
          'FLIP HORIZONTAL': 'Voltear horizontal',
          'CANCEL': 'Cancelar',
          'SAVE': 'Guardar',
          'DELETE': {
            'TITLE': 'Removing address',
            'TEXT': 'It will be permanently removed',
            'CONFIRM': 'Ok',
            'CANCEL': 'cancel',
            'SUCCESS': 'Address deleted'
          }
        },
        'ADDRESS': {
          'MODAL': {
            'TITLE': { 
              'NEW': 'Crear nueva dirección',
              'EDIT': 'Editar dirección'
            },
            'NAME': 'Alias',
            'STREET': 'Calle',
            'NUMBER': 'Número exterior',
            'INT_NUMBER': 'Número interior',
            'SUBURB': 'Suburbio / colonia',
            'TOWNSHIP': 'Municipio',
            'CITY': 'Ciudad',
            'STATE': 'Estado',
            'COUNTRY': 'País',
            'DIRECTIONS': 'Entre calles (direcciones)',
            'POSTAL_CODE': 'Codigo postal'
          }
        },
        'FILES': {
          'FILES': '{{number}} Archivos',
          'PDF_FILES': '{{number}} Documentos',
          'IMAGES_FILES': '{{number}} Imagenes',
          'VIDEO_FILES': '{{number}} Videos',
          'AUDIO_FILES': '{{number}} Audios',
          'OTHER_FILES': '{{number}} Otros',
          'UPLOADING': 'Subiendo',
          'FILE_UPLOAD': 'Subr archivos',
          'TAGS': 'Etiquetas',
          'ERRORS': {
            'WRONG_TYPE': 'El archivo no es valido',
            'WRONG_SIZE': 'Tamaño máximo excedido'
          },
          'TABLE': {
            'FILES': 'Archivos',
            'NAME': 'Nombre',
            'TYPE': 'Tipo',
            'SIZE': 'Tamaño',
            'UPDATED_AT': 'Ultima modificación',
            'EDIT': 'Abrir archivo',
            'SHARE': 'Compartir archivo',
            'DELETED': 'Borrar archivo'
          },
          'FILE_DETAILS': {
            'DETAILS': 'Detalles',
            'NAME': 'Nombre',
            'TAGS': 'Etiqutas',
            'SIZE': 'Tamaño',
            'LAST_MODIFIED': 'Ultima modificación',
            'VERSION': 'Versión',
            'SHARE': 'Compartir',
            'LINK': 'Liga',
            'SAVE': 'Guardar',
            'DELETE': 'Borrar',
            'FOLDER': 'Carpeta',
            'COPIED_CONTENT': 'Contenido copiado',
            'DELETE_TITLE': 'Confirmar borrado',
            'DELETE_MESSAGE': '¿Esta seguro de borrar el archivo seleccionado?',
            'DELETE_OK': 'Si',
            'DELETE_CANCEL': 'No',
            'SENDED_CONTENT': 'Archivo enviado',
            'WAITING_UPLOAD': 'Esperando subida',
            'UPLOAD_NEW_VERSION': 'Subir nueva versión'
          }
        },
        'CARDS': {
          'NAME': 'Nombre',
          'TYPE': 'Tipo',
          'BRAND': 'Marca',
          'EXPIRATION': 'Expiración',
          'CARD': 'No. Tarjeta',
          'CARD_TITLE': 'Detalles de tarjeta',
          'FAV': 'Favorito',
          'ADD_NEW': 'Agregar',
          'CVC': 'CVV',
          'SAVE': 'Guardar',
          'DELETE': 'Borrar',
          'DELETE_TITLE': 'Confirmar borrado',
          'DELETE_MESSAGE': '¿Esta seguro de borrar la tarjeta seleccionada?',
          'DELETE_OK': 'Si',
          'DELETE_CANCEL': 'No',
          'ERROR': {
            'DATE': 'Fecha de expiración erronea'
          }
        },
        'SERVICES': {
          'NAME': 'Nombre',
          'DESCRIPTION': 'Descripción',
          'PRICE': 'Precio',
          'ACTIVE': 'Activo',
          'ADD_NEW': 'Agregar nuevo',
          'CARD_TITLE': 'Detalles del servicio',
          'SAVE': 'Guardar',
          'DELETE': 'Borrar',
          'DURATION': 'Duración'
        },
        'QUESTIONNAIRES': {
          'TABLE': {
            'NAME': 'Nombre',
            'FIELDS': 'Campos',
            'STATUS': 'Estatus',
            'UPDATED_AT': 'Ultima actualizacion',
            'EDIT': 'Abrir cuestionario',
            'DELETED': 'Eliminar'
          }
        }
      }
    },
    'CLIENTS': {
      'TITLE': 'Clientes',
      'SUBTITLE': 'Detalles del cliente',
      'SEARCH': 'Buscar',
      'ADD_NEW': 'Agregar nuevo cliente',
      'ERROR': {
        'REQUIRED': 'Requerido',
        'PASSWORD_DONT_MATCH': 'Contraseñas no coinciden'
      },
      'TABLE': {
        'NAME': 'Nombre',
        'EMAIL': 'Correo electrónico',
        'PHONE': 'Teléfono',
        'JOB TITLE': 'Profesión',
        'ROLE': 'Role',
        'ACTIVE': 'Activo',
        'INACTIVE': 'Inactivo',
        'BAN': 'Bloquear',
        'ACTIVATE': 'Activar',
        'BANNED': 'Bloqueado',
        'EDIT': 'Editar',
        'REMOVE': 'Eliminar',
        'VIEW': 'Ver'
      },
      'FORM': {
        'TITLE': 'Titulo',
        'NAME': 'Nombre',
        'LAST NAME': 'Apellido',
        'PHONE NUMBER': 'No. telefónico',
        'OFFICE NUMBER': 'No. oficina',
        'EMAIL': 'Correo electrónico',
        'JOB TITLE': 'Profesión',
        'BIRTHDAY': 'Cumpleaños',
        'GENDER': 'Genero',
        'MALE': 'Masculino',
        'FEMALE': 'Femenino',
        'OTHER': 'Otro',
        'NOT SURE': 'No estoy seguro',
        'RATHER NOT SAY': 'Prefiero no decir',
        'RFC': 'RFC',
        'NO LICENCIA': 'Numero licencia',
        'CEDULA PROFESIONAL': 'Cédula profesional',
        'MATRICULA PROFESIONAL': 'Matricula profesional',
        'ESPECIALIDAD': 'Especialidad',
        'BIOGRAPHY': 'Biografía',
        'USERNAME': 'Nombre usuario',
        'PASSWORD': 'Contraseña',
        'CONFIRM_PASSWORD': 'Confirmar contraseña',
        'ROLE': 'Role',
        'LAST LOGIN': 'Última sesion'
      },
      'TABS': {
        'BASIC INFO': 'Información basica',
        'ACCOUNT INFO': 'Información cuenta',
        'LOCATIONS': 'Direcciones'
      },
    },
    'OFFICES': {
      'ERROR': {
        'REQUIRED': 'Campo requerido',
        'INVALID': 'valor invalido'
      },
      'TITLE': 'Sucursales',
      'SUBTITLE': 'Detalle sucursal',
      'SEARCH': 'Busqueda',
      'ADD_NEW': 'Agregar sucursal',
      'TABLE': {
        'NAME': 'Nombre',
        'EMAIL': 'Correo',
        'PHONE': 'Teléfono',
        'ACTIVE': 'Activo',
        'BANNED': 'Bloqueado',
        'ACTIVATE': 'Activar',
        'BAN': 'Bloquear',
        'EDIT': 'Editar',
        'REMOVE': 'Eliminar',
        'DIRECTIONS': 'Ubicación',
        'VIEW': 'Ver',
        'DELETE_TITLE': 'Confirmar borrado',
        'DELETE_MESSAGE': '¿Esta seguro de borrar la sucursal seleccionada?<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Si',
        'DELETE_CANCEL': 'No'
      },
      'TABS': {
        'BASIC INFO': 'Detalles',
        'PRICE_LIST': 'Lista de Precios'
      },
      'FORM': {
        'NAME': 'Nombre',
        'COMPANY': 'Empresa',
        'PHONE': 'Telefono',
        'EMAIL': 'Correo electrónico',
        'RFC': 'RFC',
        'CURP': 'CURP',
        'DIRECTIONS': 'Direcciones',
        'LOCATION': 'Ubicación',
        'RANGE': 'Area',
        'STREET': 'Calle y numero',
        'COLONY': 'Municipio',
        'CITY': 'Ciudad',
        'STATE': 'Estado',
        'PRICE_LIST': 'Lista de precios para esta sucursal',
        'STOCK': 'Inventario',
        'DURATION': 'Duración de cita',
        'QTY': 'Lugares disponibles',
        'OPEN_TIME': 'Hora de apertura',
        'CLOSE_TIME': 'Hora de cierre',
        'DISPATCHABLE': 'A domicilio',
        'IN_OFFICE': 'En sucursal',
        'TAX': 'Iva (solo informativo)',
      }
    },
    'CATEGORIES': {
      'ERROR': {
        'REQUIRED': 'Requerido'
      },
      'TITLE': 'Categorias',
      'SUBTITLE': 'Detalles de la categoria',
      'SEARCH': 'Busqueda',
      'ADD_NEW': 'Agregar categoria',
      'TABLE': {
        'NAME': 'Nombre',
        'COLOR': 'Color',
        'ACTIVE': 'Activo',
        'BANNED': 'Bloqueado',
        'ACTIVATE': 'Activar',
        'BAN': 'Bloquear',
        'EDIT': 'Editar',
        'REMOVE': 'Eliminar',
        'VIEW': 'Ver',
        'DELETE_TITLE': 'Confirmar borrado',
        'DELETE_MESSAGE': '¿Esta seguro de borrar la categoria seleccionada?<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Si',
        'DELETE_CANCEL': 'No'
      },
      'TABS': {
        'BASIC INFO': 'Detalles',
        'SUBCATEGORIES': 'Subcategorias'
      },
      'FORM': {
        'NAME': 'Nombre',
        'COLOR': 'Color',
        'DESCRIPTION': 'Descripción',
        'ACTIVE': 'Activado'
      }
    },
    'PRODUCTS' :{
      'ERROR': {
        'REQUIRED': 'Campo requerido',
        'INVALID': 'valor invalido',
        'SEND_METHOD_ERROR': 'Favor de seleccionar uno de los valores',
        'CANT_ZERO': 'El valor no puede ser menor o igual a cero'
      },
      'TITLE': 'Productos',
      'SUBTITLE': 'Detalles del producto',
      'SEARCH': 'Buscar',
      'ADD_NEW': 'Agregar nuevo producto',
      'TABLE': {
        'NAME': 'Nombre',
        'DESCRIPTION': 'Descripción',
        'PRICE': 'Precio sin iva',
        'PRICE_VENTA': 'Precio con iva',
        'DISPATCHABLE': 'Modalidad',
        'REQUIREMENTS': 'Requisitos',
        'CATEGORY': 'Categoria',
        'ACTIVE': 'Disponible',
        'BANNED': 'Bloqueado',
        'ACTIVATE': 'Activar',
        'BAN': 'Bloquear',
        'EDIT': 'Editar',
        'REMOVE': 'Eliminar',
        'VIEW': 'Ver',
        'DELETE_TITLE': 'Confirmar borrado',
        'DELETE_MESSAGE': '¿Esta seguro de borrar el producto seleccionado?<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Si',
        'DELETE_CANCEL': 'No',
        'EXPORT': 'Exportar',
        'IMPORT': 'Importar'
      },
      'TABS': {
        'BASIC INFO': 'Detalles',
        'PRICES': 'Precios',
        'INVENTORY': 'Inventario',
        'SAT': 'SAT'
      },
      'FORM': {
        'NAME': 'Nombre',
        'CATEGORY': 'Categoria',
        'PRICE': 'Precio sin iva',
        'DESCRIPTION': 'Descripción',
        'ACTIVE': 'Disponible',
        'DISPATCHABLE': 'A domicilio',
        'IN_OFFICE': 'En sucursal',
        'REQUIREMENTS': 'Requisitos',
        'APPLICATION_TYPE': 'Tipo de aplicación',
        'PRICE_REAL': 'Precio sin iva',
        'PRICE_VENTA': 'Precio con iva',
        'PRICE_PROMOCION': 'Precio promoción',
        'PRICE_RENTA': 'Precio renta',
        'SKU': 'SKU (código de almacenamiento único)',
        'BARCODE': 'Codigo de barras',
        'OFFICE': {
          'PRICE_REAL': 'Costo para sucursal',
          'PRICE_VENTA': 'Precio base para sucursal',
          'PRICE_PROMOCION': 'Precio promoción para sucursal',
          'PRICE_RENTA': 'Precio renta para sucursal',
        },
        'SAT_CLAVE': 'Clave',
        'SAT_UNIDAD': 'Clave unidad',
        'SAT_CONCEPTO': 'Concepto',
        'SAT_CONCEPTO_UNIDAD': 'Concepto unidad'
      },
      'EXPORT': {
        'TEMPLATE': 'Plantilla'
      },
      'IMPORT': {
        'TITLE': 'Importar',
        'SUBTITLE': 'Productos',
        'SUCCESS_UPLOAD': 'Carga de archivo exitosa',
        'DOWNLOAD_TEMPLATE': 'Descargar plantilla',
        'ERROR': {
          'EMPTY': 'valor vacio',
          'DUPLICATED': 'valor duplicado',
          'NEW_CATEGORY': 'categoria erronea',
          'NEW_APPLICATION_TYPE': 'tipo aplicación erronea',
          'NO_DISPATCHABLE': 'Seleccione una opción'
        },
        'TABLE': {
          'NAME': 'Nombre',
          'PRICES': 'Precios',
          'CATEGORY': 'Categoria',
          'SHIPPING': 'Envios',
          'ACTIVE': 'Activo',
          'BARCODE': 'Barcode',
          'SKU': 'Sku',
          'PRICE_REAL': 'Sin iva',
          'PRICE_VENTA': 'Con iva',
          'PRICE_RENTA': 'Renta',
          'PRICE_PROMOCION': 'Promo',
          'APPLICATION_TYPE': 'Tipo aplicación',
          'TAGS': 'Etiquetas',
          'IN_OFFICE': 'En sucursal',
          'DISPATCHABLE': 'Envio a cliente',
          'SAT_CLAVE': 'Clave',
          'SAT_UNIDAD': 'Clave unidad',
        },
        'SIDEBAR': {
          'TITLE': 'Editar entes de subir el archivo',
          'FORM': {
            'NAME': 'Nombre',
            'CATEGORY': 'Categoria',
            'PRICE': 'Precio sin iva',
            'DESCRIPTION': 'Descripción',
            'ACTIVE': 'Disponible',
            'DISPATCHABLE': 'A domicilio',
            'IN_OFFICE': 'En sucursal',
            'REQUIREMENTS': 'Requisitos',
            'APPLICATION_TYPE': 'Tipo de aplicación',
            'PRICE_REAL': 'Precio sin iva',
            'PRICE_VENTA': 'Precio con iva',
            'PRICE_PROMOCION': 'Precio promoción',
            'PRICE_RENTA': 'Precio renta',
            'SKU': 'SKU (código de almacenamiento único)',
            'BARCODE': 'Codigo de barras',
            'OFFICE': {
              'PRICE_REAL': 'Costo para sucursal',
              'PRICE_VENTA': 'Precio base para sucursal',
              'PRICE_PROMOCION': 'Precio promoción para sucursal',
              'PRICE_RENTA': 'Precio renta para sucursal',
            },
            'DETAILS': 'Detalles',
            'SHORT_DESCRIPTION': 'Descripción corta',
            'PRICES': 'Precios',
            'PRICES_LIST': 'Listas de precios',
            'UPDATE': 'Actualizar',
            'CANCEL': 'Cancelar',
            'SAT_FIELDS': 'SAT',
            'SAT_CLAVE': 'Clave',
            'SAT_UNIDAD': 'Clave unidad',
            'SAT_CONCEPTO': 'Concepto',
            'SAT_CONCEPTO_UNIDAD': 'Concepto unidad'
          },
        }
      }
    },
    'BANNERS_OFFICE': {
      'ADD': {
        'TITLE': 'Crear nuevo banner'
      },
      'ERROR': {
        'REQUIRED': 'Campo requerido',
        'INVALID': 'Campo invalido'
      },
      'TITLE': 'Banners',
      'SUBTITLE': 'Detalles',
      'SEARCH': 'Buscar',
      'ADD_NEW': 'Agregar nuevo banner',
      'TABLE': {
      },
      'TABS': {
        'BASIC INFO': 'Detalles'
      },
      'FORM': {
        'URL': 'Enlace',
        'BACKGROUND_COLOR': 'Color de fondo',
        'BACKGROUND_IMAGE': 'Imagen de fondo',
        'MESSAGE': 'Mensaje',
        'ALIGN': {
          'VERTICAL': 'Alineación vertical',
          'HORIZONTAL': 'Alineación horizontal',
          'CENTER': 'Centro',
          'TOP': 'Superior',
          'BOTTOM': 'Inferior',
          'LEFT': 'Izquierda',
          'MIDDLE': 'Enmedio',
          'RIGHT': 'Derecha'
        },
        'ACTIVE': 'Activo',
        'DATES': 'Rango de fechas'
      },
      'BANNERS LIST': 'Listado de banners',
      'BANNER CONFIG': 'Configuración de banner',
      'DELETE_TITLE': 'Confirmar borrado',
      'DELETE_MESSAGE': '¿Esta seguro de borrar el banner seleccionado?',
      'DELETE_OK': 'Si',
      'DELETE_CANCEL': 'No',
    },
    'PRICE_LISTS': {
      'ERROR': {
        'REQUIRED': 'Requerido',
        'ZERO_VALUE': 'Precio en cero o valor negativo'
      },
      'TITLE': 'Lista de precios',
      'SUBTITLE': 'Detalles de la lista',
      'SEARCH': 'Busqueda',
      'ADD_NEW': 'Agregar lista',
      'TABLE': {
        'NAME': 'Nombre',
        'ACTIVE': 'Activo',
        'BANNED': 'Bloqueado',
        'ACTIVATE': 'Activar',
        'BAN': 'Bloquear',
        'EDIT': 'Editar',
        'REMOVE': 'Eliminar',
        'VIEW': 'Ver',
        'DELETE_TITLE': 'Confirmar borrado',
        'DELETE_MESSAGE': '¿Esta seguro de borrar la lista seleccionada?<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Si',
        'DELETE_CANCEL': 'No',
        'PRODUCTS': {
          'DELETE_TITLE': 'Confirmar borrado',
          'DELETE_MESSAGE': '¿Esta seguro de borrar el producto de la lista?\n<br><strong><i>{{name}}</i></strong>',
          'DELETE_OK': 'Yes',
          'DELETE_CANCEL': 'No',
        }
      },
      'TABS': {
        'BASIC INFO': 'Detalles',
        'PRODUCTS': 'Productos'
      },
      'FORM': {
        'NAME': 'Nombre',
        'DESCRIPTION': 'Descripción',
        'FILTER': 'Buscar',
        'ACTIVE': 'Activado',
        'PRECIO_REAL': 'Precio sin iva',
        'PRECIO_VENTA': 'Precio con iva',
        'PRECIO_PROMOCION': 'Precio promoción'
      }
    },
    'SERVICES' :{
      'ERROR': {
        'REQUIRED': 'Campo requerido',
        'INVALID': 'valor invalido',
        'SEND_METHOD_ERROR': 'Favor de seleccionar uno de los valores',
        'CANT_ZERO': 'El valor no puede ser menor o igual a cero'
      },
      'TITLE': 'Servicios',
      'SUBTITLE': 'Detalles del servicio',
      'SEARCH': 'Buscar',
      'ADD_NEW': 'Agregar nuevo servicio',
      'TABLE': {
        'NAME': 'Nombre',
        'DESCRIPTION': 'Descripción',
        'PRICE': 'Precio sin iva',
        'PRICE_VENTA': 'Precio con iva',
        'DISPATCHABLE': 'Modalidad',
        'REQUIREMENTS': 'Requisitos',
        'CATEGORY': 'Categoria',
        'ACTIVE': 'Disponible',
        'BANNED': 'Bloqueado',
        'ACTIVATE': 'Activar',
        'BAN': 'Bloquear',
        'EDIT': 'Editar',
        'REMOVE': 'Eliminar',
        'VIEW': 'Ver',
        'DELETE_TITLE': 'Confirmar borrado',
        'DELETE_MESSAGE': '¿Esta seguro de borrar el servicio seleccionado?<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Si',
        'DELETE_CANCEL': 'No',
        'EXPORT': 'Exportar',
        'IMPORT': 'Importar'
      },
      'TABS': {
        'BASIC INFO': 'Detalles',
        'PRICES': 'Precios',
        'INVENTORY': 'Inventario',
        'SAT': 'SAT'
      },
      'FORM': {
        'NAME': 'Nombre',
        'CATEGORY': 'Categoria',
        'PRICE': 'Precio sin iva',
        'DESCRIPTION': 'Descripción',
        'ACTIVE': 'Disponible',
        'DISPATCHABLE': 'A domicilio',
        'IN_OFFICE': 'En sucursal',
        'REQUIREMENTS': 'Requisitos',
        'APPLICATION_TYPE': 'Tipo de aplicación',
        'PRICE_REAL': 'Precio sin iva',
        'PRICE_VENTA': 'Precio con iva',
        'PRICE_PROMOCION': 'Precio promoción',
        'PRICE_RENTA': 'Precio renta',
        'SKU': 'SKU (código de almacenamiento único)',
        'BARCODE': 'Codigo de barras',
        'OFFICE': {
          'PRICE_REAL': 'Costo para sucursal',
          'PRICE_VENTA': 'Precio base para sucursal',
          'PRICE_PROMOCION': 'Precio promoción para sucursal',
          'PRICE_RENTA': 'Precio renta para sucursal',
        },
        'SAT_CLAVE': 'Clave',
        'SAT_UNIDAD': 'Clave unidad',
        'SAT_CONCEPTO': 'Concepto',
        'SAT_CONCEPTO_UNIDAD': 'Concepto unidad'
      },
      'EXPORT': {
        'TEMPLATE': 'Plantilla'
      },
      'IMPORT': {
        'TITLE': 'Importar',
        'SUBTITLE': 'Servicios',
        'SUCCESS_UPLOAD': 'Carga de archivo exitosa',
        'DOWNLOAD_TEMPLATE': 'Descargar plantilla',
        'ERROR': {
          'EMPTY': 'valor vacio',
          'DUPLICATED': 'valor duplicado',
          'NEW_CATEGORY': 'categoria erronea',
          'NEW_APPLICATION_TYPE': 'tipo aplicación erronea',
          'NO_DISPATCHABLE': 'Seleccione una opción'
        },
        'TABLE': {
          'NAME': 'Nombre',
          'PRICES': 'Precios',
          'CATEGORY': 'Categoria',
          'SHIPPING': 'Envios',
          'ACTIVE': 'Activo',
          'BARCODE': 'Barcode',
          'SKU': 'Sku',
          'PRICE_REAL': 'Sin iva',
          'PRICE_VENTA': 'Con iva',
          'PRICE_RENTA': 'Renta',
          'PRICE_PROMOCION': 'Promo',
          'APPLICATION_TYPE': 'Tipo aplicación',
          'TAGS': 'Etiquetas',
          'IN_OFFICE': 'En sucursal',
          'DISPATCHABLE': 'Envio a cliente',
          'SAT_CLAVE': 'Clave',
          'SAT_UNIDAD': 'Clave unidad',
        },
        'SIDEBAR': {
          'TITLE': 'Edit before upload',
          'FORM': {
            'NAME': 'Nombre',
            'CATEGORY': 'Categoria',
            'PRICE': 'Precio sin iva',
            'DESCRIPTION': 'Descripción',
            'ACTIVE': 'Disponible',
            'DISPATCHABLE': 'A domicilio',
            'IN_OFFICE': 'En sucursal',
            'REQUIREMENTS': 'Requisitos',
            'APPLICATION_TYPE': 'Tipo de aplicación',
            'PRICE_REAL': 'Precio con iva',
            'PRICE_VENTA': 'Precio sin iva',
            'PRICE_PROMOCION': 'Precio promoción',
            'PRICE_RENTA': 'Precio renta',
            'SKU': 'SKU (código de almacenamiento único)',
            'BARCODE': 'Codigo de barras',
            'OFFICE': {
              'PRICE_REAL': 'Costo para sucursal',
              'PRICE_VENTA': 'Precio base para sucursal',
              'PRICE_PROMOCION': 'Precio promoción para sucursal',
              'PRICE_RENTA': 'Precio renta para sucursal',
            },
            'DETAILS': 'Detalles',
            'SHORT_DESCRIPTION': 'Descripción corta',
            'PRICES': 'Precios',
            'PRICES_LIST': 'Listas de precios',
            'UPDATE': 'Actualizar',
            'CANCEL': 'Cancelar',
            'SAT_FIELDS': 'SAT',
            'SAT_CLAVE': 'Clave',
            'SAT_UNIDAD': 'Clave unidad',
            'SAT_CONCEPTO': 'Concepto',
            'SAT_CONCEPTO_UNIDAD': 'Concepto unidad'
          },
        }
      }
    },
    'ORDERS': {
      'TITLE': 'Pedidos',
      'SUBTITLE': 'Detalle de la orden',
      'SEARCH': 'Buscar',
      'TABLE': {
        'PAYMENT_METHOD': 'Metodo de pago',
        'TOTAL': 'Total',
        'FROM': 'Desde',
        'CREATED': 'Creado',
        'STATUS': 'Estatus',
        'CANCELED': 'Cancelado',
        'PAID': 'Pagado',
        'PENDING': 'Pendiente',
        'AWAITING': 'Pago pendiente',
        'COMPLETED': 'Completado',
        'DECLINED': 'Declinado',
        'REFUNDED': 'Reembolsado',
        'DISPUTED': 'En disputa',
        'VIEW': 'Ver',
        'REMOVE': 'Eliminar',
        'APPLICANT': 'Solicitante',
        'CHANGE_STATUS': 'Cambiar estatus',
        'REFERENCE': 'Referencia',
        'INVOICE': 'Factura',
        'GENERATE_INVOICE': 'Generar factura',
        'PRICE': 'Precio',
        'QTY': 'Cantidad',
        'SUBTOTAL': 'Subtotal',
        'DISCOUNT': 'Descuento',
        'SEND_INVOICE': 'Enviar factura'
      },
      'DETAILS': {
        'PURCHASE_DETAILS': 'Detalles de la compra',
        'ORDER_STATUS': 'Estatus de la orden',
        'AMOUNT': 'Monto',
        'PAYMENT_METHOD': 'Metodo de pago',
        'CREATION_DATE': 'Fecha de creación',
        'CUSTOMER_DETAILS': 'Detalles del cliente',
        'CUSTOMER_NAME': 'Nombre',
        'CUSTOMER_PHONE': 'Telefono',
        'CUSTOMER_EMAIL': 'Email',
        'PAYMENT_DETAILS': 'Detalles de pago',
        'PAYMENT_AMOUNT': 'Monto',
        'PAYMENT_CARD': 'Tarjeta',
        'PAYMENT_CODE': 'Codigo Autorización',
        'PAYMENT_INVOICE': 'Factura',
        'PAYMENT_OPEN_INVOICE': 'Abir en ventana',
        'PAYMENT_EXPIRED': 'EXPIRADO',
        'PAYMENT_PAID': 'PAGADO',
      },
      'TABS': {
        'DETAILS': 'Detalles',
        'CONTENT': 'Contenido'
      },
      'INVOICE': {
        'MODAL': {
          'TITLE': 'Factura',
          'CLOSE': 'Cerrar'
        }
      },
      'GENERATE_INVOICE': {
        'MODAL': {
          'TITLE': 'Generar factura',
          'FORM': {
            'RFCS': 'Datos de facturas guardados',
            'RFC': 'RFC',
            'RAZON_SOCIAL': 'Razon Social',
            'CFDI': 'CFDI',
            'EMAIL': 'Correo electrónico'
          },
          'CLOSE': 'Cerrar',
          'GENERATE': 'Generar'
        }
      }
    },
    'CALENDAR': {
      'TITLE': 'Calendario',
      'SEARCH': 'Buscar',
      'TODAY': 'Hoy',
      'DAY': 'Dia',
      'WEEK': 'Semana',
      'MONTH': 'Mes',
      'CATEGORIES': 'Categorias',
      'TABS': {
        'SCHEDULE': 'Agenda'
      },
      'SIDEBAR': {
        'TITLE': 'Detalles de cita',
        'CLIENT': 'Cliente',
        'APPOINTMENT': 'Cita',
        'DISPATCHABLE': 'Envio',
        'IN_OFFICE': 'En sucursal',
        'ORDER': 'Pedido',
        'CANCELED': 'Cancelado',
        'PAID': 'Pagado',
        'PENDING': 'Pendiente',
        'AWAITING': 'Pago pendiente',
        'COMPLETED': 'Completado',
        'DECLINED': 'Declinado',
        'REFUNDED': 'Reembolsado',
        'DISPUTED': 'En disputa',
        'RELATED_APPOINTMENTS': 'Citas relacionadas',
        'SCHEDULED': 'Agendado',
        'NO SHOW': 'No llego',
        'PENDING APPROVAL': 'Pendiente de aprovación',
        'REQUEST EXPIRED': 'Petición expirada',
        'NEW TIME PROPOSED': 'Reagenda propuesta',
        'PENDING RESCHEDULE': 'Reagenda pendiente',
        'PROVIDER': 'Medico asignado',
        'FORM': {
          'COMMENTS': 'Comentarios',
          'UPDATE': 'Actualizar',
          'CANCEL': 'Cancelar',
          'BACK': 'Cerrar',
          'STATUS': 'Estatus cita',
          'WAITING_UPLOAD': 'Preparado',
          'ARCHIVED': 'Archivado'
        }
      },
      'MODAL': {
        'NEW_APPOINTMENT': {
          'TITLE': 'Detalles de la cita',
          'START_DATE': 'Fecha Inicio',
          'START_TIME': 'Hora Inicio',
          'END_DATE': 'Fecha Fin',
          'END_TIME': 'Hora Fin',
          'CLIENT': 'Cliente',
          'STATUS_DATE': 'Estatus cita',
          'COMMENTS': 'Comentarios',
          'OFFICE_BRANCH': 'Sucursal',
          'PROVIDER': 'Medico asignado'
        }
      }
    },
    'COUPONS': {
      'TITLE': 'Cupones',
      'SEARCH': 'Busqueda',
      'ADD_NEW': 'Crear cupon',
      'SUBTITLE': 'Coupon details',
      'FORM': {
        'NAME': 'Nombre',
        'CODE': 'Codigo',
        'DISCOUNT_TYPE': 'Tipo de descuento',
        'TYPE': 'Tipo',
        'VALUE': 'Valor descuento',
        'EXPIRATION': 'Periodo expiración',
        'QTY': 'Uso maximo',
        'ACTIVE': 'Activado',
        'BY_PRODUCTS': 'Activo para productos',
        'BY_CART': 'Activo para carro de compras',
        'FILTER': 'Search product'
      },
      'ERROR': {
        'REQUIRED': 'Requerido',
        'LOWER_PERCENT': 'el valor es menor al 1%'
      },
      'TABS': {
        'BASIC INFO': 'Detalles',
        'MODALITY': 'Modalidad',
        'PRODUCTS': 'Productos'
      },
      'TABLE': {
        'NAME': 'Nombre',
        'CODE': 'Codigo',
        'DISCOUNT_TYPE': 'Descuento',
        'TYPE': 'Tipo de descuento',
        'EXPIRATION': 'Periodo expiración',
        'QTY': 'Uso maximo',
        'ACTIVE': 'Activar',
        'BANNED': 'Bloquear',
        'ACTIVATE': 'Activado',
        'BAN': 'Bloqueado',
        'EDIT': 'Editar',
        'REMOVE': 'Eliminar',
        'VIEW': 'Ver',
        'DELETE_TITLE': 'Confirmar borrado',
        'DELETE_MESSAGE': '¿Esta seguro de borrar el cupon seleccionado?<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Si',
        'DELETE_CANCEL': 'No',
        'START': 'Inicio',
        'END': 'Fin',
        'USED': 'Usados',
        'PRODUCTS': {
          'DELETE_TITLE': 'Confirmar borrado',
          'DELETE_MESSAGE': '¿Esta seguro de borrar el producto de la lista?\n<br><strong><i>{{name}}</i></strong>',
          'DELETE_OK': 'Yes',
          'DELETE_CANCEL': 'No',
        }
      }
    },
    'APPOINTMENTS': {
      'TITLE': 'Citas',
      'SEARCH': 'Buscar',
      'ADD_NEW': 'Crear nueva cita',
      'TABLE': {
        'DATE': 'Fecha y hora',
        'CLIENT': 'Cliente',
        'STATUS': 'Estatus',
        'NOTES': 'Comentarios',
        'EDIT': 'Editar',
        'VIEW': 'Ver detalles',
        'DISPATCHABLE': 'Envio',
        'IN_OFFICE': 'En sucursal',
        'ORDER': 'Pedido',
        'CANCELED': 'Cancelado',
        'PAID': 'Pagado',
        'PENDING': 'Pendiente',
        'AWAITING': 'Pago pendiente',
        'COMPLETED': 'Completado',
        'DECLINED': 'Declinado',
        'REFUNDED': 'Reembolsado',
        'DISPUTED': 'En disputa',
        'RELATED_APPOINTMENTS': 'Citas relacionadas',
        'SCHEDULED': 'Agendado',
        'NO SHOW': 'No llego',
        'PENDING APPROVAL': 'Pendiente de aprovación',
        'REQUEST EXPIRED': 'Petición expirada',
        'NEW TIME PROPOSED': 'Reagenda propuesta',
        'PENDING RESCHEDULE': 'Reagenda pendiente',
        'SERVICE': 'Servicio',
        'PHONE': 'Teléfono',
        'LOCATION': 'Ubicación',
        'OFFICE': 'Sucursal'
      }
    },
    'SETTINGS': {
      'TITLE': 'Configuración',
      'ADD_NEW_CHAT': 'Agregar respuesta ',
      'TABS': {
        'BASIC': 'Configuración general',
        'CHATBOT': 'Chatbot',
        'CALENDAR': 'Calendario',
        'API': 'Integraciones'
      },
      'ERRORS': {
        'REQUIRED': 'Requerido',
        'LOWER_PERCENT': 'el valor es menor al 1%',
        'SAVE': 'Por favor, intente mas tarde',
        'NO_LOAD': 'No se pudo mostrar la página'
      },
      'TABLE': {
        'MESSAGE': 'Mensaje esperado',
        'RESPONSE': 'Respuesta del bot',
        'ACTIVE': 'Activar',
        'BANNED': 'Bloquear',
        'ACTIVATE': 'Activado',
        'BAN': 'Bloqueado',
        'EDIT': 'Editar',
        'REMOVE': 'Eliminar',
        'VIEW': 'Ver',
      },
      'FORM': {
        'GENARAL': {
          'NAME': 'Nombre de la empresa'
        },
        'TITLE': 'Configuración Chatbot',
        'MESSAGE': 'Mensaje esperado',
        'RESPONSE': 'Respuesta del bot',
        'DEACTIVED': 'Desactivado',
        'ACTIVED': 'Activado',
        'RESPONSE_OFFICES': 'Lista de sucursales (max. 10)\n• Sucursal 1\n• Sucursal 2',
        'RESPONSE_AGENT': 'Un agente te atenderá en unos minutos.',
        'SAVE': 'Guardar',
        'UPDATE': 'Actualizar',
        'DELETE': 'Eliminar',
        'CANCEL': 'Cancelar',
        'CHIPS': {
          'USERNAME': 'Nombre del cliente',
          'PRODUCTS': 'Buscar productos',
          'SERVICES': 'Buscar servicios',
          'OFFICES': 'Lista de Sucursales',
          'URL': 'Liga tienda en línea',
          'NOTIFY_AGENT': 'Notificar al agente',
          'FOLIO': 'Folio',
          'RESPONSE_VARIABLES': 'Variables para usar en respuestas',
          'REQUEST_VARIABLES': 'Variables para uso en webhooks'
        },
        'NAME': 'Nombre empresa',
        'LOGO': 'Logo',
        'ICON': 'Icono',
        'STORE_LINK': 'Liga de tienda en linea',
        'CURRENCY': 'Divisa',
        'PAYMENTS_METHOD': 'Proveedor de pagos en línea',
        'CONEKTA_PUBLIC_KEY': 'Llave publica de Conekta',
        'CONEKTA_PRIVATE_KEY': 'Llave privada de Conekta',
        'CONEKTA_OXXO_EXPIRATION': 'Expiración del pago en OXXO',
        'CONEKTA_OXXO_MINUTES': 'minutos',
        'CONEKTA_OXXO_DEFAULT_MINUTES': 'Default 60 minutos',
        'INVOICE_PROVIDER': 'Proveedor de facturas',
        'FACTURAMA_USER': 'Usuario Facturama',
        'FACTURAMA_PASSWORD': 'Contraseña Facturama',
        'FACTURAMA_MODE': 'Ambiente producción',
        'FACTURAMA_MODE_LABEL': 'Producción',
        'WHATSAPP_PROVIDER': 'Proveedor de mensajes de Whatsapp',
        'TWILIO_ACCOUNT_SID': 'SID de cuenta',
        'TWILIO_AUTH_TOKEN': 'Token de autentificación',
        'TWILIO_PHONE_FROM': 'No. telefónico desde donde se envia',
        'GOOGLE_API_DISCLAIMER': 'Llave API de los servicios de Google',
        'GOOGLE_API': 'Google API key',
        'GOOGLE_FIREBASE': 'Google Base de datos Firebase (Base de datos en tiempo real)',
        'PAYPAL_API_DISCLAIMER': 'Integración de Paypal API',
        'PAYPAL_CLIENT': 'Llave Paypal API',
        'EMAIL': 'Correo Electronico',
        'PHONE': 'Telefono',
        'CP': 'Codigo postal',
        'ADDRESS': 'Calle y numero',
        'CITY': 'Ciudad',
        'STATE': 'Estado',
        'COUNTRY': 'País',
        'USE_WEBHOOK': 'Usar webhook',
        'WEBHOOK_URL': 'Liga del Webhook',
        'WEBHOOK_METHOD': 'Metodo del Webhook'
      },
      'MODAL': {
        'DELETE_TITLE': 'Confirmar borrado',
        'DELETE_MESSAGE': '¿Esta seguro de borrar la pregunta para el chatbot?<br><strong><i>{{name}}</i></strong>',
        'DELETE_OK': 'Si',
        'DELETE_CANCEL': 'No'
      },
      'GENERAL': {
        'TITLE': 'Configuración general'
      },
      'CHATBOT': {
        'TITLE': 'Configuración del chatbot',
        'TABS': {
          'BASIC': 'Información general',
          'MESSAGES': 'Mensajes'
        },
        'FORM': {
          'DEFAULT_RESPONSE': 'Respuesta predeterminada',
          'DISABLE_TIME': 'Duración que permanecerá deshabilitado el bot'
        }
      },
      'APIS': {
        'TITLE': 'Integración de API\'s'
      },
      'MAIL': {
        'TITLE': 'Configuración del servidor de correos electrónicos',
        'FORM': {
          'HOST': 'Host (Ip o nombre del dominio)',
          'PORT': 'Puerto',
          'ENCRYPTION': 'Encriptación',
          'USERNAME': 'Nombre de usuario',
          'PASSWORD': 'Contraseña',
          'MAILER': 'Mail driver',
          'SENDER': 'Correo que envia'
        },
        'TEST_MAIL_SERVER': 'Probar configuración',
        'MODAL': {
          'TITLE': 'Prueba de servidor de correos',
          'EMAIL': 'Correo electrónico',
          'SEND': 'Enviar'
        }
      },
      'THEME': {
        'TITLE': 'Apariencia',
        'FORM': {
          'PUBLISH': 'Publicar',
          'DELETE': 'Borrar',
          'SAVE': 'Guardar',
          'NAME': 'Nombre',
          'CANCEL': 'Cancelar',
          'EDIT': 'Editar',
          'CLAVE': 'Nombre clave',
          'CLAVE_OPTIONAL': 'Opcional',
          'UPDATE': 'Actualizar'
        },
        'MODAL': {
          'DELETE_MESSAGE': 'La información ya no se podrá recuperar',
          'DELETE_OK': 'Borrar',
          'DELETE_TITLE': 'Borrar archivos del tema',
          'DELETE_CANCEL': 'Cancelar'
        },
        'TABS': {
          'THEMES': 'Templates',
          'SETTINGS': 'Configuración'
        },
        'CHOOSE_FILE': 'Elige el archivo en formato zip',
        'OR_DRAG_HERE': 'o arrastrarlo aqui',
        'SENDING_FILE': 'Enviando archivo'
      }
    },
    'TOOLBAR': {
      'USER': {
        'ACCOUNT': 'Mi cuenta',
        'INBOX': 'Mensajes',
        'LOGOUT': 'Cerrar sesion'
      },
      'NOTIFICATIONS': {
        'TITLE': 'Notificaciones',
        'MARK_ALL': 'Marcar todo como leído',
        'MARK_READ': 'Marcar como leído',
        'MARK_UNREAD': 'Marcar como no leído',
        'REMOVE': 'Eliminar',
        'REMOVE_MESSAGE': '¿Esta seguro de eliminar esta notificación?',
        'REMOVE_YES': 'Si',
        'REMOVE_NO': 'No'
      }
    },
    'MESSAGES': {
      'TOOLBAR': {
        'MENU': {
          'PROFILE': 'Perfil',
          'LOGOUT': 'Cerrar sesion'
        },
        'SEARCH': {
          'PLACEHOLDER': 'Buscar en un mensaje o un cliente'
        }
      },
      'CHATS': {
        'TITLE': 'Mensajes',
        'ITEM': {
          'FILE': 'Archivo'
        },
        'CHAT_CENTER': 'Centro de mensajes',
        'CHOOSE_A_CHAT': '¡Seleccione un contacto para abrir un chat!',
        'CONTACT_INFO': 'Información de contacto',
        'TYPE_A_MESSAGE': 'Escribe tu mensaje',
        'DISABLE_BOT': 'Desactivar Chatbot',
        'ENABLE_BOT': 'Activar Chatbot',
        'CHOOSE_FILE': 'Elija un archivo',
        'OR_DRAG_HERE': 'o arrástrelo y sueltelo aquí.',
        'SENDING_FILE': 'Enviando archivo',
        'DISABLED_CHATBOT': 'Desactivar el chatbot para el cliente {{client}}',
        'ENABLED_CHATBOT': 'Activar el chatbot para el cliente {{client}}',
        'SEND_FILE': 'Enviar archivos',
        'REGISTER_CLIENT': 'Registrar cliente',
        'OPEN_GALLERY': 'Abrir vista previa de archivos',
        'ERRORS': {
          'INVALID_FORMAT': 'Formato de archivo no valido {{type}}',
          'INVALID_SIZE': 'El archivo ({{size1}}) excede el tamaño maximo permitido({{size2}})'
        }
      }
    },
    'FORMS': {
      'TITLE': 'Formularios',
      'SUBTITLE': 'Crea formularios para poder enviar a sus clientes',
      'ADD_NEW': 'Agregar nuevo formulario',
      'TABLE': {
        'NAME': 'Nombre',
        'FIELDS': 'Campos',
        'ACTIVE': 'Activo',
        'BANNED': 'Desactivado',
        'BAN': 'Desactivar',
        'ACTIVATE': 'Activar',
        'EDIT': 'Editar',
        'VIEW': 'Ver',
        'REMOVE': 'Eliminar'
      },
      'TABS': {
        'BASIC INFO': 'Detalles',
        'FIELDS': 'Construye tu formulario'
      },
      'FORM': {
        'NAME': 'Nombre',
        'ACTIVE': {
          'ACTIVED': 'Activo',
          'DEACTIVED': 'Inactivo'
        },
        'ADD_STEP': 'Agregar plantilla'
      }
    }
  }
};
