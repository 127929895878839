
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Product, User, Response, Active } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';

import * as _ from 'lodash';

@Injectable()
export class ProductsService {

  constructor(private http: HttpClient) {}

  get(id: Number, isService?: boolean): Observable<Response<Product>> {
    return  id > 0 ? this.http.get<Response<Product>>(new ServerApiPipe().transform(`products/${id}`)) : of({
      status: 'success',
      success: true,
      message: '',
      data: {
        name: '',
        description: '',
        short_description: '',
        product_type_id: isService ? 2 : 1,
        precio_real: 0,
        precio_venta: 0,
        precio_promocion: 0,
        precio_renta: 0,
        product_category_id: 0,
        product_application_type_id: 0,
        dispatchable: false,
        in_office: false,
        tags: [],
        active: false,
        barcode: '',
        sku: '',
        sat_clave: '',
        sat_unidad: '',
        sat_concepto: '',
        sat_concepto_unidad: '',
        requirements: []
      } as Product
    } as Response<Product>);
  }

  all(body: any = {}): Observable<Response<Product[]>> {
    let params = new HttpParams().set("s", body.s || '');

    Object.keys(body).forEach(p => {
      params = params.set(p, body[p] || '');
    });

    return this.http.get<Response<Product[]>>(new ServerApiPipe().transform(`products`), { params });
  }

  delete(product: Product): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`products/${product.id}`));
  }

  update(product: Product): Observable<Response<Product>> {

    const formData: FormData = new FormData();

    Object.keys(product).forEach((k) => {
      if (!Array.isArray(product[k]) && product[k] !== '' && product[k] !== null) {
        formData.append(k, product[k]);
      } else {

      }
    });

    try {
      const _images = _.filter(product.images, { file: true }) || [];
      if (_images.length > 0) {
        _.each(_images, (i) => {
          formData.append('postedFiles[]', i._file, i._file.name);
        });
      }
    } catch(e) { }

    if (product.tags?.length > 0) {
      formData.append('tags', JSON.stringify(product.tags));
    } else {
      formData.append('tags', '[]');
    }

    const _noPostImages = _.filter(product.images, (i) =>  i.file !== true) || [];

    if (_noPostImages.length > 0) {
      formData.append('images', JSON.stringify(_noPostImages));
    } else {
      formData.append('images', '[]');
    }

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<Product>>(new ServerApiPipe().transform(`products/${product.id}?_method=PUT`), formData, { headers });
  }

  save(product: Product): Observable<Response<Product>> {

    const formData: FormData = new FormData();

    Object.keys(product).forEach((k) => {
      if (!Array.isArray(product[k]) && product[k] !== '' && product[k] !== null) {
        formData.append(k, product[k]);
      } else {

      }
    });

    try {
      const _images = _.filter(product.images, { file: true }) || [];
      if (_images.length > 0) {
        _.each(_images, (i) => {
          formData.append('postedFiles[]', i._file, i._file.name);
        });
      }
    } catch(e) { }

    if (product.tags?.length > 0) {
      formData.append('tags', JSON.stringify(product.tags));
    } else {
      formData.append('tags', '[]');
    }
    
    formData.append('images', '[]');

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<Product>>(new ServerApiPipe().transform(`products`), formData, { headers });
  }

  list(product: string): Observable<Response<Product[]>> {
    let params = new HttpParams().set("s", product || '');

    return this.http.get<Response<Product[]>>(new ServerApiPipe().transform(`products/list`), { params });
  }

  exports(body = {}): Observable<ArrayBuffer> {
    let params = new HttpParams();

    Object.keys(body).forEach(p => {
      params = params.set(p, body[p] || '');
    });

    const httpOptions = {
      responseType: 'blob' as 'json',
      params: params
    };

    return this.http.get<ArrayBuffer>(new ServerApiPipe().transform(`products/export`), httpOptions);
  }
}
