import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { User, Response, Active, Message, ChatItem } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';
import { FuseUtils } from '@fuse/utils';

@Injectable()
export class MessagesService {

  onChatSelected: BehaviorSubject<any>;
  onContactSelected: BehaviorSubject<any>;
  onContactUpdated: BehaviorSubject<any>;

  constructor(private http: HttpClient) {
    // Set the defaults
    this.onChatSelected = new BehaviorSubject(null);
    this.onContactSelected = new BehaviorSubject(null);
    this.onContactUpdated = new BehaviorSubject(null);
  }

  list(body: any = {}): Observable<Response<ChatItem[]>> {
    let params = new HttpParams();

    Object.keys(body).forEach(p => {
      params = params.set(p, body[p]);
    });

    return this.http.post<Response<ChatItem[]>>(new ServerApiPipe().transform(`messages/list`), params);
  }

  get(id):Observable<Response<Message[]>> {
    return this.http.get<Response<Message[]>>(new ServerApiPipe().transform(`messages/${id}`));
  }
}
