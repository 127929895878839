<div class="product-details-sidebar-content" fusePerfectScrollbar *ngIf="product">
  <ngx-spinner name="product_import_loading" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium" bdColor="rgba(51, 51, 51, 0.5)"></ngx-spinner>
  <div class="header mb-16">
    <span class="title">
      {{ 'PRODUCTS.IMPORT.SIDEBAR.TITLE' | translate }}
    </span>
    
    <button mat-icon-button class="close-button" (click)="toggleSidebarOpen('ProductImportDetails')" style="margin-left: auto;">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form [formGroup]="form" *ngIf="form">
    <div class="group">
      <h2>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.DETAILS' | translate }}</h2>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.NAME' | translate }}</mat-label>
        <input name="name" formControlName="name" matInput required>
        <mat-error *ngIf="form.get('name').hasError('required')">
          {{ 'PRODUCTS.ERROR.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>

      <div fxLayout="row" fxLayoutGap="5px">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.SKU' | translate }}</mat-label>
          <input name="sku" formControlName="sku" matInput required>
          <mat-error *ngIf="form.get('sku').hasError('required')">
            {{ 'PRODUCTS.ERROR.REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.BARCODE' | translate }}</mat-label>
          <input name="barcode" formControlName="barcode" matInput>
          <mat-error *ngIf="form.get('barcode').hasError('required')">
            {{ 'PRODUCTS.ERROR.REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.DESCRIPTION' | translate }}</mat-label>
        <textarea matInput name="description" formControlName="description" rows="3" [maxLength]="150">
        </textarea>
        <mat-hint align="end">{{ form.get('description').value.length || 0}}/300</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.SHORT_DESCRIPTION' | translate }}</mat-label>
        <textarea matInput name="short_description" formControlName="short_description" rows="3" [maxLength]="150">
        </textarea>
        <mat-hint align="end">{{ form.get('short_description').value.length || 0}}/150</mat-hint>
      </mat-form-field>

      <div fxLayout="row" fxLayoutGap="5px">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.CATEGORY' | translate }}</mat-label>
          <mat-select formControlName="category" required [errorStateMatcher]="category_matcher">
            <mat-option [value]="">&nbsp;</mat-option>
            <mat-option [value]="category.parse" *ngFor="let category of categories">
              {{ category.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('category').hasError('required') || form.errors?.category_empty">
            {{ 'PRODUCTS.ERROR.REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.APPLICATION_TYPE' | translate }}</mat-label>
          <mat-select formControlName="application_type" required [errorStateMatcher]="application_type_matcher">
            <mat-option [value]="">&nbsp;</mat-option>
            <mat-option [value]="app_type.parse" *ngFor="let app_type of application_types">
              {{ app_type.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('application_type').hasError('required') || form.errors?.application_empty">
            {{ 'PRODUCTS.ERROR.REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="5px">
        <ul class="send-method-list" fxFlex [class.red-fg]="form.hasError('sendMethodError')">
          <li>
            <mat-checkbox color="primary" formControlName="dispatchable" >{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.DISPATCHABLE' | translate }}</mat-checkbox>
          </li>
          <li>
            <mat-checkbox color="primary" formControlName="in_office" >{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.IN_OFFICE' | translate }}</mat-checkbox>
          </li>
          <li *ngIf="form.hasError('sendMethodError')">
            <mat-error>
              {{ 'PRODUCTS.ERROR.SEND_METHOD_ERROR' | translate }}
            </mat-error>
          </li>
        </ul>

        <mat-checkbox color="primary" formControlName="active" class="" fxFlex>
          {{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.ACTIVE' | translate }}
        </mat-checkbox>
      </div>
    </div>


    <div class="group">
      <h2>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.PRICES' | translate }}</h2>

      <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.PRICE_REAL' | translate }}</mat-label>
        <input currencyMask name="precio_base" formControlName="precio_base" matInput required [errorStateMatcher]="price_matcher">
        <mat-error *ngIf="form.get('precio_base').hasError('required')">
          {{ 'PRODUCTS.ERROR.REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="form.hasError('price_zero')">
          {{ 'PRODUCTS.ERROR.CANT_ZERO' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.PRICE_VENTA' | translate }}</mat-label>
        <input currencyMask name="precio_venta" formControlName="precio_venta" matInput required [errorStateMatcher]="price_matcher">
        <mat-error *ngIf="form.get('precio_venta').hasError('required')">
          {{ 'PRODUCTS.ERROR.REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="form.hasError('price_zero')">
          {{ 'PRODUCTS.ERROR.CANT_ZERO' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.PRICE_RENTA' | translate }}</mat-label>
        <input currencyMask name="precio_renta" formControlName="precio_renta" matInput>
        <mat-error *ngIf="form.get('precio_renta').hasError('required')">
          {{ 'PRODUCTS.ERROR.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.PRICE_PROMOCION' | translate }}</mat-label>
        <input currencyMask name="precio_promocion" formControlName="precio_promocion" matInput>
        <mat-error *ngIf="form.get('precio_promocion').hasError('required')">
          {{ 'PRODUCTS.ERROR.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="group">
      <h2>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.SAT_FIELDS' | translate }}</h2>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.SAT_CLAVE' | translate }}</mat-label>
        <input name="sat_clave" formControlName="sat_clave" matInput required>
        <mat-error *ngIf="form.get('name').hasError('required')">
          {{ 'PRODUCTS.ERROR.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.SAT_UNIDAD' | translate }}</mat-label>
        <input name="sat_unidad" formControlName="sat_unidad" matInput required>
        <mat-error *ngIf="form.get('name').hasError('required')">
          {{ 'PRODUCTS.ERROR.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.SAT_CONCEPTO' | translate }}</mat-label>
        <input name="sat_concepto" formControlName="sat_concepto" matInput required>
        <mat-error *ngIf="form.get('name').hasError('required')">
          {{ 'PRODUCTS.ERROR.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.SAT_CONCEPTO_UNIDAD' | translate }}</mat-label>
        <input name="sat_concepto_unidad" formControlName="sat_concepto_unidad" matInput required>
        <mat-error *ngIf="form.get('name').hasError('required')">
          {{ 'PRODUCTS.ERROR.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="group" *ngIf="price_list.length > 0">
      <h2>{{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.PRICES_LIST' | translate }}</h2>
      <ng-container *ngFor="let _lista of price_list">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{ _lista.name }} {{ 'PRODUCTS.IMPORT.TABLE.PRICE_REAL' | translate }}</mat-label>
          <input currencyMask name="{{ _lista.column }}" formControlName="{{ _lista.column }}" matInput>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{ _lista.name }} {{ 'PRODUCTS.IMPORT.TABLE.PRICE_VENTA' | translate }}</mat-label>
          <input currencyMask name="{{ _lista.column+'_iva' }}" formControlName="{{ _lista.column+'_iva' }}" matInput>
        </mat-form-field>
      </ng-container>
    </div>

    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between flex-start">
      <button mat-stroked-button class="mr-4 fuse-white-bg" (click)="toggleSidebarOpen('ProductImportDetails')">
        <mat-icon>arrow_back</mat-icon>
        {{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.CANCEL' | translate }}
      </button>
      <button mat-raised-button color="accent" (click)="updateRecord()" [disabled]="form.invalid">
        <mat-icon>save</mat-icon>
        {{ 'PRODUCTS.IMPORT.SIDEBAR.FORM.UPDATE' | translate }}
      </button>
    </div>
    <!-- 
      "Etiquetas": 'tags',
      "Requisitos": 'requirements' 
    -->
  </form>
</div>