
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { ApplicationType, User, Response, Active } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';

@Injectable()
export class ApplicationTypesService {

  constructor(private http: HttpClient) {}

  all(body: any = {}): Observable<Response<ApplicationType[]>> {
    let params = new HttpParams().set("s", body.s || '');

    return this.http.get<Response<ApplicationType[]>>(new ServerApiPipe().transform(`application_types`), { params });
  }
}
