<div class="file-details-details-panel" fusePerfectScrollbar>
  
  <div class="header">
    <span class="title"></span>

    <button mat-icon-button class="close-button" (click)="toggleSidebarOpen('FileDetails')" style="margin-left: auto;">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form [formGroup]="form" *ngIf="file">
    <ng-container *ngIf="!canEdit">
      <ng-container *ngIf="isImage">
        <div class="file-container">
          <img class="" src="{{ file.path | server_file }}">
        </div>
        <div class="file-name">
          <mat-icon fontSet="material-icons-outlined">image</mat-icon>
          {{ file.name }}
        </div>
        <div class="file-extension">
          {{ file | extension }}
        </div>
        <div class="file-size">
          <span class="file-size-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.SIZE' | translate }}</span>
          <span class="">{{ file.size | bytes : 2 }}</span>
        </div>
        <div class="file-updated">
          <span class="file-updated-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.LAST_MODIFIED' | translate }}</span>
          <span class="">{{ file.updated_at | dateFormat:'YYYY-MM-DD hh:mm a' }}</span>
        </div>
        <div class="file-path">
          <span class="file-path-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.FOLDER' | translate }}</span>
          <span class="">{{ file.folder }}</span>
        </div>
        <div class="file-tags">
          <span class="file-tags-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.TAGS' | translate }}</span>
          <span class="" *ngIf="tags.length > 0">#{{ tags.join(' #') }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="isAudio">
        <div class="file-container isAudio">
          <ngx-audio-player #musicPlayer [playlist]="msaapPlaylist" [displayTitle]="false" [autoPlay]="false"
              [displayPlaylist]="false" [expanded]="false" [displayVolumeControls]="true" 
              [displayRepeatControls]="false"></ngx-audio-player>
        </div>
        <div class="file-name">
          <mat-icon fontSet="material-icons-outlined">image</mat-icon>
          {{ file.name }}
        </div>
        <div class="file-extension">
          {{ file | extension }}
        </div>
        <div class="file-size">
          <span class="file-size-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.SIZE' | translate }}</span>
          <span class="">{{ file.size | bytes : 2 }}</span>
        </div>
        <div class="file-updated">
          <span class="file-updated-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.LAST_MODIFIED' | translate }}</span>
          <span class="">{{ file.updated_at | dateFormat:'YYYY-MM-DD hh:mm a' }}</span>
        </div>
        <div class="file-path">
          <span class="file-path-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.FOLDER' | translate }}</span>
          <span class="">{{ file.folder }}</span>
        </div>
        <div class="file-tags">
          <span class="file-tags-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.TAGS' | translate }}</span>
          <span class="" *ngIf="tags.length > 0">#{{ tags.join(' #') }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="isVideo">
        <div class="file-container isVideo">
          <video [vgMedia]="media" #media id="singleVideo" preload="auto" controls>
            <source src="{{ file.path | server_file }}" type="video/mp4">
          </video>
        </div>
        <div class="file-name">
          <mat-icon fontSet="material-icons-outlined">image</mat-icon>
          {{ file.name }}
        </div>
        <div class="file-extension">
          {{ file | extension }}
        </div>
        <div class="file-size">
          <span class="file-size-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.SIZE' | translate }}</span>
          <span class="">{{ file.size | bytes : 2 }}</span>
        </div>
        <div class="file-updated">
          <span class="file-updated-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.LAST_MODIFIED' | translate }}</span>
          <span class="">{{ file.updated_at | dateFormat:'YYYY-MM-DD hh:mm a' }}</span>
        </div>
        <div class="file-path">
          <span class="file-path-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.FOLDER' | translate }}</span>
          <span class="">{{ file.folder }}</span>
        </div>
        <div class="file-tags">
          <span class="file-tags-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.TAGS' | translate }}</span>
          <span class="" *ngIf="tags.length > 0">#{{ tags.join(' #') }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="isDocument">
        <div class="file-container isDocument">
          <iframe [src]="pdfUrl"></iframe>
        </div>
        <div class="file-name">
          <mat-icon fontSet="material-icons-outlined">image</mat-icon>
          {{ file.name }}
        </div>
        <div class="file-extension">
          {{ file | extension }}
        </div>
        <div class="file-size">
          <span class="file-size-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.SIZE' | translate }}</span>
          <span class="">{{ file.size | bytes : 2 }}</span>
        </div>
        <div class="file-updated">
          <span class="file-updated-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.LAST_MODIFIED' | translate }}</span>
          <span class="">{{ file.updated_at | dateFormat:'YYYY-MM-DD hh:mm a' }}</span>
        </div>
        <div class="file-path">
          <span class="file-path-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.FOLDER' | translate }}</span>
          <span class="">{{ file.folder }}</span>
        </div>
        <div class="file-tags">
          <span class="file-tags-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.TAGS' | translate }}</span>
          <span class="" *ngIf="tags.length > 0">#{{ tags.join(' #') }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="isOtherFile">
        <div class="file-container">
          <mat-icon fontSet="material-icons-outlined">description</mat-icon>
        </div>
        <div class="file-name">
          <mat-icon fontSet="material-icons-outlined">image</mat-icon>
          {{ file.name }}
        </div>
        <div class="file-extension">
          {{ file | extension }}
        </div>
        <div class="file-size">
          <span class="file-size-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.SIZE' | translate }}</span>
          <span class="">{{ file.size | bytes : 2 }}</span>
        </div>
        <div class="file-updated">
          <span class="file-updated-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.LAST_MODIFIED' | translate }}</span>
          <span class="">{{ file.updated_at | dateFormat:'YYYY-MM-DD hh:mm a' }}</span>
        </div>
        <div class="file-path">
          <span class="file-path-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.FOLDER' | translate }}</span>
          <span class="">{{ file.folder }}</span>
        </div>
        <div class="file-tags">
          <span class="file-tags-label">{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.TAGS' | translate }}</span>
          <span class="" *ngIf="tags.length > 0">#{{ tags.join(' #') }}</span>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="canEdit">
      <ng-container *ngIf="isImage">
        <div class="file-container">
          <img class="" src="{{ file.path | server_file }}">
        </div>
      </ng-container>
      <ng-container *ngIf="isAudio">
        <div class="file-container isAudio">
          <ngx-audio-player #musicPlayer [playlist]="msaapPlaylist" [displayTitle]="false" [autoPlay]="false"
              [displayPlaylist]="false" [expanded]="false" [displayVolumeControls]="true" 
              [displayRepeatControls]="false"></ngx-audio-player>
        </div>
      </ng-container>
      <ng-container *ngIf="isVideo">
        <div class="file-container isVideo">
          <video [vgMedia]="media" #media id="singleVideo" preload="auto" controls>
            <source src="{{ file.path | server_file }}" type="video/mp4">
          </video>
        </div>
      </ng-container>
      <ng-container *ngIf="isDocument">
        <div class="file-container isDocument">
          <iframe [src]="pdfUrl"></iframe>
        </div>
      </ng-container>
      <ng-container *ngIf="isOtherFile">
        <div class="file-container">
          <mat-icon fontSet="material-icons-outlined">description</mat-icon>
        </div>
      </ng-container>

      <div class="group">
        <h2>{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.DETAILS' | translate }}</h2>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.NAME' | translate }}</mat-label>
          <input name="name" formControlName="name" matInput required>
          <span matSuffix>{{ file | extension }}</span>
          <mat-error *ngIf="form.get('name').hasError('required')">
            {{ 'PRODUCTS.ERROR.REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
          <mat-label>{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.TAGS' | translate }}</mat-label>
          <mat-chip-list #tagList name="tags" formControlName="tags">
            <mat-chip *ngFor="let tag of tags" [removable]="true" (removed)="removeTag(tag)">
              {{ tag }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input [matChipInputFor]="tagList"
                   [matChipInputAddOnBlur]="true"
                   (matChipInputTokenEnd)="addTag($event)"/>
          </mat-chip-list>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.SIZE' | translate }}</mat-label>
          <input name="size" formControlName="size" matInput readonly>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.LAST_MODIFIED' | translate }}</mat-label>
          <input name="updated_at" formControlName="updated_at" matInput readonly>
        </mat-form-field>
        <ng-container *ngIf="file.versions.length > 1">
          <mat-form-field appearance="outline" fxFlex="20">
            <mat-label>{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.VERSION' | translate }}</mat-label>
            <mat-select formControlName="version" (selectionChange)="changeVersion($event.value)">
              <mat-option [value]="_version.version" *ngFor="let _version of file.versions">
                {{ _version.version }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="file.versions.length <= 1">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.VERSION' | translate }}</mat-label>
            <input name="version" formControlName="version" matInput readonly>
          </mat-form-field>
        </ng-container>
      </div>
      <div class="group">
        <h2>{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.SHARE' | translate }}</h2>
      
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.LINK' | translate }}</mat-label>
          <input name="path" formControlName="path" matInput readonly #inputTarget>
          <mat-icon matSuffix [ngxClipboard]="inputTarget" class="cursor-pointer" (cbOnSuccess)="copiedLink()">content_copy</mat-icon>
        </mat-form-field>
      </div>

      <div class="text-center">
        <label for="file-upload" class="custom-file-upload mat-raised-button">
          {{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.UPLOAD_NEW_VERSION' | translate }}
        </label>
        <span *ngIf="fileWaiting" class="mat-small primary-200-fg">
          <ng-container *ngIf="!fileWaiting.error">
            {{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.WAITING_UPLOAD' | translate }}: <b>{{ fileWaiting.name }}</b>
            <mat-icon (click)="fileWaiting = null" class="cursor-pointer" style="vertical-align: middle;">close</mat-icon>
          </ng-container>
          <ng-container *ngIf="fileWaiting.error">
            <span class="warn-fg">
              Error: <b>{{ fileWaiting['error'] | translate }}</b>
              <mat-icon (click)="fileWaiting = null" class="cursor-pointer" style="vertical-align: middle;">close</mat-icon>
            </span>
          </ng-container>
        </span>
        <input id="file-upload" type="file" (change)="fileChangeEvent($event)" accept="image/*,application/pdf,application/msword,audio/*,video/*,application/vnd.ms-powerpoint,application/vnd.ms-excel,text/*,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
      </div>

      <mat-divider class="my-20"></mat-divider>


      <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
        <button mat-raised-button color="accent" (click)="updateFile()">
          <mat-icon>save</mat-icon>
          {{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.SAVE' | translate }}
        </button>
        <button mat-raised-button (click)="sharWhatsapp()" class="green-400 fuse-white-fg" *ngIf="TwilioPhone && file.user.phone" [disabled]="fileWaiting && !fileWaiting.error">
          <mat-icon>share</mat-icon>
          {{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.SHARE' | translate }}
        </button>
        <button mat-raised-button color="warn" (click)="deleteFile()" class="" [disabled]="fileWaiting && !fileWaiting.error">
          <mat-icon>delete</mat-icon>
          {{ 'MANAGEMENT.USERS.FILES.FILE_DETAILS.DELETE' | translate }}
        </button>
      </mat-button-toggle-group>

      <ngx-spinner name="file_details_loading" [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium" bdColor="rgba(51, 51, 51, 0.5)">
        <span class="fuse-white-fg" *ngIf="fileWaiting">
          <span *ngIf="fileWaitingPosting.progress < 100" class="font-size-9">{{ fileWaitingPosting.posted_data | bytes : 2 }} / {{ fileWaitingPosting.posted_total | bytes : 2 }} | </span>
          <span *ngIf="fileWaitingPosting.progress >= 100" class="font-size-9">{{ fileWaitingPosting.posted_total | bytes : 2 }} | </span>
          {{ fileWaitingPosting.progress }}%
        </span>
      </ngx-spinner>
    </ng-container>
  </form>

</div>