<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" src="{{ '/storage/company/company_logo.png' | server_file }}">
        </div>
      </div>

      <div class="px-8 px-md-16">
        <fuse-shortcuts [navigation]="navigation" *ngIf="showShortcut"></fuse-shortcuts>
      </div>
    </div>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="avatar mr-0 mr-sm-16" src="{{ User.avatar }}" *ngIf="User.avatar">
          <span class="username mr-12" fxHide fxShow.gt-sm>{{ User.title }} {{ User.first_name }} {{ User.last_name }}</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
        <ngx-spinner name="profile" [fullScreen]="false" type="ball-clip-rotate-multiple" size="small"></ngx-spinner>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="gotoProfile()">
          <mat-icon>account_circle</mat-icon>
          <span>{{ 'TOOLBAR.USER.ACCOUNT' | translate }}</span>
        </button>

        <button mat-menu-item [routerLink]="[ 'messages' ]">
          <mat-icon>mail</mat-icon>
          <span>{{ 'TOOLBAR.USER.INBOX' | translate }}</span>
        </button>

        <button mat-menu-item class="" (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>{{ 'TOOLBAR.USER.LOGOUT' | translate }}</span>
        </button>
      </mat-menu>

      <div class="toolbar-separator"></div>

      <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

      <!-- <div class="toolbar-separator"></div> -->

      <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.svg'">
          <span class="iso text-uppercase">{{ selectedLanguage.id }}</span>
        </div>
      </button>

      <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
          <span fxLayout="row" fxLayoutAlign="start center">
            <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.svg'">
            <span class="iso">{{ lang.title }}</span>
          </span>
        </button>
      </mat-menu>

      <div class="toolbar-separator" ></div>

      <button mat-icon-button class="notifications-button" aria-label="Toggle notification options" [matMenuTriggerFor]="notificationMenu">
        <mat-icon class="material-icons-outlined secondary-text" [matBadgeHidden]="(UnreadNotifications === 0)" [matBadge]="UnreadNotifications" matBadgeSize="small">notifications</mat-icon>
      </button>

      
      <!-- Notifications panel -->
      <mat-menu #notificationMenu="matMenu" [overlapTrigger]="false" class="menu-notifications w-360 position-static">
        
          <!-- Header -->
          <div class="py-16 pr-16 pl-24 menu-notifications-header primary">
            <div class="font-size-16 font-weight-500 line-height-40">{{ 'TOOLBAR.NOTIFICATIONS.TITLE' | translate }}</div>
            <div class="ml-auto">
              <button mat-icon-button matTooltip="{{ 'TOOLBAR.NOTIFICATIONS.MARK_ALL' | translate }}" [disabled]="UnreadNotifications === 0" (click)="markAllAsRead()">
                <mat-icon class="material-icons-outlined">mark_email_read</mat-icon>
              </button>
            </div>
          </div>

          <!-- Content -->
          <div class="position-relative menu-notifications-list h-480 fuse-white-500-bg" fusePerfectScrollbar>
            <!-- Notifications -->
            <ng-container *ngFor="let notification of AllNotifications; trackBy: trackByFn">
              <div class="menu-notifications-item position-relative" [ngClass]="{ 'unread': !notification.readed }">
                <!-- Notification with a link -->
                <ng-container *ngIf="notification.link">
                  <!-- Normal links -->
                  <ng-container *ngIf="!notification.useRouter">
                    <a class="flex flex-auto py-20 pl-24 cursor-pointer" [href]="notification.link">
                      <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                    </a>
                  </ng-container>
                  <!-- Router links -->
                  <ng-container *ngIf="notification.useRouter">
                    <a class="flex flex-auto py-20 pl-24 cursor-pointer" [routerLink]="notification.link">
                      <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                    </a>
                  </ng-container>
                </ng-container>

                <!-- Notification without a link -->
                <ng-container *ngIf="!notification.link">
                  <div class="flex flex-auto py-20 pl-24">
                    <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                  </div>
                </ng-container>

                <!-- Actions -->
                <div class="position-relative flex flex-col my-20 mr-24 ml-8 action-group">
                  <!-- Indicator -->
                  <button class="w-24 h-24 min-h-24" mat-icon-button (click)="toggleRead(notification)" matTooltip="{{ (notification.readed ? 'TOOLBAR.NOTIFICATIONS.MARK_UNREAD' : 'TOOLBAR.NOTIFICATIONS.MARK_READ') | translate }}">
                    <span class="w-8 h-8 border-radius-100 " [ngClass]="{'primary-100-bg': notification.readed, 'accent-bg': !notification.readed}"></span>
                  </button>
                  <!-- Remove -->
                  <button class="w-24 h-24 min-h-24 opacity-0" mat-icon-button (click)="delete(notification, $event)" matTooltip="{{ 'TOOLBAR.NOTIFICATIONS.REMOVE' | translate }}">
                    <mat-icon class="font-size-16">clear</mat-icon>
                  </button>
                </div>

                <div 
                  [@slideInRight]="notification.on_process_delete ? '*' : 'void'" 
                  *ngIf="notification.on_process_delete" 
                  class="flex flex-col position-absolute w-100-p h-100-p blue-grey-50-bg">
                  <div class="flex flex-auto font-size-16 text-semibold justify-center items-center">{{ 'TOOLBAR.NOTIFICATIONS.REMOVE_MESSAGE' | translate }}</div>
                  <div class="flex flex-row">
                    <button class="h-24 p-0 flex flex-auto border-radius-0 mat-elevation-z0 justify-center items-center pt-8" color="accent" mat-raised-button matTooltip="{{ 'TOOLBAR.NOTIFICATIONS.REMOVE_YES' | translate }}" (click)="performDelete(notification, $event)">
                      <mat-icon class="font-size-16 fuse-white-fg" slot="start">check</mat-icon>
                    </button>
                  
                    <button class="h-24 p-0 flex flex-auto border-radius-0 mat-elevation-z0 justify-center items-center pt-8" color="warn" mat-raised-button matTooltip="{{ 'TOOLBAR.NOTIFICATIONS.REMOVE_NO' | translate }}" (click)="delete(notification, $event)" >
                      <mat-icon class="font-size-16 fuse-white-fg" slot="start">clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <!-- Notification content template -->
              <ng-template #notificationContent>
                <!-- Icon -->
                <ng-container *ngIf="notification.icon && !notification.image">
                  <div class="flex flex-shrink-0 items-center justify-center w-32 h-32 mr-16 border-radius-100 grey-100-bg">
                    <mat-icon class="font-size-24 line-height-24">{{ notification.icon || 'label' }}</mat-icon>
                  </div>
                </ng-container>
                <!-- Image -->
                <ng-container *ngIf="notification.image">
                  <img class="flex-shrink-0 w-32 h-32 mr-16 border-radius-100 overflow-hidden object-cover object-center" [src]="notification.image" [alt]="'Notification image'">
                </ng-container>
                <!-- No Icon & No Image -->
                <ng-container *ngIf="!notification.icon && !notification.image">
                  <div class="flex flex-shrink-0 items-center justify-center w-32 h-32 mr-16 border-radius-100 grey-100-bg">
                    <mat-icon class="font-size-24 line-height-24">label</mat-icon>
                  </div>
                </ng-container>
                <!-- Title, description & time -->
                <div class="flex flex-col flex-auto">
                  <div class="font-size-12 text-bold line-clamp-1" [innerHTML]="notification.title || '&nbsp;'"></div>
                  
                  <ng-container *ngIf="notification.body">
                    <div class="font-size-12 line-clamp-2" [innerHTML]="notification.body"></div>
                  </ng-container>
                  <div class="mt-8 font-size-10 line-height-1 secondary-text">
                    {{ notification.created_at | date:'MMM dd, h:mm a' }}
                  </div>
                </div>
              </ng-template>

              <mat-divider></mat-divider>
            </ng-container>

            <!-- No notifications -->
            <ng-container *ngIf="!AllNotifications || !AllNotifications.length">
              <div class="flex flex-col flex-auto items-center justify-center py-48 px-32">
                <div class="flex flex-0 items-center justify-center w-56 h-56 border-radius-100 grey-100-bg">
                  <mat-icon class="primary-fg">support_agent</mat-icon>
                </div>
                <div class="mt-20 text-semibold">No notifications</div>
                <div class="w-100-p max-w-240 mt-4 font-size-16 text-center secondary-text">When you have notifications, they will appear here.</div>
              </div>
            </ng-container>
          </div>

      </mat-menu>

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

      <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
