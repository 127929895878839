import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [{
  id       : 'management',
  title    : 'Management',
  translate: 'NAV.MANAGEMENT.TITLE',
  type     : 'group',
  icon     : 'apps',
  children : [{
    id       : 'users',
    title    : 'Users',
    translate: 'NAV.MANAGEMENT.USERS',
    type     : 'item',
    icon     : 'people',
    url      : '/management/users',
    auth     : [ 'users/index' ]
  }, {
    id       : 'messages',
    title    : 'Messages',
    translate: 'NAV.MESSAGES',
    type     : 'item',
    icon     : 'mail',
    url      : '/messages',
    auth     : [ 'messages/view' ],
  }, {
    id       : 'roles',
    title    : 'Roles',
    translate: 'NAV.MANAGEMENT.ROLES',
    type     : 'item',
    icon     : 'pattern',
    url      : '/management/roles',
    auth     : [ 'roles/index' ]
  }, {
    id: 'clients',
    title: 'Clients',
    translate: 'NAV.CLIENT.TITLE',
    type: 'item',
    icon: 'face',
    url: '/clients',
    auth: [ 'clients/index' ]
  }, {
    id: 'offices',
    title: 'Offices',
    translate: 'NAV.OFFICES.TITLE',
    type: 'item',
    icon: 'maps_home_work',
    url: '/offices',
    auth: [ 'offices/index' ]
  }, {
    id: 'orders',
    title: 'Orders',
    translate: 'NAV.ORDERS.TITLE',
    type: 'item',
    icon: 'sync_alt',
    url: '/orders',
    auth: [ 'orders/index' ]
  }, {
    id: 'calendar',
    title: 'Calendar',
    translate: 'NAV.CALENDAR.TITLE',
    type: 'item',
    icon: 'event',
    url: '/calendar',
    auth: [ 'calendar/view' ]
  }, {
    id: 'appointment',
    title: 'Appointment',
    translate: 'NAV.APPOINTMENT.TITLE',
    type: 'item',
    icon: 'date_range',
    url: '/appointments',
    auth: [ 'calendar/index' ]
  }, {
    id: 'settings',
    title: 'Settings',
    translate: 'NAV.SETTINGS.TITLE',
    type: 'collapsable',
    icon: 'tune',
    auth: [ 'settings/view' ],
    children: [{
      id: 'settings-general',
      title: 'General Settings',
      translate: 'NAV.SETTINGS.GENERAL',
      type: 'item',
      icon: 'settings',
      url: '/settings',
      auth: [ 'settings/view' ],
      exactMatch: true
    }, {
      id: 'settings-chatbot',
      title: 'Chtbot Settings',
      translate: 'NAV.SETTINGS.CHATBOT',
      type: 'item',
      icon: 'smart_toy',
      url: '/settings/chatbot',
      auth: [ 'chatbot/view' ]
    }, {
      id: 'settings-apis',
      title: 'API\'s Settings',
      translate: 'NAV.SETTINGS.APIS',
      type: 'item',
      icon: 'vpn_key',
      url: '/settings/apis',
      auth: [ 'settings_api/view' ]
    }, {
      id: 'settings-mail',
      title: 'Mail Server Settings',
      translate: 'NAV.SETTINGS.MAIL',
      type: 'item',
      icon: 'mail',
      url: '/settings/mail',
      auth: [ 'settings_mail/view' ]
    }, {
      id: 'settings-theme',
      title: 'Landing page theme',
      translate: 'NAV.SETTINGS.THEME',
      type: 'item',
      icon: 'brush',
      url: '/settings/theme',
      auth: [ 'settings_theme/index' ]
    }]
  }]
}, {
  id       : 'management_offices',
  title    : 'Management Offices',
  translate: 'NAV.OFFICES.TITLE',
  type     : 'group',
  icon     : 'apps',
  children : [{
    id: 'banners_office',
    title: 'Banners By Office',
    translate: 'NAV.BANNERS_OFFICE.TITLE',
    type: 'item',
    icon: 'photo_library',
    url: '/bannersoffice',
    auth: [ 'banners_office/index' ],
    is_sucursal: true
  }]
}, {
  id       : 'Catalogs',
  title    : 'Catalogs',
  translate: 'NAV.CATALOGS.TITLE',
  type     : 'group',
  icon     : 'apps',
  children : [{
    id: 'categories',
    title: 'Categories',
    translate: 'NAV.CATEGORIES.TITLE',
    type: 'item',
    icon: 'list',
    url: '/categories',
    auth: [ 'categories/index' ]
  }, {
    id: 'products',
    title: 'Products',
    translate: 'NAV.PRODUCTS.TITLE',
    type: 'item',
    icon: 'inventory',
    url: '/products',
    auth: [ 'products/index' ]
  }, {
    id: 'services',
    title: 'Services',
    translate: 'NAV.SERVICES.TITLE',
    type: 'item',
    icon: 'inventory',
    url: '/services',
    auth: [ 'services/index' ]
  }, {
    id: 'price_lists',
    title: 'Price lists',
    translate: 'NAV.PRICE_LISTS.TITLE',
    type: 'item',
    icon: 'money',
    url: '/price-list',
    auth: [ 'price_list/index' ]
  }, {
    id: 'forms',
    title: 'Forms',
    translate: 'NAV.FORMS.TITLE',
    type: 'item',
    icon: 'view_sidebar',
    url: '/forms',
    auth: [ 'forms/index' ]
  }]
}, {
  id       : 'close_session',
  title    : 'Close Session',
  translate: 'NAV.LOGOUT.CLOSE_SESSION',
  type     : 'group',
  icon     : 'people',
  children : [{
    id: 'logout',
    title: 'LogOut',
    translate: 'NAV.LOGOUT.TITLE',
    type: 'item',
    icon: 'logout',
    url      : '/auth/logout',
    classes: 'logout-link',
    auth: [ 'guest' ]
  }]
}];
