import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ 
  name: 'onlyText',
  pure: false
})
export class OnlyTextPipe implements PipeTransform {
  transform(value: string): any {
    const _divTmp = document.createElement('div');
    _divTmp.innerHTML = value;
    return _divTmp.innerText || value;
  }
}