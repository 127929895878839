/**
 * Created by xavi on 5/16/17.
 */
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Login, Session, Response } from 'app/core/interfaces/all.interface';

import { ServerApiPipe } from 'app/pipes/server_api.pipe';

@Injectable()
export class AuthenticationService {

  constructor(private http: HttpClient) {}

  login(login: Login): Observable<Response<Session>> {
    return this.http.post<Response<Session>>(new ServerApiPipe().transform('auth/login'), login);
  }

  logout(): Observable<Response<any>> {
    return this.http.post<Response<any>>(new ServerApiPipe().transform('auth/logout'), {});
  }
}
