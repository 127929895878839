import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { StorageService } from 'app/core/services/storage.service';
import { map, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import * as XLSX from 'xlsx';

import { User } from 'app/core/interfaces/all.interface';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(
    private spinner: NgxSpinnerService,
    private _storage: StorageService
  ) {}

  private _spinnerName = 'fullpage';

  showLoading(name: string = '') {
    this.spinner.show(name || this._spinnerName);
  }

  hideLoading(name: string = '') {
    this.spinner.hide(name || this._spinnerName);
  }

  dataURItoBlob(dataURI, type: string = 'image/png') {
    const byteString = window.atob(dataURI.replace(`data:${type};base64,`, ''));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type });    
    return blob;
  }

  ToFile(name: string = 'name.png', base64: string = '', type: string = 'image/png') {
    const imageBlob = this.dataURItoBlob(base64, type);
    const imageFile = new File([imageBlob], name, { type });

    return imageFile;
  }

  ToJsonFile(name: string = 'name.txt', content: string = '', type: string = 'text/plain') {
    var blob = new Blob([content], { type: "text/plain;charset=utf-8" });
    const jsonFile = new File([blob], name, { type });
    return jsonFile;
  }

  hasAccess(access: string): boolean {
    let _user = this._storage.getCurrentUser();

    return access.toLowerCase() === 'guest' || (_user && (_user.permisos || []).filter(p => `${p.access}` === '1' || `${p.access}` === 'true').map(p => `${p.controller}/${p.action}`).find(p => p === access) ? true : false);
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      }, err => {
        reject(err);
      });
    });
  }

  importFromFile(bstr: string): XLSX.AOA2SheetOpts {
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    const data = <XLSX.AOA2SheetOpts>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

    return data;
  }

  download(result, name: string) {
    var downloadURL = window.URL.createObjectURL(result);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = name;
    link.click();
  }
}
