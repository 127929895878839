import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Coupon, User, Response, Active } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';

import * as moment from 'moment';

@Injectable()
export class CouponsService {

  constructor(private http: HttpClient) {}

  get(id: Number): Observable<Response<Coupon>> {
    return  id > 0 ? this.http.get<Response<Coupon>>(new ServerApiPipe().transform(`coupons/${id}`)) : of({
      status: 'success',
      success: true,
      message: '',
      data: {
        name: '',
        code: '',
        discount_type: 1,
        type: 1,
        value: 0,
        expiration_date_start: moment().startOf('month').toDate(),
        expiration_date_end: moment().endOf('month').toDate(),
        qty: 1,
        active: true,
        products: []
      } as Coupon
    } as Response<Coupon>);
  }

  all(body: any = {}): Observable<Response<Coupon[]>> {
    let params = new HttpParams().set("s", body.s || '');

    return this.http.get<Response<Coupon[]>>(new ServerApiPipe().transform(`coupons`), { params });
  }

  delete(coupon: Coupon): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`coupons/${coupon.id}`));
  }

  update(coupon: Coupon): Observable<Response<Coupon>> {
    return this.http.put<Response<Coupon>>(new ServerApiPipe().transform(`coupons/${coupon.id}`), coupon);
  }

  save(coupon: Coupon): Observable<Response<Coupon>> {
    return this.http.post<Response<Coupon>>(new ServerApiPipe().transform(`coupons`), coupon);
  }

  list(body: any = {}): Observable<Response<Coupon[]>> {
    let params = new HttpParams();

    if (body.active) {
      params = params.set('active', body.active);
    }

    return this.http.get<Response<Coupon[]>>(new ServerApiPipe().transform(`coupons/list`), { params });
  }
}
