import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Router, RouterEvent } from '@angular/router';
import { ServerFilePipe } from 'app/pipes/server_file.pipe';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseNavigation } from '@fuse/types';

import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { locale as navigationMexican } from 'app/navigation/i18n/es';

import { StorageService } from 'app/core/services/storage.service';
import { OfficesService } from 'app/core/services/offices.service';
import { NotificationsService } from 'app/core/services/notificationes.service';
import { ProductsImportService } from 'app/core/services/productsImport.service';
import { FilesService } from 'app/core/services/files.service';
import { AppointmentsService } from 'app/core/services/appointments.service';
import { ChatService } from 'app/core/services/chat.service';
import { SettingsService } from 'app/core/services/settings.service';
import { UtilsService } from 'app/core/services/utils.service';

import { User } from 'app/core/interfaces/user.interface';
import { Office, Settings } from 'app/core/interfaces/all.interface';

import Conekta from 'app/core/helper/conekta.helper';

import * as firebase from 'firebase';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Howl, Howler } from 'howler';

import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);

@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  listenForChat: any = {};
  total: number = 0;
  ready: boolean = false;

  get FireBaseURL(): string {
    return this._settings.getCurrentSettings().google_firebase;
  }

  get canViewMessges(): boolean {
    return this._utils.hasAccess('messages/view');
  }

  // Private
  private _unsubscribeAll: Subject<any>;
  private _unsubscribeUser: any;
  private _listenForNotifications: any;
  private _listenForUserNotifications: any;
  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {FuseSplashScreenService} _fuseSplashScreenService
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   */
  constructor(
      @Inject(DOCUMENT) private document: any,
      private _fuseConfigService: FuseConfigService,
      private _fuseNavigationService: FuseNavigationService,
      private _fuseSidebarService: FuseSidebarService,
      private _fuseSplashScreenService: FuseSplashScreenService,
      private _fuseTranslationLoaderService: FuseTranslationLoaderService,
      private _translateService: TranslateService,
      private _storage: StorageService,
      private _offices: OfficesService,
      private _notifications: NotificationsService,
      private _settings: SettingsService,
      private _platform: Platform,
      private router: Router,
      private _translate: TranslateService,
      private _productsImport: ProductsImportService,
      private _files: FilesService,
      private _appointments: AppointmentsService,
      private _chat: ChatService,
      private _utils: UtilsService,
  ) {
      var sound = new Howl({
        src: [ '/assets/sounds/alert.wav' ],
      });

      // Get default navigation
      this.navigation = [];

      // Register the navigation to the service
      this._fuseNavigationService.register('empty', this.navigation);

      // Set the main navigation as our current navigation
      this._fuseNavigationService.setCurrentNavigation('empty');

      // Add languages
      this._translateService.addLangs(['en', 'tr', 'mx']);

      // Set the default language
      this._translateService.setDefaultLang('mx');

      // Set the navigation translations
      this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish, navigationMexican);

      // Use a language
      this._translateService.use(this._storage.getLanguage() || this._storage.storageLanguege('mx') || 'mx');
      moment.locale(({'en': 'en', 'tr': 'tr', 'mx': 'es'})[this._storage.getLanguage() || this._storage.storageLanguege('mx') || 'mx']);

      // Add is-mobile class to the body if the platform is mobile
      if ( this._platform.ANDROID || this._platform.IOS ) {
          this.document.body.classList.add('is-mobile');
      }

      // Set the private defaults
      this._unsubscribeAll = new Subject();
      this._unsubscribeUser = this._storage.onCurrentUserChanged().subscribe((user: User) => {
        if (user) {
          if (this._fuseNavigationService.getNavigation('main')) {
            this._fuseNavigationService.unregister('main');
          }
          this.clearMenu(navigation);
          // Get default navigation
          this.navigation = this.validMenu(navigation, user);

          // Register the navigation to the service
          this._fuseNavigationService.register('main', this.navigation);

          // Set the main navigation as our current navigation
          this._fuseNavigationService.setCurrentNavigation('main');
          
          this._settings.all().subscribe((settings: Settings) => {
            if (settings.conekta_client) Conekta.initConekta(settings);
          });
          this._settings.onSettingsChange().subscribe((settings: Settings) => {
            this._settings.setFirebaseApp();
          });
          this._notifications.list(user).subscribe((response) => {
            if (response.data && response.data.length > 0) {
              let _everyoneNotifications: Notification[] = JSON.parse(localStorage.getItem(`notifications.${user.id}`) || '[]') as Notification[];
              
              this._notifications.setNotifications(response.data.map(n => {
                let _found = _.find(_everyoneNotifications, {
                  id: n.id
                });
                
                return {...n, ...(_found as object) };
              }));
            }
          });
          this._settings.onFirebaseSetted()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings: Settings) => {
              this._listenForNotifications = this.FireBaseURL && firebase.database().ref(`notifications/all`).on('value', resp => {
                this._notifications.push(resp.val());
              });
              this._listenForUserNotifications = this.FireBaseURL && firebase.database().ref(`notifications/${user.id}`).on('value', resp => {
                this._notifications.push(resp.val());
              });

              this.listenForChat['parent'] = this.FireBaseURL && firebase.database().ref(`chats`).on('value', resp => {
                if (this.ready && this.canViewMessges) {
                  // this.total = this.total + 1;
                  this._chat.addBadge();

                  sound.play();
                }
              });

              setTimeout(()=> {
                this.ready = true;
              }, 1000);
            });
        }
      });

      this.router.events.pipe(
        filter(e => e instanceof RouterEvent)
      ).subscribe((val: any) => {
        let _navItem = this.getNavigationItem(val.url, navigation);

        if (_navItem) {
          document.title = this._translate.instant(_navItem.translate);
        } else {
          document.title = '';
        }
      });

      this.document.querySelector('#fuse-splash-screen .logo img').src = new ServerFilePipe().transform('/storage/company/company_icon.png');
      
      let _favicon = this.document.createElement('link');
      _favicon.rel = 'icon';
      _favicon.type = 'image/png';
      _favicon.href = new ServerFilePipe().transform('/storage/company/company_icon.png');

      this.document.head.appendChild(_favicon);

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
      // Subscribe to config changes
      this._fuseConfigService.config
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((config) => {

              this.fuseConfig = config;

              // Boxed
              if ( this.fuseConfig.layout.width === 'boxed' )
              {
                  this.document.body.classList.add('boxed');
              }
              else
              {
                  this.document.body.classList.remove('boxed');
              }

              // Color theme - Use normal for loop for IE11 compatibility
              for ( let i = 0; i < this.document.body.classList.length; i++ )
              {
                  const className = this.document.body.classList[i];

                  if ( className.startsWith('theme-') )
                  {
                      this.document.body.classList.remove(className);
                  }
              }

              this.document.body.classList.add(this.fuseConfig.colorTheme);
          });


  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
      if ( this.FireBaseURL) {
        firebase.database().ref(`notifications/all`).off('value', this._listenForNotifications);
        firebase.database().ref(`chats`).off('value', this.listenForChat['parent']);
      }
      this._unsubscribeUser.unsubscribe();
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
      this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  validMenu(nav: FuseNavigation[], user: User): FuseNavigation[] {
    let _nav = nav;
    let _listPermission = user.permisos.filter(p => +p.access === 1).map(p => `${p.controller}/${p.action}`);

    nav.forEach((n, i) => {
      if (n.type === 'item') {
        if (!n.auth) n.hidden = true;
        if (!n.auth.find(f => _listPermission.includes(f)) && !n.auth.includes('guest')) n.hidden = true;
        else if (n.is_sucursal && !user.role.is_sucursal) n.hidden = true;
        else n.hidden = false;
      }
      if (n.children && n.children.length > 0) {
        this.validMenu(n.children, user);
        if (n.children.filter(c => !c.hidden).length <= 0) n.hidden = true;
      }
    });
    return _nav;
  }

  clearMenu(nav: FuseNavigation[]): FuseNavigation[] {
    let _nav = nav;

    nav.forEach((n, i) => {
      if (n.type === 'item') {
        delete n.hidden;
      }
      if (n.children && n.children.length > 0) {
        this.clearMenu(n.children);
        delete n.hidden;
      }
    });
    return _nav;
  }

  getNavigationItem(url, navigation = null): any | boolean {
    for ( const item of navigation ) {
      if ( item.url === url ) {
        return item;
      }

      if ( item.children ) {
        const childItem = this.getNavigationItem(url, item.children);

        if ( childItem ) {
          return childItem;
        }
      }
    }

    return false;
  }

  ProductImportDetailsOpen(arg1) {
    if (!arg1) {
      this._productsImport.setCurrentProduct(null);
    }
  }

  FileDetailsOpen(arg1) {
    if (!arg1) {
      this._files.setCurrentFile(null);
    }
  }

  AppointmentDetailsOpen(arg1) {
    if (!arg1) {
      this._appointments.setCurrentAppointment(null);
    } 
  }

  QuestionnarieImportDetailsOpen(arg1) {
    if (!arg1) {
      // this._appointments.setCurrentAppointment(null);
    } 
  }
}
