
import { Settings } from 'app/core/interfaces/all.interface';

const Conekta = {
  initConekta: (settings: Settings = {} as Settings) => {
    window['Conekta'].setPublicKey(settings.conekta_client)
  },
  getCardBrand: (cardNumber) => {
    return window['Conekta'].card.getBrand(cardNumber)
  },
  validateCardNumber: (cardNumber) => {
    return window['Conekta'].card.validateNumber(cardNumber)
  },
  validateCvc: (cvc) => {
    return window['Conekta'].card.validateCVC(cvc)
  },
  validateExpirationDate: (expiryMonth, expiryYear) => {
    return window['Conekta'].card.validateExpirationDate(expiryMonth, `20${expiryYear}`)
  },
  tokenize: (cardNumber, cardHolder, expiryMonth, expiryYear, cvc): Promise<any> => {
    return new Promise((resolve, reject) => {
      const tokenParams = {
        card: {
          number: cardNumber,
          name: cardHolder,
          exp_year: expiryYear,
          exp_month: expiryMonth,
          cvc
        }
      }
      window['Conekta'].Token.create(tokenParams, resolve, reject);
    });
  }
}

export default Conekta