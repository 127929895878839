import { Pipe, PipeTransform } from '@angular/core';
import { UserFile } from 'app/core/interfaces/all.interface';
@Pipe({ 
  name: 'extension',
  pure: false
})
export class ExtensionPipe implements PipeTransform {
  extensiones: any = {
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
    'image/png': '.png',
    'image/gif': '.gif',
    'image/x-icon': '.ico',
    'image/jpeg': '.jpeg',
    'image/svg+xml': '.svg',
    'image/tiff': '.tiff',
    'image/webp': '.webp',
    'audio/aac': '.aac',
    'application/x-abiword': '.abw',
    'video/x-msvideo': '.avi',
    'application/vnd.amazon.ebook': '.azw',
    'application/octet-stream': '.bin',
    'application/x-bzip': '.bz',
    'application/x-bzip2': '.bz2',
    'application/x-csh': '.csh',
    'text/css': '.css',
    'text/csv': '.csv',
    'application/msword': '.doc',
    'application/epub+zip': '.epub',
    'text/html': '.html',
    'text/calendar': '.ics',
    'application/java-archive': '.jar',
    'application/javascript': '.js',
    'application/json': '.json',
    'audio/midi': '.midi',
    'video/mpeg': '.mpeg',
    'audio/mpeg': '.mp3',
    'video/mp4': '.mp4',
    'application/vnd.apple.installer+xml': '.mpkg',
    'application/vnd.oasis.opendocument.presentation': '.odp',
    'application/vnd.oasis.opendocument.spreadsheet': '.ods',
    'application/vnd.oasis.opendocument.text': '.odt',
    'audio/ogg': '.oga',
    'video/ogg': '.ogv',
    'application/ogg': '.ogx',
    'application/pdf': '.pdf',
    'application/vnd.ms-powerpoint': '.ppt',
    'application/x-rar-compressed': '.rar',
    'application/rtf': '.rtf',
    'application/x-sh': '.sh',
    'application/x-shockwave-flash': '.swf',
    'application/x-tar': '.tar',
    'font/ttf': '.ttf',
    'application/vnd.visio': '.vsd',
    'audio/x-wav': '.wav',
    'audio/webm': '.weba',
    'video/webm': '.webm',
    'font/woff': '.woff',
    'font/woff2': '.woff2',
    'application/xhtml+xml': '.xhtml',
    'application/vnd.ms-excel': '.xls',
    'application/xml': '.xml',
    'application/vnd.mozilla.xul+xml': '.xul',
    'application/zip': '.zip',
    'video/3gpp': '.3gp',
    'video/3gpp2': '.3g2',
    'application/x-7z-compressed': '.7z',
    'application/x-freearc': '.arc',
    'image/bmp': '.bmp',
    'application/vnd.ms-fontobject': '.eot',
    'application/gzip': '.gz',
    'image/vnd.microsoft.icon': '.ico',
    'text/javascript': '.js',
    'application/ld+json': '.jsonld',
    'audio/x-midi': '.midi',
    'audio/opus': '.opus',
    'font/otf': '.otf',
    'application/x-httpd-php': '.php',
    'application/vnd.rar': '.rar',
    'video/mp2t': '.ts',
    'text/plain': '.txt',
    'audio/wav': '.wav',
    'text/xml': 'xml'
  };
  
  transform(file: UserFile): any {
    const extension = (file.name||'').slice(((file.name||'').lastIndexOf(".") - 2 >>> 0) + 2);
    return this.extensiones[file.type] || extension;
  }
}