import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { FacturamaClient } from 'app/core/helper/facturama.helper';

import { Response, Active, FacturamaCFDI } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';

import { environment as env } from '../../../environments/environment';

@Injectable()
export class FacturamaService {
  private _facturamaHost: string = 'http://api.facturama.mx/';

  constructor(private http: FacturamaClient) {
    if (env.production) {
      this._facturamaHost = 'http://api.facturama.mx/';
    } else {
      this._facturamaHost = 'http://apisandbox.facturama.mx/';
    }
  }

  cfdiList(rfc: string): Observable<any[]> {
    return this.http.get<any[]>(`${this._facturamaHost}Catalogs/CfdiUses?keyword=${rfc}`);
  }
}