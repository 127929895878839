import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FuseMaterialColorPickerModule } from '@fuse/components/material-color-picker/material-color-picker.module';
import { FuseSidebarModule } from '@fuse/components/sidebar/sidebar.module';
import { NgxSpinnerModule } from 'ngx-spinner';

import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'app/pipes/pipes.module';
import { NgPipesModule, NgBytesPipeModule } from 'angular-pipes';

import { FileDetailsSidebarComponent } from './file-details-sidebar.component';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { ClipboardModule } from 'ngx-clipboard';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [ FileDetailsSidebarComponent ],
  imports: [
    NgxSpinnerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    FlexLayoutModule,

    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSlideToggleModule,
    
    TranslateModule.forChild(),
    PipesModule,
    FuseDirectivesModule,
    FuseMaterialColorPickerModule,
    FuseSidebarModule,
    MatButtonModule,
    NgBytesPipeModule,
    NgxAudioPlayerModule,
    MatChipsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSelectModule,
    MatButtonToggleModule,
    ClipboardModule
  ],
  exports: [
    FileDetailsSidebarComponent
  ]
})
export class FileDetailsSidebarModule { }
