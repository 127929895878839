import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Response, Order, OrderProduct } from 'app/core/interfaces/all.interface';

import { ServerApiPipe } from 'app/pipes/server_api.pipe';

@Injectable()
export class OrdersService {
  constructor(private http: HttpClient) {}

  all(body: any): Observable<Response<Order[]>> {
    let params = new HttpParams().set("s", body.s || '');

    return this.http.get<Response<Order[]>>(new ServerApiPipe().transform(`orders`), { params });
  }

  get(id: string): Observable<Response<Order>> {
    return id !== '' ? this.http.get<Response<Order>>(new ServerApiPipe().transform(`orders/${id}`)) : of({
      status: 'success',
      success: true,
      message: '',
      data: {
        name: '',
        phone: '',
        email: '',
        payment_method: '',
        titular: '',
        card: '',
        expiration: '',
        subtotal: 0,
        discount: 0,
        total: 0,
        uuid_key: '',
        qty: 0,
      } as Order
    } as Response<Order>);
  }

  delete(order: Order): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`orders/${order.id}`));
  }

  update(order: Order, status: number): Observable<Response<Order>> {
    return this.http.put<Response<Order>>(new ServerApiPipe().transform(`orders/${order.uuid_key}`), { status });
  }

  save(order: Order): Observable<Response<Order>> {
    return this.http.post<Response<Order>>(new ServerApiPipe().transform(`orders`), order);
  }

  list(): Observable<Response<Order[]>> {
    return this.http.get<Response<Order[]>>(new ServerApiPipe().transform(`orders/list`));
  }

  products(order: Order, body: any = {}): Observable<Response<OrderProduct[]>> {
    let params = new HttpParams().set("s", body.s || '');
    return this.http.get<Response<OrderProduct[]>>(new ServerApiPipe().transform(`orders/${order.id}/products`), { params });
  }

  getProduct(order: Order, id: number): Observable<Response<OrderProduct>> {
    return this.http.get<Response<OrderProduct>>(new ServerApiPipe().transform(`orders/${order.id}/products/${id}`));
  }

  generateInvoice(order: Order, data: any): Observable<Response<any>> {
    return this.http.post<any>(new ServerApiPipe().transform(`orders/${order.uuid_key}/invoice`), data);
  }

  sendInvoice(order: Order, data: any): Observable<Response<any>> {
    return this.http.post<any>(new ServerApiPipe().transform(`orders/${order.uuid_key}/invoice/send`), data);
  }
}
