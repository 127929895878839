import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SettingsService } from 'app/core/services/settings.service';
import { Settings } from 'app/core/interfaces/all.interface';

import * as firebase from 'firebase';

@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent implements OnInit, OnDestroy {
  date: Date;
  notes: any[];
  settings: any;

  // Private
  private _unsubscribeAll: Subject<any>;


  constructor(
    private _settings: SettingsService,
    private _httpClient: HttpClient
  ) {
    this.date = new Date();
    this.settings = {
      notify: true,
      cloud : false,
      retro : true
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
