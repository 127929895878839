import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ 
  name: 'waPhone',
  pure: false
})
export class WaPhonePipe implements PipeTransform {
  transform(value: string): any {
    return (value || '').substring(Math.max((value || '').length - 10, 0));
  }
}