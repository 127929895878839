import { NgModule } from '@angular/core';

import { PhonePipe } from './phone.pipe';
import { ServerFilePipe } from './server_file.pipe';
import { ServerApiPipe } from './server_api.pipe';
import { TruncatePipe } from './truncate.pipe';
import { DateFormatPipe } from './dateFormat.pipe';
import { SafePipe } from './safe.pipe';
import { FormatMessagePipe } from './messageResponse.pipe';
import { ExtensionPipe } from './extensiones.pipe';
import { Last4Pipe } from './last4.pipe';
import { WaPhonePipe } from './whatsappPhone.pipe';
import { WaFormatPipe } from './waFormat.pipe';
import { ParseUrlsPipe } from './parseURLs.pipe';
import { ParseMarkupPipe } from './parseMarkup.pipe';
import { OnlyTextPipe } from './onlyText.pipe';

@NgModule({
  declarations: [
    PhonePipe,
    ServerFilePipe,
    ServerApiPipe,
    TruncatePipe,
    DateFormatPipe,
    FormatMessagePipe,
    SafePipe,
    ExtensionPipe,
    Last4Pipe,
    WaPhonePipe,
    WaFormatPipe,
    ParseUrlsPipe,
    ParseMarkupPipe,
    OnlyTextPipe
  ],
  imports     : [],
  exports     : [
    PhonePipe,
    ServerFilePipe,
    ServerApiPipe,
    TruncatePipe,
    DateFormatPipe,
    FormatMessagePipe,
    SafePipe,
    ExtensionPipe,
    Last4Pipe,
    WaPhonePipe,
    WaFormatPipe,
    ParseUrlsPipe,
    ParseMarkupPipe,
    OnlyTextPipe
  ]
})
export class PipesModule {}
