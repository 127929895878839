import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, Subject } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Theme, Response } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';
import * as firebase from 'firebase';

@Injectable()
export class ThemesService {

  constructor(private http: HttpClient) {
  }

  all(): Observable<Response<Theme[]>> {
    return this.http.get<Response<Theme[]>>(new ServerApiPipe().transform(`themes`));
  }

  save(theme: Theme, compilado: File): Observable<Response<Theme>> { 
    const formData: FormData = new FormData();
    
    if (compilado) {
      formData.append('site', compilado, 'site');
    }

    formData.append('name', theme.name || '');
    formData.append('clave', theme.clave || '');
    if (theme.current_in_use) {
      formData.append('current_in_use', '1');
    }

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<Theme>>(new ServerApiPipe().transform(`themes`), formData, { headers });
  }

  update(theme: Theme, compilado: File = null): Observable<Response<Theme>> { 
    const formData: FormData = new FormData();
    
    if (compilado) {
      formData.append('site', compilado, 'site');
    }

    formData.append('name', theme.name || '');
    formData.append('clave', theme.clave || '');
    if (theme.current_in_use) {
      formData.append('current_in_use', '1');
    }

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<Theme>>(new ServerApiPipe().transform(`themes/${theme.id}?_method=PUT`), formData, { headers });
  }

  delete(theme: Theme): Observable<Response<any>> { 
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`themes/${theme.id}`));
  }

  publish(theme: Theme): Observable<Response<Theme>> {
    return this.http.get<Response<Theme>>(new ServerApiPipe().transform(`themes/${theme.id}/publish`) );
  }
}
