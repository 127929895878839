import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { User as Doctor, Response, Active } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';

@Injectable()
export class DoctorsService {
  constructor(private http: HttpClient) { }

  list(body: any = {}): Observable<Response<{ doctors: Doctor[] }>> {
    let params = new HttpParams();

    if (body.active) {
      params = params.set('active', body.active);
    }

    return this.http.get<Response<{ doctors: Doctor[]}>>(new ServerApiPipe().transform(`doctors/list`), { params });
  }
}
